export default class OptionsUtils {
    constructor() {
    }
    /*由BaseComponent.js中实现
    static getOptionsData(optionsRefDataId) {
        if (optionsRefDataId) {
            return this.getPage().getData(optionsRefDataId);
        }
        return;
    }
	*/
    static getOptions(options, optionsDisabled, optionsFilter) {
        let result = [];
        if (options) {
            for (let i = 0; i < options.length; i++) {
                let option = options[i];
                if (!option) continue;
                let item = { columns: option };
                if (optionsFilter && !optionsFilter(option)) {
                    continue;
                }
                let disabledVal = false;
                if (optionsDisabled && (typeof optionsDisabled === "function")) {
                    disabledVal = optionsDisabled(option);
                }
                item['disabled'] = disabledVal;
                result.push(item);
            }
        }
        return result;
    }
}