/*eslint-env browser*/

import {encode,decode} from 'html-entities';
import ansiHTML from 'ansi-html';

var clientOverlay = document.createElement('div');
var styles = {
  background: 'rgba(0,0,0,0.85)',
  color: '#E8E8E8',
  lineHeight: '1.2',
  whiteSpace: 'pre',
  fontFamily: 'Menlo, Consolas, monospace',
  fontSize: '13px',
  position: 'fixed',
  zIndex: 9999,
  padding: '10px',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  overflow: 'auto',
  dir: 'ltr'
};
for (var key in styles) {
  clientOverlay.style[key] = styles[key];
}


var colors = {
  reset: ['transparent', 'transparent'],
  black: '181818',
  red: 'E36049',
  green: 'B3CB74',
  yellow: 'FFD080',
  blue: '7CAFC2',
  magenta: '7FACCA',
  cyan: 'C3C2EF',
  lightgrey: 'EBE7E3',
  darkgrey: '6D7891'
};
ansiHTML.setColors(colors);


function showProblems(type, lines, close) {
  clientOverlay.innerHTML = '';
  lines.forEach(function (msg) {
    msg = ansiHTML(encode(msg));
    var div = document.createElement('div');
    div.style.marginBottom = '26px';
    div.innerHTML = problemType(type) + closeable(close) + ' : ' + msg;
    clientOverlay.appendChild(div);
  });
  if (document.body) {
    var closeBtn = clientOverlay.querySelector(".webpack-overlay-closeable");
    if (closeBtn) {
      closeBtn.addEventListener('click', () => {
        clear();
      });
    }
    document.body.appendChild(clientOverlay);
  }
}

exports.showProblems = showProblems;

function clear() {
  if (document.body && clientOverlay.parentNode) {
    document.body.removeChild(clientOverlay);
  }
}

exports.clear = clear;
var problemColors = {
  errors: colors.red,
  warnings: colors.yellow
};

function problemType(type) {
  var color = problemColors[type] || colors.red;
  return (
    '<span style="background-color:#' + color + '; color:#fff; padding:2px 4px; border-radius: 2px">' +
    type.slice(0, -1).toUpperCase() +
    '</span>'
  );
}

function closeable(close) {
  if (close) {
    var color = colors.red;
    return (
      '<span class="webpack-overlay-closeable needsclick" style="position:relative;bottom:4px;background-color:#' + color + '; color:#fff; padding:2px 4px; border-radius: 2px;float:right;">关闭(X)</span>'
    )
  }
}
