import React from 'react';
import WxComponentBase from "components/wx/base/WxComponentBase";
export default class ReactContainer extends WxComponentBase {
  constructor(...args){
    super(...args);
  }
  getWxContainer(wxContainerId,props){
    let wxContainer;
    React.Children.forEach(this.props.children, function(child){
        if(wxContainerId == child.props.id){
            wxContainer = child;
        }
    });
    if(!wxContainer){
      throw `未找到${wxContainerId}指定的wxContainer节点。`

    }
    //传递PageComponent的state 使用不同的对象 方便扩展自己上下文
    let contextVars = {...this.__root__.state};
    //合并wxContainer自身属性
    let {children,...wxContainerProps} = wxContainer.props;
    Object.assign(contextVars,wxContainerProps);
    //当前JsxContainer模块上下文
    Object.assign(contextVars,props);
    //这里直接渲染wxContainer的子节点 等于去掉了wxContainer
    return children(contextVars);
  }
  getPage(){
    return this.__root__.wxPageDeclaration.$page;
  }
}
