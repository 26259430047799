import  {findReact,doThen,setAppTitle} from '../../utils';


/*
	title	String	是	页面标题
	success	Function	否	接口调用成功的回调函数
	fail	Function	否	接口调用失败的回调函数
	complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
*/
let headerInstance;

function getHeader(){
  if(!headerInstance){
    headerInstance = findReact(document.querySelector("#header>div"));
  }
  return headerInstance;
}


const setNavigationBarTitle=params=>{
  const {title} = params;
  const headerInstance  = getHeader();
  //此处使用底层的api, 因为用的地方是用底层api
  var pages = getCurrentReactPages();
  if (pages.length > 0){
    pages[pages.length-1].title = title;
  }
  headerInstance && headerInstance.setTitle(title);
  setAppTitle(title);
};

const showNavigationBarLoading=()=>{
  const headerInstance  = getHeader();
  headerInstance.showLoading();
};

const hideNavigationBarLoading=()=>{
  const headerInstance  = getHeader();
  headerInstance && headerInstance.hideLoading();
};

export {setNavigationBarTitle,showNavigationBarLoading,hideNavigationBarLoading }
