import {doThen,doCatch,isX5App,resolveLocalFile} from '../../utils';
import storeJs from 'store';
import _ from 'lodash'

//兼容wx 不存在的之返回 ‘’
let store = {
  set: function(key, val) {
    storeJs.set(key, val)
  },
  get: function(key) {
    let value = storeJs.get(key);
    if (value == undefined) {
      return ''
    }
    return value;
  },
  remove:function(key){
    storeJs.remove(key)
  },
  clear:function(){
    storeJs.clear()
  }
};

/*
 key	String	是	本地缓存中的指定的 key
 data	Object/String	是	需要存储的内容
 success	Function	否	接口调用成功的回调函数
 fail	Function	否	接口调用失败的回调函数
 complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
 */
function setStorage(params) {
  store.set(params.key, params.data);
  doThen(params);
}

function setStorageSync(key, data) {
  store.set(key, data);
}
function getStorage(params) {
  let data = store.get(params.key);
  doThen(params, {
    data: data
  });
}
function getStorageSync(key) {
  return store.get(key);
}

function removeStorage(params){
	store.remove(params.key);
	doThen(params,{
		errMsg:"removeStorage:ok"
	})
}

function removeStorageSync(key){
	store.remove(key);
}
function getStorageInfo(params){
	doThen(params,_getCurrentStorageInfo());
}
function getStorageInfoSync(){
	return _getCurrentStorageInfo();
}

function clearStorage() {
  store.clear();
}
function clearStorageSync() {
  store.clear();
}

//获取 storage 的信息(当前存储大小、keys)

function _getCurrentStorageInfo(){
	let  _total = 0;
	let _xLen;
	let keys= [];
	storeJs.forEach((key,item) => {
		_xLen = ((item.toString()).length + key.length)*2;
		_total += _xLen;
		keys.push(key.substr(0,50));
	})
	return {
		currentSize: +((_total/1024).toFixed(2)),
		keys,
		limitSize:10240
	}
}

/*// 计算 localStorage 的占空间大小
 * for (in localStorage) {
	    _xLen = ((localStorage[_x].length + _x.length) * 2);
	    _lsTotal += _xLen;
	    console.log(_x.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB")
	    };
	    console.log("Total = " + (_lsTotal / 1024).toFixed(2) + " KB");
 * */


export {setStorage,setStorageSync,getStorage,getStorageSync,clearStorage,clearStorageSync,getStorageInfo,getStorageInfoSync,removeStorage,removeStorageSync}
