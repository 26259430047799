import {findReact} from './../utils';
import detector from 'detector';

import {setStorage,setStorageSync,getStorage,getStorageSync,clearStorage,clearStorageSync,getStorageInfo,getStorageInfoSync,removeStorage,removeStorageSync} from './data/storage'
import './jweixin-1.3.2';

let wx = window.wx = window.wx || window.jWeixin|| {};
const isX5App = (navigator.userAgent.indexOf("x5app") >= 0) || (navigator.userAgent.indexOf("Crosswalk") >= 0);
if(isX5App){
  let script = document.createElement('script');
  script.src = "/cordova.js";
  document.getElementsByTagName('head')[0].appendChild(script);
  document.addEventListener('deviceready', function() {
    if(window.isDebug && window.StatusBar && detector.os.ios){
      StatusBar.hide();
    }
    if (navigator && navigator.splashscreen && navigator.splashscreen.hide) {
      setTimeout(function() {navigator.splashscreen.hide();}, 0);
    }
    if(cordova.InAppBrowser && cordova.InAppBrowser.open){
      window.open = cordova.InAppBrowser.open;
    }
  }, false);
}


wx.setStorage = setStorage;
wx.setStorageSync = setStorageSync;
wx.getStorage = getStorage;
wx.getStorageSync = getStorageSync;
wx.clearStorage = clearStorage;
wx.clearStorageSync = clearStorageSync;
wx.getStorageInfo = getStorageInfo;
wx.getStorageInfoSync = getStorageInfoSync;
wx.removeStorage = removeStorage;
wx.removeStorageSync = removeStorageSync;

import  {startRecord,stopRecord,getRecorderManager} from './media/record/record';
wx.startRecord = startRecord;
wx.stopRecord = stopRecord;
wx.getRecorderManager = getRecorderManager;

import {getBackgroundAudioPlayerState,playBackgroundAudio,pauseBackgroundAudio,seekBackgroundAudio,stopBackgroundAudio,onBackgroundAudioPlay,onBackgroundAudioPause,onBackgroundAudioStop,getBackgroundAudioManager} from './media/backgroundAudio';
wx.getBackgroundAudioPlayerState = getBackgroundAudioPlayerState;
wx.playBackgroundAudio = playBackgroundAudio;
wx.pauseBackgroundAudio = pauseBackgroundAudio;
wx.seekBackgroundAudio = seekBackgroundAudio;
wx.stopBackgroundAudio = stopBackgroundAudio;
wx.onBackgroundAudioPlay = onBackgroundAudioPlay;
wx.onBackgroundAudioPause = onBackgroundAudioPause;
wx.onBackgroundAudioStop = onBackgroundAudioStop;
wx.getBackgroundAudioManager = getBackgroundAudioManager;

import {chooseImage,chooseAllFile,previewImage,getImageInfo,saveImageToPhotosAlbum} from './media/image';
wx.chooseImage = chooseImage;
wx.previewImage = previewImage;
wx.getImageInfo = getImageInfo;
wx.saveImageToPhotosAlbum = saveImageToPhotosAlbum;
wx.saveVideoToPhotosAlbum = saveImageToPhotosAlbum;
wx.chooseAllFile = chooseAllFile;

import {chooseVideo} from './media/video';
wx.chooseVideo = chooseVideo;

import {playVoice,pauseVoice,stopVoice} from './media/voice';
wx.playVoice = playVoice;
wx.pauseVoice = pauseVoice;
wx.stopVoice = stopVoice;

import {scanCode} from './media/scanCode'
wx.scanCode = scanCode;

import {makePhoneCall} from './media/makePhoneCall';
wx.makePhoneCall = makePhoneCall;

import {downloadFile} from './network/file/download';
wx.downloadFile = downloadFile;

import {saveFile,getSavedFileList,getSavedFileInfo,removeSavedFile,openDocument} from './network/file/save';
wx.saveFile = saveFile;
wx.getSavedFileList = getSavedFileList;
wx.getSavedFileInfo = getSavedFileInfo;
wx.removeSavedFile = removeSavedFile;
wx.openDocument = openDocument;

import {uploadFile} from './network/file/upload';
wx.uploadFile = uploadFile;

import {request} from './network/request';
wx.request = request;

import {connectSocket,closeSocket,sendSocketMessage,onSocketClose,onSocketError,onSocketMessage,onSocketOpen} from './network/websocket';
wx.connectSocket = connectSocket;
wx.closeSocket = closeSocket;
wx.sendSocketMessage = sendSocketMessage;
wx.onSocketClose = onSocketClose;
wx.onSocketError = onSocketError;
wx.onSocketMessage = onSocketMessage;
wx.onSocketOpen = onSocketOpen;

import {hideKeyboard} from './ui/addon';
wx.hideKeyboard = hideKeyboard;

import {createAnimation} from './ui/animation';
wx.createAnimation = createAnimation;

import{showModal} from './ui/modal';
wx.showModal=showModal;

import {showActionSheet} from './ui/actionSheet';
wx.showActionSheet=showActionSheet;

import {Toast} from './ui/toast';
wx.showToast = Toast.showToast;
wx.hideToast = Toast.hideToast;

import {Tabbar} from './ui/tabbar';
wx.setTabBarBadge = Tabbar.setTabBarBadge;

import {getNetworkType,getSystemInfo,getSystemInfoSync,onAccelerometerChange,onCompassChange,stopAccelerometer} from './device';
wx.getNetworkType = getNetworkType;
wx.getSystemInfo = getSystemInfo;
wx.getSystemInfoSync = getSystemInfoSync;
wx.onAccelerometerChange = onAccelerometerChange;
wx.onCompassChange = onCompassChange;
wx.stopAccelerometer = stopAccelerometer;


import {getLocation,openLocation,chooseLocation,createMapContext} from './location';
wx.getLocation  = getLocation;
wx.openLocation  = openLocation;
wx.chooseLocation = chooseLocation;
wx.createMapContext = createMapContext;


import {login,getUserInfo,requestPayment,checkSession,chooseAddress,getWeRunData,chooseInvoiceTitle,getSetting,authorize} from './openApi';
wx.login = login;
wx.getUserInfo = getUserInfo;
wx.getUserProfile = getUserInfo;
wx.requestPayment = requestPayment;
wx.checkSession = checkSession;
wx.chooseAddress = chooseAddress;
wx.getWeRunData = getWeRunData;
wx.chooseInvoiceTitle = chooseInvoiceTitle;
wx.getSetting = getSetting;
wx.authorize = authorize;

import {navigateTo,redirectTo,navigateBack,switchTab,reLaunch,navigateToMiniProgram,navigateBackMiniProgram} from './ui/navigate';
wx.navigateTo = navigateTo;
wx.redirectTo = redirectTo;
wx.navigateBack = navigateBack;
wx.switchTab = switchTab;
wx.reLaunch = reLaunch;
wx.navigateToMiniProgram = navigateToMiniProgram;
wx.navigateBackMiniProgram = navigateBackMiniProgram;

import {setNavigationBarTitle,showNavigationBarLoading,hideNavigationBarLoading } from './ui/navigationBar';
wx.setNavigationBarTitle = setNavigationBarTitle;
wx.showNavigationBarLoading = showNavigationBarLoading;
wx.hideNavigationBarLoading = hideNavigationBarLoading;


import {createAudioContext,createInnerAudioContext} from './media/audioContext';
wx.createAudioContext = createAudioContext;
wx.createInnerAudioContext = createInnerAudioContext;


import {createVideoContext} from './media/videoContext';
wx.createVideoContext = createVideoContext;

import {createCanvasContext} from './ui/canvas';
wx.createCanvasContext = createCanvasContext;
wx.createContext = createCanvasContext;

import {startPullDownRefresh,stopPullDownRefresh} from './ui/pullDownRefresh';
wx.stopPullDownRefresh = stopPullDownRefresh;
wx.startPullDownRefresh = startPullDownRefresh;

import {getClipboardData,setClipboardData} from './media/clipboard';
wx.getClipboardData = getClipboardData;
wx.setClipboardData = setClipboardData;

//mock api
wx.showShareMenu = function(){
	wx.showShareMenu.show = true;
};
wx.hideShareMenu = function(){
	wx.showShareMenu.show = false;
};
wx.updateShareMenu = function(){};
wx.getShareInfo = function(){};

import {showLoading,hideLoading} from "./ui/loading";
wx.showLoading = showLoading;
wx.hideLoading = hideLoading;

//扩展react相关api
wx.findReact = findReact;

export default wx;
