import 'whatwg-fetch';
import {isArray,forEach} from 'lodash';
import transform from 'lodash/transform';
import {doThen,doCatch,checkStatus} from '../../utils';
import CryptoRequest  from "./crypto-request";
import mitt from 'mitt';
function _parseParams(params) {
  params.method = params.method || "GET";
  params.header = params.header || {};
  params.header = transform(params.header, function (result, val, key) {
    result[key.toLowerCase()] = val;
  });
  params.header['X-Requested-With'] = "Fetch";
  params.header['content-type']  = params.header['content-type'] ||  'application/json';
  let body;
  if (params.data) {
    let data = params.data;
    if(typeof data == "object"){
      if(params.method.toUpperCase() == "GET"){
        let url = new URL(params.url);
	//日期对话像需要调用toISOString
        Object.keys(data).forEach(key => {
          let value = data[key];
          if(isArray(value)){
            forEach(value,(item)=>{
              url.searchParams.append(key, item?.toISOString?.() || item);
            })
          }else{
            url.searchParams.append(key, value?.toISOString?.() || value);
          }
        });
        params.url = url.toString();
        data = undefined;
      } else if(params.method.toUpperCase() == "POST"){
        if(params.header['content-type'] == 'application/json'){
          body = JSON.stringify(data);

        }else if(params.header['content-type'] == 'application/x-www-form-urlencoded'){
          let __param = new URLSearchParams();
          Object.keys(data).forEach(key => __param.append(key, data[key]?.toISOString?.() || data[key]));
          body = __param.toString();
        }
      }else {
        body = JSON.stringify(data);
      }
    }
    body = body || data;
  }
  return {
    body: body,
    headers: params.header,
    method:params.method,
    credentials: 'include'
  }
}

function _parseResponse(response) {
  return response.text();
}

/**
 * 只要成功接收到服务器返回，无论statusCode是多少，
 * 都会进入success回调。请开发者根据业务逻辑对返回值进行判断。
 */

export function request(params) {
  const _params = _parseParams(params);
  CryptoRequest.interceptRequest(params,_params,_request);
  return _request(params,_params);
}

function _request(params,_params) {


  fetch(params.url,_params)
    /*.then(checkStatus)*/
    .then(function(response) {


      let contentType = response.headers.get('content-type');
      if(contentType && contentType.indexOf('text/event-stream') == 0){
        let data = processEventStreamResponse(response);
        let status = response.status;
        doThen(params, {
          data,
          statusCode:status
        });
        return;
      }
      return response.text().then(function(text){
        let status = response.status;


        let errMsg = "request:ok";
        let header = {};
        response.headers.forEach((value,key) =>{
          header[key] = value;
        });
        if(header['content-type'] && header['content-type'].indexOf('application/json')!=-1){
          try{
            text = JSON.parse(text);
          }catch(e){
          }
        }else{
          //兼容gox5返回字符串类型 但是前段要json类型的需求 同时小程序也是这样支持的 其实不符合规范  这里加字符判断是为了规避 超长的数字字符串返回 如果被json后会精度丢失
          if(text && (text.indexOf("{") !=-1 || text.indexOf("[") !=-1)){
            try{
              text = JSON.parse(text);
            }catch(e){
            }
          }
        }

        //兼容全球加速的场景对content-range处理 认为不规范 直接去掉的问题
        if(header['x-content-range'] && !header['content-range']){
          header['content-range'] = header['x-content-range'];
        }

        let data = text;
        doThen(params, {
          data,
          errMsg,
          header,
          statusCode:status
        });
      });
    }).catch(function (error) {
      doCatch(params, error);
  });
}


function processEventStreamResponse(response) {
  let reader = response.body.getReader();
  let decoder = new TextDecoder();
  let stream = new mitt();
  let readData = ()=> {
    return reader.read().then( (data) =>{
      let value = data.value;
      let done = data.done;
      if(value){
        value = new TextDecoder("utf-8").decode(value);
      }
      if(value){
        value.split(/\r?\n/g).filter((item)=>item !== '').forEach((item)=>{
          if(item && item.indexOf("{") !=-1  && item.indexOf("}") !=-1){
            item = JSON.parse(item);
          }

          if (!done) {
            if(! (item && item.hasOwnProperty("percent") && item.hasOwnProperty("message"))){
              stream.result = item;
            }else{
              stream.emit("message",item);
            }

          }else {
            stream.result = item || stream.result;
            stream.emit("done",stream.result);
            return;
          }
        })
      }else if(done){
        stream.emit("done",stream.result);
        return;
      }
      return readData();
    });
  }
  readData();
  return stream;
}
