import _ from 'lodash';
import moment from 'moment';
import { doThen, doCatch, isX5App, resolveLocalFile } from '../../../utils';
import URI from 'urijs';
import Recorder from './recorder';

//TODO cordova升级屏蔽细节差异
function _getMediaStorePath() {
  let ios = window.cordova && cordova.file.cacheDirectory;
  let ext = window.cordova && cordova.file.externalCacheDirectory;
  return (ext) ? ext : (ios ? ios : "");
}

let recordInfo = {};
let promiseParams = {};
/*
 success	Function	否	录音成功后调用，返回录音文件的临时文件路径，res = {tempFilePath: '录音文件的临时路径'}
 fail	Function	否	接口调用失败的回调函数
 complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
 */
function startRecord(params) {
  promiseParams = params;
  let duration = params.duration || 60000;
  if (!recordInfo._recording) {
    let self = this;
    recordInfo.timerId = setTimeout(function () {
      self.stopRecord();
    }, duration);
    recordInfo.startRecordingTime = new Date().getTime();
    if (isX5App) {
      recordInfo.recordFileName = _getMediaStorePath() + moment().format('YYYYMMDDhhmmss') + ".wav";
      recordInfo.recorder = new Media(recordInfo.recordFileName, function () {
      }, function (err) {
        doCatch(params, err);
      });
      recordInfo.recorder.startRecord();
      recordInfo._recording = true;
    } else {
      Recorder.get(function (rec) {
        //处理异常，
        if (_.isString(rec)) {
          doCatch(params, rec);
          throw new Error(rec);
        }
        recordInfo._recording = true;
        recordInfo.recorder = rec;
        recordInfo.recorder._blobId = _.uniqueId('blob://');
        rec.start();
      });
    }
  }
}

function stopRecord() {
  if (recordInfo._recording) {
    if (isX5App) {
      recordInfo.recorder.stopRecord();
      doThen(promiseParams, {
        tempFilePath: recordInfo.recordFileName
      });
    } else {
      recordInfo.recorder.stop();
      window.blobs[recordInfo.recorder._blobId] = recordInfo.recorder.getBlob();
      doThen(promiseParams, {
        tempFilePath: recordInfo.recorder._blobId
      });
    }
    const stopRecordingTime = new Date().getTime();
    window.sessionStorage.setItem("recordingTime", (stopRecordingTime - recordInfo.startRecordingTime));
  };
  clearTimeout(recordInfo.timerId);
  recordInfo = {};
}
/**
 * 这个api微信实现的需要增强等增强后在模拟
 https://developers.weixin.qq.com/blogdetail?action=get_post_info&docid=0008064e964160c96fe63cf7351400&token=1429949403&lang=zh_CN
 */
let recordManager = {
  start:function(params = {}){
    if(params.duration && params.duration > 600000){
      params.duration = 600000;
    }
    params.success = function(res){

    };
    params.fail = function(res){

    };
    return startRecord(params);
  },
  pause:function(){

  },
  resume:function(){

  },
  stop:function(){
    return stopRecord({
      success:function(res){
        if(recordManager._onStop){
          recordManager._onStop(res);
        }
      },
      fail:function(res){
        if(recordManager._onError){
          recordManager._onError(res);
        }
      }
    });
  },
  onStart:function(callback){
    recordManager._onStart = callback;
  },
  onStop:function(callback){
    recordManager._onStop = callback;
  },
  onError:function(callback){
    recordManager._onError = callback;
  }
};

function getRecorderManager(){
  return recordManager;
}

export { startRecord, stopRecord, getRecorderManager};
