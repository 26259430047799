import _ from 'lodash';
import {wx,doThen,doCatch,isX5App,resolveLocalFile} from '../../utils';
import domify from 'domify'

/***
 *OBJECT参数说明：

	参数				类型			必填			说明
	title			String		是		提示的标题
	content			String		是		提示的内容
	showCancel		Boolean		否		是否显示取消按钮，默认为 true
	cancelText		String		否		取消按钮的文字，默认为"取消"，最多 4 个字符
	cancelColor		HexColor	否		取消按钮的文字颜色，默认为"#000000"
	confirmText		String		否		确定按钮的文字，默认为"确定"，最多 4 个字符
	confirmColor	HexColor	否		确定按钮的文字颜色，默认为"#3CC51F"
	success			Function	否		接口调用成功的回调函数，返回res.confirm为true时，表示用户点击确定按钮
	fail			Function	否		接口调用失败的回调函数
	complete		Function	否		接口调用结束的回调函数（调用成功、失败都会执行）
 *
 */

const temp=
	`<div class="wx-modal">
			<div class="wx-modal-mask"></div>
			<div class="wx-modal-dialog ">
				<div class="wx-modal-dialog-hd"><%= title %></div>
			    <div class="wx-modal-dialog-bd"><%= content%></div>
			    <div class="wx-modal-dialog-ft">
			    <% if ( showCancel){%>
			    	<a href="javascript:void(0)"  class="wx-modal-btn-default cancel-btn" style="color: <%= cancelColor%>;"><%= cancelText %></a>
			    	<%}%>
			        <a href="javascript:void(0)" class="wx-modal-btn-primary confirm-btn" style="color: <%= confirmColor %>;"><%= confirmText %></a>
			    </div>
			</div>
		</div>`;
let element=null;


//支持多语言 利用门户的资源信息
function showModal(params){

	let {title="",content="",showCancel=true,cancelText="取消",cancelColor="#000000",confirmText="确定",confirmColor="#3cc51f"}=params;

  let currentPage = window.getCurrentReactPage();
  if(currentPage){
    title = currentPage.i18n(title);
    content = currentPage.i18n(content);
    cancelText = currentPage.i18n(cancelText);
    confirmText = currentPage.i18n(confirmText);
  }

	//将模板转换为DOM对象
	if(element && element.parentNode){
		element.parentNode.removeChild(element);
	}
	element=domify(_.template(temp)({
		title,
		content,
		"cancelText":cancelText.substr(0,4),
		"confirmText":confirmText.substr(0,4),
		cancelColor,
		confirmColor,
		showCancel
	}));
	document.body.appendChild(element);
	let called = false;
	return new Promise(resolve => {
		element.addEventListener('click', (e) => {
	      if (called) {return}
		  if(/wx-modal-mask/.test(e.target.className)){
          called = true;
          resolve({errMsg:"showModal:ok",confirm:false,cancel:false});
		  }else if (/cancel-btn/.test(e.target.className)) {
	        called = true;
	        resolve({errMsg:"showModal:ok",confirm:false,cancel:true});
	      } else if (/confirm-btn/.test(e.target.className)) {
	        called = true;
	        resolve({errMsg:"showModal:ok",confirm:true,cancel:false})
	      }
	      if (called && element && element.parentNode) {
		      element.parentNode.removeChild(element)
		    }
	    }, false)
	 }).then(res=>{
		 if(res){
			 doThen(params,res);
		 }
	 })
}

export {showModal}
