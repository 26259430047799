import {isInViewport} from "core/utils"
import  $ from "jquery"





document.addEventListener('deviceready', function () {
  window.Keyboard.shrinkView(true);
  window.Keyboard.disableScrollingInShrinkView(true);
}, false);
window.addEventListener("resize",() => {
  let activeElement = document.activeElement;
  if(activeElement && /^(INPUT|TEXTAREA)$/.test(activeElement.nodeName)){
    if(!isInViewport(activeElement)){
      if(activeElement.scrollIntoView){
        setTimeout(function(){
          activeElement.scrollIntoViewIfNeeded && activeElement.scrollIntoViewIfNeeded();
          if(!isInViewport(activeElement)){
            activeElement.scrollIntoView(false);
          }
        },50);
      }
    }
  }
},false);

document.addEventListener("focusin",function(e){
	var target = e.target;
	if (target.tagName && (target.tagName === 'TEXTAREA' || (target.tagName === 'INPUT' && (target.type === 'text' || target.type === 'search' || target.type === 'number'))) || (target.tagName === 'DIV' && $(e.target).attr('contenteditable') ==  "true")) {
		if (target.disabled || target.readOnly) {
			return;
		}else{
			if(window.Keyboard && Keyboard.show){
				Keyboard.show();
			}
		}
	}
});


window.addEventListener('keyboardWillShow', function () {
  document.querySelector("#container").classList.add('focusin');
  document.querySelector("#tabbar").classList.add('focusin');
});

window.addEventListener('keyboardDidHide', function () {
  document.querySelector("#container").classList.remove('focusin');
  document.querySelector("#tabbar").classList.remove('focusin');
});
