/**
 * @author  007slm@163.com
 *
 * this is a emulate tool which emulate multi-touch event as specified by w3c in desktop mode.
 * designed for wex5 mobile page preview in desktop mode.
 * inspired by hammerjs  phantomjs(fake touch) fastclick
 */
import TouchEmulator from './hammer-touchemulator';
import './touch-emulate.css';
if(window.isPcx){
  TouchEmulator.template = function(touch){
    return {};
  }
}
TouchEmulator();
/*
 create synthetic event in desktop by touch event
 lzg 关闭click 
 */
class SyntheticClick{
  constructor(){
    //this.preventDefaultClick();
    //document.addEventListener('click', this.onClick.bind(this), true);
    document.addEventListener('touchstart', this.onTouchStart.bind(this), global.supportsPassive? { passive: true}:false);
    document.addEventListener('touchmove', this.onTouchMove.bind(this), global.supportsPassive? { passive: true}:false);
    document.addEventListener('touchend', this.onTouchEnd.bind(this), global.supportsPassive? { passive: true}:false);
    document.addEventListener('touchcancel', this.onTouchCancel.bind(this), global.supportsPassive? { passive: true}:false);
  }

  onClick(event){
    if(event.clientX && event.clientX > 1) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
    //synthetiese event not need prevent
  }

  onTouchStart(event) {
    if (event.targetTouches.length > 1) {
      return true;
    }
    let touch = event.targetTouches[0];
    //stop tracking  click when touch has moved
    this.targetElement = event.target;
    this.trackingClick = true;
    this.trackingClickStart = event.timeStamp;
    this.touchStartX = touch.pageX;
    this.touchStartY = touch.pageY;
    return true;
  }
  touchBoundary = 10;
  touchHasMoved(event) {
    let touch = event.changedTouches[0], boundary = this.touchBoundary;

    if(Math.abs(touch.pageX - this.touchStartX) > boundary
      || Math.abs(touch.pageY - this.touchStartY) > boundary) {
      return true;
    }
    return false;
  }
  onTouchMove(event) {
    if (!this.trackingClick) {
      return true;
    }
    // If the touch has moved, cancel the click tracking
    if (this.targetElement !== event.target || this.touchHasMoved(event)) {
      this.trackingClick = false;
      this.targetElement = null;
    }
    return true;
  }
  tapTimeout = 700;
  onTouchEnd(event) {
    if (!this.trackingClick) {
      return true;
    }
    if ((event.timeStamp - this.trackingClickStart) > this.tapTimeout) {
      return true;
    }

    this.trackingClick = false;
    this.trackingClickStart = 0;

    if (SyntheticClick.needsFocus(this.targetElement)) {
      SyntheticClick.focus(this.targetElement);
    }

    if (SyntheticClick.needsClick(this.targetElement)) {
      SyntheticClick.sendClick(this.targetElement);
    }
    return false;
  }
  onTouchCancel() {
    this.trackingClick = false;
    this.targetElement = null;
  }
  static focus(targetElement) {
    targetElement.focus();
  }
  static needsFocus(target) {
    switch (target.nodeName.toLowerCase()) {
      case 'textarea':
        return true;
      case 'select':
        return true;
      case 'input':
        switch (target.type) {
          case 'button':
          case 'checkbox':
          case 'file':
          case 'image':
          case 'radio':
          case 'submit':
            return false;
        }
        // No point in attempting to focus disabled inputs
        return !target.disabled && !target.readOnly;
      default:
        return false;
    }
  }
  static needsClick(target) {
    switch (target.nodeName.toLowerCase()) {
      // Don't send a synthetic click to disabled inputs (issue #62)
      case 'button':
      case 'select':
      case 'textarea':
        if (target.disabled) {
          return false;
        }
    }
    return false;
  }
  static sendClick(target){
    // let clickEvent = new CustomEvent("click",{
    //   "detail":{
    //     "synthetic":true
    //   },
    //   "bubbles":true,
    //   "cancelable":true
    // });
    //target.dispatchEvent(clickEvent);
    if (document.activeElement && document.activeElement !== target) {
      document.activeElement.blur();
    }


    let clickEvent = document.createEvent('MouseEvents');
    clickEvent.initMouseEvent('click', true, true, window, 1, 1, 1, 1, 1, false, false, false, false, 0, null);
    target.dispatchEvent(clickEvent);
  }
}
new SyntheticClick();
