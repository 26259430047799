import _ from 'lodash';
import {wx,doThen,doCatch,isX5App,resolveLocalFile} from '../../utils';
import domify from 'domify'

/*
 * @showActionSheet(Object) 参数：
 * 
 *		参数			类型					必填				说明
	itemList		String Array		是			按钮的文字数组，数组长度最大为6个
	itemColor		HexColor			否			按钮的文字颜色，默认为"#000000"
	success			Function			否			接口调用成功的回调函数，详见返回参数说明
	fail			Function			否			接口调用失败的回调函数
	complete		Function			否			接口调用结束的回调函数（调用成功、失败都会执行）
	
 */
/*
 * @success 返回参数:
	
	参数				类型					说明
	cancel			Boolean			用户是否取消选择
	tapIndex		Number			用户点击的按钮，从上到下的顺序，从0开始
 */

const temp=`
<div>
	<div class="wx-action-sheet-mask"></div>
	<div class="wx-action-sheet wx-action-sheet-show">
		<div class="wx-action-sheet-menu">
			<% _.forEach(itemList, function(item,index) { %><div class="wx-action-sheet-item" data-index="<%= index %>" style="color:<%= itemColor%>"><%- item %></div><% }); %>
		</div>
		<div class="wx-action-sheet-item-cancel">
			<div class="wx-action-sheet-middle"></div>
			<div class="wx-action-sheet-cancel" id="actionsheetCancel">取消</div>
		</div>
	</div>
</div>`;
let element=null;
function showActionSheet(params){
	if(element&&element.parentNode)element.parentNode.removeChild(element);
	let {itemList=[],itemColor="#000000"}=params;
	itemList.length=Math.min(6,itemList.length);
	//将模板转换为DOM对象
	element=domify(_.template(temp)({
		itemList,
		itemColor
	}));
	document.body.appendChild(element);
	let called=false;
	return new Promise((resolve,reject)=>{
		element.addEventListener("click",(e)=>{
			if(called)return;
			if(/wx-action-sheet-mask/.test(e.target.className)){
				called=true;
				resolve({errMsg:"showActionSheet:ok",cancel:true});
			}else if(/wx-action-sheet-cancel/.test(e.target.className)){
				called=true;
				reject({errMsg:"showActionSheet:ok",cancel:true});
			}else if(/wx-action-sheet-item/.test(e.target.className)){
				called=true;
				resolve({errMsg:"showActionSheet:ok",cancel:false,tapIndex:+(e.target.dataset.index)});
			}
			if(called&&element&&element.parentNode)element.parentNode.removeChild(element)
		},false)
	}).then(res=>{
		doThen(params,res);
	}).catch(error=>{
    doCatch(params,error);
  })
}

export {showActionSheet}
