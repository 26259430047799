import _ from 'lodash';
import 'whatwg-fetch';
import {doThen,doCatch,checkStatus} from '../../../utils';

/**
 url	String	是	下载资源的 url
 type	String	否	 下载资源的类型，用于客户端识别处理，有效值：image/audio/video
 header	Object	否 	HTTP 请求 Header
 success	Function	否	下载成功后以 tempFilePath 的形式传给页面，res = {tempFilePath: '文件的临时路径'}
 fail	Function	否	接口调用失败的回调函数
 complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
 */
function _parseParams(params){
  let _params = {
    header:params.header
  };
  return _params;
}


export function downloadFile(params){
  let _params = _parseParams(params);
  fetch(params.url,_params)
    .then(checkStatus)
    .then(function(response){
      response.blob().then(function(blob){
        const id = _.uniqueId('blob://');
        window.blobs[id] = blob;
        let res = {
          tempFilePath:id
        };
        doThen(params,res);
      }).catch(function(error){
        doCatch(params,error);
      });

    }).catch(function(error){
      doCatch(params,error);
    });
}
