import $ from 'jquery';
/**
 * from bootstrap  for  emulateTransitionEnd
 */
$.support.transition = (function(){
  var el = document.createElement('bootstrap');

  var transEndEventNames = {
    'WebkitTransition' : 'webkitTransitionEnd'
    , 'MozTransition'    : 'transitionend'
    , 'OTransition'      : 'oTransitionEnd otransitionend'
    , 'transition'       : 'transitionend'
  };

  for (var name in transEndEventNames) {
    if (el.style[name] !== undefined) {
      return { end: transEndEventNames[name] }
    }
  }
  return false;
})();
// http://blog.alexmaccaw.com/css-transitions
$.fn.emulateTransitionEnd = function (duration) {
  var called = false, $el = this
  $(this).one($.support.transition.end, function () { called = true })
  var callback = function () { if (!called) $($el).trigger($.support.transition.end) }
  setTimeout(callback, duration)
  return this
};




$.fn.transform = function (transform) {
  for (var i = 0; i < this.length; i++) {
    var elStyle = this[i].style;
    elStyle.webkitTransform = elStyle.MsTransform = elStyle.msTransform = elStyle.MozTransform = elStyle.OTransform = elStyle.transform = transform;
  }
  return this;
};

$.fn.transition = function (duration) {
  for (var i = 0; i < this.length; i++) {
    var elStyle = this[i].style;
    elStyle.webkitTransitionDuration = elStyle.MsTransitionDuration = elStyle.msTransitionDuration = elStyle.MozTransitionDuration = elStyle.OTransitionDuration = elStyle.transitionDuration = duration + 'ms';
  }
  return this;
};

$.fn.transitionEnd = function (callback) {
  var events = ['webkitTransitionEnd', 'transitionend', 'oTransitionEnd', 'MSTransitionEnd', 'msTransitionEnd'],
    i, j, dom = this;
  function fireCallBack(e) {
    /*jshint validthis:true */
    callback.call(this, e);
    for (i = 0; i < events.length; i++) {
      dom.off(events[i], fireCallBack);
    }
  }
  if (callback) {
    for (i = 0; i < events.length; i++) {
      dom.on(events[i], fireCallBack);
    }
  }
  return this;
};

$.fn.animationEnd = function (callback) {
  var events = ['webkitAnimationEnd', 'OAnimationEnd', 'MSAnimationEnd', 'animationend'],
    i, j, dom = this;
  function fireCallBack(e) {
    callback(e);
    for (i = 0; i < events.length; i++) {
      dom.off(events[i], fireCallBack);
    }
  }
  if (callback) {
    for (i = 0; i < events.length; i++) {
      dom.on(events[i], fireCallBack);
    }
  }
  return this;
};
