import history from 'core/appHistory';
import {endsWith} from 'lodash';
import ErrorBoll from "../ErrorBoll";
import {debounce} from 'lodash'
function url() {
  let l = window.location;
  return ((l.protocol === "https:") ? "wss://" : "ws://") + l.host + l.pathname + s;
}

export default class HotRenderClient{
  constructor(serviceName,contextName) {
    this.serviceName = serviceName;
    this.contextName = contextName;
    this.reConnectionCount = 0;
    new ErrorBoll();
    this.connect();
    this.pageRender = debounce(wx.render,500);
  }
  getUrl(serviceName,contextName){
    let l = window.location;
    let pathName =  (this.serviceName ? "/" + this.serviceName : "") + "/" + this.contextName;
    return ((l.protocol === "https:") ? "wss://" : "ws://") + l.host + pathName + "/hotRender";
  }



  defaultPageReload({route,type}){
    let currentPageInstance  = getCurrentReactPage();
    if(type == "module" && currentPageInstance){
      //模块更改 更新当前页面
      let location = currentPageInstance.wxPageDeclaration.location;
      this.pageRender(location,"REPLACE");
    }else if(currentPageInstance && endsWith(currentPageInstance.wxPageDeclaration.route,route)) {
      //当前页修改 更新当前页面
      let location = currentPageInstance.wxPageDeclaration.location;
      this.pageRender(location,"REPLACE");
    }
  }

  pageReload({route,type}){
    if(history.router?.type == "portal"){
      history.router.hotReload({
        route,
        type
      })
    }else{
      this.defaultPageReload({route,type});
    }
  }


  /*uixPortalPageReload({route,type}){
    let activePageUrl = history.router?.stateRef.current.activePageUrls[0];
    let currentActiveTab = history.router?.stateRef.current.tabs.find(({pageUrl})=>{
      return pageUrl == activePageUrl;
    })
    let portalPage = history.router?.portalState?.portalPage;

    if(type == "module"){
      currentActiveTab?.pageContainer?.reload();
    }else if(currentActiveTab.url.indexOf(route)!=-1){
        currentActiveTab?.pageContainer?.reload();
    }
  }*/


  connect(){
    if(this.socket){
      this.socket.close()
    }
    this.socket = new WebSocket(this.getUrl());

    this.socket.addEventListener('open',  (event) =>{
      console.log('热更新连接成功！');
      this.reConnectionCount = 0;
    });

    this.socket.addEventListener('close',  (event) =>{
      console.log('热更新断线重连', event.reason);
      if(this.reConnectionCount < 5){
        setTimeout(() =>{
          this.connect();
        }, 1000);
      }
    });

    this.socket.addEventListener('error',  (event) =>{
      console.error('热更新通信异常: ', event.message);
      this.reConnectionCount++;
      this.socket.close();
    });

    this.socket.addEventListener('message',  (event) =>{
      let  message = JSON.parse(event.data);
      let reactPage = getCurrentReactPages();

      ErrorBoll.resetErrorCount();
      try{
        let uixHotRender = JSON.parse(localStorage.getItem("eruda-开发工具") || "{}").uixHotRender;
        if(uixHotRender == false){
          console.log('热更新已关闭,请在uixDevTools中开启');
          return;
        }
      }catch(e){}

      this.pageReload(message);
    });
  }
}
