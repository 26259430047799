import React from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import {getComponentEventTarget, fireWxEvent} from "../../../core/utils";
import WxUtils from "./WxUtils";
import {leafShallowEqual} from 'core/compShallowEqual';
import {applyAnimation, stopAnimation} from "core/api/ui/animation";

export default class WxComponentBase extends React.Component {
  constructor(...args) {
    super(...args);
    this.__root__ = this.context.__root__;
    /*
     touchstart	手指触摸动作开始
     touchmove	手指触摸后移动
     touchcancel	手指触摸动作被打断，如来电提醒，弹窗
     touchend	手指触摸动作结束
     tap	手指触摸后马上离开
     longtap	手指触摸后，超过350ms再离开
     */
    this.events = Object.assign({
      "onClick": ["bindtap", "catchtap"],
	  "onClickCapture": ["capturebindtap", "capturecatchtap"],
      "onTouchStart": "bindtouchstart",
      "onTouchMove": "bindtouchmove",
      "onTouchEnd": "bindtouchend",
      "onTouchCancel": "bindtouchcancel"
    }, this.getInitialEvent());
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(leafShallowEqual(nextState,this.state) && leafShallowEqual(nextProps,this.props)){
      return false;
    }
    return true;
  }

  getInitialEvent() {
    return {};
  }

  toDOMAttrs(props) {
    return WxUtils.toDOMAttrs(props);
  }

  componentWillReceiveProps(nextProps) {
    _.forEach(nextProps, (value, key) => {
      key = _.camelCase(key);
      if (_.has(this.state, key)) {
        if (this.state[key] !== nextProps[key]) {
          let state = {};
          state[key] = value;
          this.setState(state);
        }
      }
    });
  }

  /**
   1. process event handler;
   2. process css
   */
  compatWX() {
    //console.log("render: " + this.props.id);
    this.htmlProps = {};
    this.compatEventHandler();
    this.compatClassName();
    this.compatId();
    this.compatCommonProps();
  }

  //输出error到console
  /*componentDidCatch(error, info) {
    console.error(error, info);
  }*/

  //处理一些通用的属性, 如disabled等等
  compatCommonProps() {
    this.htmlProps.disabled = this.toBoolean(this.props.disabled);
  }

  componentWillUpdate(newProps, newState) {
    if (newProps.animation) {
      let target = ReactDOM.findDOMNode(this);
      stopAnimation(target);
      setTimeout(() => {
        applyAnimation(target, newProps.animation);
      }, 0);
    }
  }


  render() {
    //console.log(this.props.id + ":" + this.props.children);
    this.compatWX();
    this._doHidden();
    return this.doRender ? this.doRender() : null;
  }

  _doHidden() {
    let isHidden = this.props.hidden;
    if (isHidden) {
      this.htmlProps['className'] = cx('hidden-only-for-attr', this.htmlProps['className'])
    } else {
      this.htmlProps['className'] = cx({'hidden-only-for-attr': false}, this.htmlProps['className'])
    }
  }

  getClassName() {
    return this.htmlProps.className;
  }

  //scopedId
  compatId() {
    let s = this.__root__.wxPageStyle;
    let id = this.props.id;
    if (id) {
      let scopedId = s && s[id] || id;
      this.htmlProps['id'] = scopedId;
      this.htmlProps['data-props-id'] = id;
    }
  }

  getCompDefaultClassName() {
    return  this.constructor.name + ' ' +  (this._DefaultClassName||'');
  }

  //对className的cssModule写法支持
  compatClassName() {
    let s = this.__root__.wxPageStyle || {};

    let className = (this.props.className || "") + " " + (this.state && this.state.hoverClassName ? this.state.hoverClassName : "");
    className = className + " " + this.getCompDefaultClassName();

    //cssModule
    let cssModuleClassName = className.split(' ').map(function (item) {
      return s[item] || "";
    });
    //className
    className = className + " " + cssModuleClassName.join(' ');
    this.htmlProps['className'] = className;
  }

  hasDisabledAttr() {
    return false;
  }

  stopEvent(wxEventName, reactEventName) {
    if (this.hasDisabledAttr()
      && this.toBoolean(this.props.disabled)
      && (wxEventName.indexOf("tap") !== -1)) {
      return true;
    }
    return false;
  }

  compatEventHandler() {
    _.forEach(this.events, (wxEventNames, reactEventName) => {
      if (_.isString(wxEventNames)) {
        wxEventNames = [wxEventNames];
      }
      let compHandler, pageHandler;

      if (_.isFunction(this[reactEventName + "Handler"])) {
        compHandler = this[reactEventName + "Handler"].bind(this);
      }
      _.forEach(wxEventNames, (wxEventName) => {
        //console.log("需要自动兼容的事件" + wxEventName + "：" + reactEventName + " in " + this.constructor.name);
        if (this.props[wxEventName] && _.isFunction(this.__root__[this.props[wxEventName]])) {
          pageHandler = (event) => {
            //console.log("react收到事件event:" + event.type + "target:" + event.target.tagName + "clientX:" + event.clientX);
            if (this.stopEvent(wxEventName, reactEventName)) {
              return;
            }
            let newEvent = this.compatEventObj(event, wxEventName, reactEventName);

            this.__root__[this.props[wxEventName]](newEvent);
            if ((wxEventName.indexOf("catch") == 0) || (wxEventName.indexOf("capturecatch") == 0)) {
              event.stopPropagation();
            }
          }
        }
      });
      this.htmlProps[reactEventName] = (event) => {
          if (compHandler) {
            compHandler(event);
          }
          if (pageHandler) {
            pageHandler(event);
          }
          if (this.props[reactEventName] && _.isFunction(this.props[reactEventName])){
        	  this.props[reactEventName]();
          }
      }
    });
    
    //将其它事件收集到this.htmlProps中, 认为事件是以on开头
    for (let key in this.props){
    	if (this.props.hasOwnProperty(key) 
    			&& (key.indexOf("on")==0) 
    			&& !this.htmlProps[key]
    			&& _.isFunction(this.props[key])){
    		this.htmlProps[key] = this.props[key];
    	}
    }
  }

  /**
   * Warning: This synthetic event is reused for performance reasons.
   * If you're seeing this, you're accessing the method `currentTarget`
   * on a released/nullified synthetic event.
   * This is a no-op function.
   * If you must keep the original synthetic event around,
   * use event.persist().
   * See https://fb.me/react-event-pooling for more information.
   */
  onTouchStartHandler(event) {
    let currentTarget = event.currentTarget;
    this.longTapTimer = setTimeout(() => {
      fireWxEvent(this, "longpress", {}, currentTarget);
    }, 350);
  }

  onTouchMoveHandler() {
    clearTimeout(this.longTapTimer);
  }

  onTouchEndHandler() {
    clearTimeout(this.longTapTimer);
  }

  onTouchCancelHandler() {
    clearTimeout(this.longTapTimer);
  }


  createDataset(node) {
    var result = {};
    if (node.dataset) {
      for (var key in node.dataset) {
        result[key] = node.dataset[key];
      }
    }
    return result;
  }

  _getEventType(name) {
    if (name.indexOf("bind") == 0) {
      return name.substr(4);
    } else if (name.indexOf("catch") == 0) {
      return name.substr(5);
	} else if (name.indexOf("capturecatch") == 0)  {
		return name.substr(12);
	} else if (name.indexOf("capturebind") == 0)  {
		return name.substr(11);
    } else {
      throw new Error("不支持事件" + name);
    }
  }

  compatEventObj(event, wxEventName) {
    var newEvent = {
      type: this._getEventType(wxEventName),
      nativeEvent: event,
      timeStamp: event.timestamp,
      wxEventName: wxEventName,
      target: {},
      currentTarget: {},
      detail: {}
    };
    if(event.touches){
      newEvent.touches = event.touches;
    }
    if(event.changedTouches){
      newEvent.changedTouches = event.changedTouches;
    }

    if (event.target) {
      let target = event.target;
      target = getComponentEventTarget(target);
      newEvent.target = {
        dataset: this.createDataset(target),
        id: target.id || "",
        offsetLeft: target.offsetLeft,
        offsetTop: target.offsetTop
      }
    }

    if (event.currentTarget) {
      newEvent.currentTarget = {
        dataset: this.createDataset(event.currentTarget),
        id: event.currentTarget.id || "",
        offsetLeft: event.currentTarget.offsetLeft,
        offsetTop: event.currentTarget.offsetTop
      }
    }

    return newEvent;
  }

  createEventObjByNode(node, type, timeStamp, currentNode) {
    currentNode = currentNode || node;
    var dataset = this.createDataset(node);
    var currentDataset = this.createDataset(currentNode);
    var target = {
      id: node.dataset.propsId || node.id || "",
      offsetLeft: node.offsetLeft,
      offsetTop: node.offsetTop,
      dataset: dataset
    };
    var currentTarget = {
      id: currentNode.dataset.propsId || currentNode.id || "",
      offsetLeft: currentNode.offsetLeft,
      offsetTop: currentNode.offsetTop,
      dataset: currentDataset
    };

    return {
      type: type,
      timeStamp: timeStamp,
      detail: {},
      target: target,
      currentTarget: currentTarget,
      nativeEvent: null
    }
  }

  //TODO 由于boolean变量特殊
  toBoolean(value) {
    if (typeof value === "boolean")
      return value;
    else if (value == null || value == undefined || (value == "") || (value == "false"))
      return false;
    else
      return true;
  }

}

WxComponentBase.contextTypes = {
  __root__: PropTypes.object
};
