import React, {Component} from "react";
import PageContext from "core/framework/PageContext";
import OptionsUtils from "core/render/OptionsUtils";
import {get, omit, isObject} from 'lodash';

export default class BaseComponent extends Component {
    static contextType = PageContext;

    constructor(props, context) {
        super(props, context);
        this.__root__ = this.context;
    }

    getUseProps(componentName, key = "default", defaultValue = {}) {
        let use = this.props.use;
        if (!this.props.use) {
            return defaultValue;
        }

        let result = get(use, "['" + componentName + "']." + key, defaultValue);
        if (isObject(result)) {
            if (componentName == "Button") {
                if (!result.children && result.text) {
                    result.children = result.text;
                }
            }
            result = omit(result, "id");
        }
        return result;
    }

    fireEvent(eventName, event, defaultAction, currentTarget = this) {
        if (!event instanceof Array && !event instanceof window.Event) {
            console.warn(eventName + "不符合标准事件规范，推荐采用w3c api  new CustomEvent('name',{cancelable:true})");
        }
        let result;
        /**
         *
         *   onClick(event)   => onMyClick(event)
         *
         *   onRowRender(text,record,index) = > onMyReowRender({text,record,index})
         *
         */
        //  fireEvent 自动传递source  event instanceof CustomEvent
        if (event instanceof CustomEvent && typeof event.detail == 'object') {
            event.detail.source = currentTarget;
        }

        let eventHandler = currentTarget.props[eventName]?.bind(currentTarget);
        let resolve;
        if (event instanceof Object && defaultAction) {
            event.defaultActionPromise = new Promise((res, reject) => {
                resolve = res;
            });
        }
        if (eventHandler) {
            if (event && event instanceof Array) {
                result = eventHandler.apply(currentTarget, event);
            } else {
                //标准w3c 事件 或者平台封装可以cancel的事件 可以作为标准event传递 否则展开
                result = eventHandler(event);
            }
        }

        if (!(event && (event.cancel == true || event.defaultPrevented))) {
            result = defaultAction?.apply(currentTarget, [result]);
            resolve?.()
        }
        return result;
    }

    hasLabelRef() {
        return !!this.props.labelRefColumnName;
    }

    getLabelRefColumnName() {
        return this.props.labelRefColumnName;
    }

    getOptionsData() {
        let {optionsRefDataId} = this.props;
        return this.getData(optionsRefDataId);
    }

    getOptions() {
        let {options, optionsDisabled, optionsFilter, optionsRefDataId} = this.props;
        if (optionsRefDataId) {
            options = this.getOptionsData().toJson({ui: true});
        }
        return OptionsUtils.getOptions.bind(this)(options, optionsDisabled, optionsFilter)
    }

    getLabelRefValue(serialize = false, props = this.props) {
        let row = this.getLabelRefRow(props);
        if (row) {
            let result = row[this.getLabelRefColumnName()];
            if (serialize) {
                if (result && typeof result == "object") {
                    result = result.toString();
                }
            }
            return result;
        } else {
            return;
        }
    }


    setLabelRefValue(value) {
        let row = this.getLabelRefRow();
        if (row) {
            return row[this.getLabelRefColumnName()] = value;
        } else {
            return;
        }
    }

    getLabelRefRow(props = this.props) {
        let row = props.labelRefRow;
        if (row) {
            let data = this.getLabelRefData();
            let idColumn = data.getIdColumn();
            let rowId = row[idColumn];
            return data.getRowByID(rowId);
        } else {
            return row;
        }

    }


    getLabelRefData() {
        let dataId = this.props.labelRefDataId;
        return this.getData(dataId);
    }

    hasRef() {
        return !!this.props.refColumnName;
    }

    getRefColumnName(props = this.props) {
        return props.refColumnName;
    }

    getRefColumnDef() {
        if (this.getRefData()) {

            return this.getRefData().getColumnDefs()[this.props.refColumnName];
        }
        return;
    }


    /**
     * 特别注意 如果是日期时间类型的字段 这里的值 返回为date对象 统一要求设置也为date对象
     * @returns {*}
     */
    getRefValue(serialize = false, props = this.props) {
        let row = this.getRefRow(props);
        if (row) {
            let result = row[this.getRefColumnName(props)];
            if (serialize) {
                if (result && typeof result == "object") {
                    result = result.toString();
                }
            }
            return result;
        } else {
            return;
        }
    }

    setRefValue(value, props = this.props) {
        let row = this.getRefRow(props);
        if (row) {
            return row[this.getRefColumnName(props)] = value;
        } else {
            return;
        }
    }

    getRefRow(props = this.props) {
        let row = props.refRow;
        if (row) {
            let data = this.getRefData(props);
            let idColumn = data.getIdColumn();
            let rowId = row[idColumn];
            if (rowId) {
                return data.getRowByID(rowId);
            }
        }
    }

    //支持data的关联引用
    getLinkDataInfo() {

    }

    getRefData(props = this.props) {
        let dataId = props.refDataId;
        if (dataId) {
            return this.getData(dataId);
        }
        return;
    }

    getRefDataIdColumn() {
        return this.getRefData()?.getIdColumn() || 'key';
    }

    //dataId格式: 真实DataId:模板DataId:ref
    $getDataProxy(dataId) {
        this.$dataProxys = this.$dataProxys || {};
        let items = dataId.split(":");
        let ref = items[2];
        this.$dataProxys[ref] = this.$dataProxys[ref] || {};
        let dataProxy = this.$dataProxys[ref]
        dataProxy.$targetDataId = items[0];
        if (!dataProxy.obj) {
            //idColumn, getIdColumn, _getColDefs, _getColDefs请求走模板, 其它走真实的data
            let self = this;
            let tpl = this.callPageModelMethod(items[1]);
            let proxyGetPros = ["getIdColumn", "props", "_getColumns", "idColumn", "$targetDataId", "_getColDefs", "_getColDefs", "schema", "getColumnDefs"];
            dataProxy.obj = new Proxy(tpl, {
                get: function (target, prop, receiver) {
                    if (proxyGetPros.indexOf(prop) !== -1) {
                        return Reflect.get(...arguments);
                    } else {
                        let comp = self.$getRealData(self.$dataProxys[ref].$targetDataId, target.id, prop);
                        return comp[prop];
                    }
                },
                set: function (target, prop, val, receiver) {
                    if (prop === "$targetDataId") {
                        return Reflect.set(...arguments);
                    } else {
                        let comp = self.$getRealData(self.$dataProxys[ref].$targetDataId, target.id, prop);
                        comp[prop] = val;
                        return true;
                    }
                }
            });
        }
        return dataProxy.obj;
    }

    $getRealData(id, tplId, prop) {
        let comp = null;
        if (id) {
            comp = this.callPageModelMethod(id);
        }
        if (comp) {
            return comp;
        } else {
            throw new Error("访问" + prop + "时, 代理数据集" + tplId + "未就绪!");
        }
    }

    getData(dataId) {
        if (dataId && dataId.indexOf(":") != -1) {
            return this.$getDataProxy(dataId);
        } else {
            return this.callPageModelMethod(dataId);
        }
    }

    getPage() {
        return this.callPageModelMethod();
    }


    callPageModelMethod(compid, method, ...params) {
        let page = this.__root__.wxPageDeclaration && this.__root__.wxPageDeclaration.$page;
        if (!compid) {
            return page;
        }
        if (compid && page) {
            let wxComp = this.__root__.wxPageDeclaration.$page.comp(compid);

            if (!method) {
                return wxComp;
            }
            return wxComp[method].call(wxComp, params, this);
        }
    }
}
