import {isX5App,doThen,doCatch} from '../../utils';
import ClipboardAction from 'clipboard/src/clipboard-action';

function defaultTarget() {
  return document.querySelector("#clipboard");
}
class Clipboard{
  constructor(){
    let clipboardElement =  document.createElement("input");
    clipboardElement.id = "clipboard";
    clipboardElement.setAttribute("style","position:absolute;top:-200px");
    document.body.appendChild(clipboardElement);
  }
  copy(text,success,fail){
    defaultTarget().value = text;
    this.clipboardAction = new ClipboardAction({
      action    : "copy",
      target    : defaultTarget(),
      container : document.body,
      trigger   : defaultTarget(),
      emitter   : {
        emit:function(type,data){
            if(type == "success"){
                data.clearSelection();
                success();
            }else if(type == "error"){
                  fail();
            }
        }
      }
    });
  }
}
let clipboard = new Clipboard();

function setClipboardData(params){
  if(isX5App && cordova.plugins && cordova.plugins.clipboard){
    cordova.plugins.clipboard.copy(params.data);
    doThen(params);
  } else {
    clipboard.copy(params.data,function(e){
      doThen(params);
    },function(){
      doCatch(params);
    });
  }
}

function getClipboardData(params){
  if(isX5App && cordova.plugins && cordova.plugins.clipboard){
    cordova.plugins.clipboard.paste(function (text) {
      doThen(params,{
        data:text
      });
    });
  }else{
    doThen(params,{
      data:defaultTarget().value
    });
  }
}

export {setClipboardData,getClipboardData};
