import $ from 'jquery';
import _ from "lodash";
import ReactDOM from 'react-dom';

//#region export

function createCanvasContext(canvasId) {
  let currentPage = _.last(getCurrentReactPages());
  let pageRootNode = ReactDOM.findDOMNode(currentPage);
  if (pageRootNode) {
    let canvas = pageRootNode.querySelector(`[data-prop-canvas-id=${canvasId}]`);
    return new CanvasContext(canvas.getContext('2d'));
    //return canvas.getContext('2d');
  }
  return null;
}

function canvasToTempFilePath(options) {
  if (!options) {
    return;
  }
  if (!options.canvasId) {
    return;
  }
  if (!options.success) {
    return;
  }
  let currentPage = _.last(getCurrentReactPages());
  let pageRootNode = ReactDOM.findDOMNode(currentPage);
  if (pageRootNode) {
    let canvas = pageRootNode.querySelector(`[data-prop-canvas-id=${options.canvasId}]`);
    let x = options.x ? options.x : 0;
    let y = options.y ? options.y : 0;
    let w = options.width ? options.width : canvas.width - x;
    let h = options.height ? options.height : canvas.height - y;
    var canvasU = document.createElement("canvas");

    var ctx = canvas.getContext('2d');
    var ctxU = canvasU.getContext('2d');

    var imgData = ctx.getImageData(x, y, w, h);
    ctxU.putImageData(imgData, 0, 0);

    let res = { tempFilePath: canvasU.toDataURL("image/png") };
    options.success(res);
  }
  if (options.complete) {
    options.complete();
  }
}

function canvasGetImageData(options) {
  if (!options) {
    return;
  }
  if (!options.canvasId) {
    return;
  }
  if (!options.success) {
    return;
  }
  let currentPage = _.last(getCurrentReactPages());
  let pageRootNode = ReactDOM.findDOMNode(currentPage);
  if (pageRootNode) {
    let canvas = pageRootNode.querySelector(`[data-prop-canvas-id=${options.canvasId}]`);
    let x = options.x ? options.x : 0;
    let y = options.y ? options.y : 0;

    var ctx = canvas.getContext('2d');

    ctx.putImageData(x, y);

    let res = { data: imgData, width: w, height: h, errMsg: 'success' };
    options.success(res);
  }
  if (options.complete) {
    options.complete();
  }
}

function canvasPutImageData(options) {
  if (!options) {
    return;
  }
  if (!options.canvasId) {
    return;
  }
  if (!options.success) {
    return;
  }
  if (!options.data) {
    return;
  }
  if (!options.data.length) {
    return;
  }
  if (options.data.length % 4 != 0) {
    return;
  }
  let currentPage = _.last(getCurrentReactPages());
  let pageRootNode = ReactDOM.findDOMNode(currentPage);
  if (pageRootNode) {
    let canvas = pageRootNode.querySelector(`[data-prop-canvas-id=${options.canvasId}]`);
    let x = options.x ? options.x : 0;
    let y = options.y ? options.y : 0;
    let w = options.width ? options.width : canvas.width - x;
    let h = options.height ? options.height : canvas.height - y;

    var ctx = canvas.getContext('2d');

    var imgData = ctx.getImageData(x, y, w, h);

    let res = { errMsg: 'success' };
    options.success(res);
  }
  if (options.complete) {
    options.complete();
  }
}

//#endregion

//#region 定义
function CanvasContext(ctx) {
  this.ctx = ctx;
  let mstart = ctx.canvas.getAttribute('data-bindtouchstart');
  if (mstart) {
    ctx.canvas.addEventListener('touchstart', x => {
      x.touches[0].x = x.touches[0].pageX;
      x.touches[0].y = x.touches[0].pageY;
      //var e = x || window.event; 
      //window.event?e.cancelBubble=true:e.stopPropagation(); 
    });
  }
  let mmove = ctx.canvas.getAttribute('data-bindtouchmove');
  if (mmove) {
    ctx.canvas.addEventListener('touchmove', x => {
      x.touches[0].x = x.touches[0].pageX;
      x.touches[0].y = x.touches[0].pageY;
    });
  }
  /*
  let mend = ctx.canvas.getAttribute('data-bindtouchend');
  if (mend) {
    ctx.canvas.addEventListener('touchend', x => {

    });
  }*/
}

function Gradient(grd) {
  this.grd = grd;
}

function Metrics(metrics) {
  this.metrics = metrics;
}

function Pattern(pattern) {
  this.pattern = pattern;
}

CanvasContext.prototype.getContext = function () {
  return this.ctx;
}

CanvasContext.prototype.needLoadImgs = [];
CanvasContext.prototype.isNeedReload = false;

//#endregion

//#region 颜色，样式，阴影

//微信小程序 基础库 1.9.90 起支持
CanvasContext.prototype.__defineSetter__("fillStyle", function (color) {
  //console.log("set fillStyle");
  if (typeof (color) == "string") {
    if (this.ctx.setFillStyle) {
      this.ctx.setFillStyle(color);
    }
    this.ctx.fillStyle = color;
  }
  if (typeof (color) == 'object') {
    if (this.ctx.setFillStyle) {
      if (color.grd) {
        this.ctx.setFillStyle(color.grd);
      }
      if (color.pattern) {
        this.ctx.setFillStyle(color.pattern);
      }
    }
    if (color.grd) {
      this.ctx.fillStyle = color.grd;
    }
    if (color.pattern) {
      this.ctx.fillStyle = color.pattern;
    }
  }
});

CanvasContext.prototype.setFillStyle = function (color) {
  if (typeof (color) == "string") {
    if (this.ctx.setFillStyle) {
      this.ctx.setFillStyle(color);
    } else {
      this.ctx.fillStyle = color;
    }
  }
  if (typeof (color) == 'object') {
    if (this.ctx.setFillStyle) {
      if (color.grd) {
        this.ctx.setFillStyle(color.grd);
      }
      if (color.pattern) {
        this.ctx.setFillStyle(color.pattern);
      }
    } else {
      if (color.grd) {
        this.ctx.fillStyle = color.grd;
      }
      if (color.pattern) {
        this.ctx.fillStyle = color.pattern;
      }
    }
  }
  return this;
}

//微信小程序 基础库 1.9.90 起支持
CanvasContext.prototype.__defineSetter__("strokeStyle", function (color) {
  if (this.ctx.setStrokeStyle) {
    this.ctx.setStrokeStyle(color);
  }
  this.ctx.strokeStyle = color;
});

CanvasContext.prototype.setStrokeStyle = function (color) {
  if (this.ctx.setStrokeStyle) {
    this.ctx.setStrokeStyle(color);
  } else {
    this.ctx.strokeStyle = color;
  }
  return this;
}

//微信小程序 基础库 1.9.90 起支持
CanvasContext.prototype.__defineSetter__("shadowOffsetX", function (offsetX) {
  this.ctx.shadowOffsetX = offsetX;
});

//微信小程序 基础库 1.9.90 起支持
CanvasContext.prototype.__defineSetter__("shadowOffsetY", function (offsetY) {
  this.ctx.shadowOffsetY = offsetY;
});

//微信小程序 基础库 1.9.90 起支持
CanvasContext.prototype.__defineSetter__("shadowBlur", function (blur) {
  this.ctx.shadowBlur = blur;
});

//微信小程序 基础库 1.9.90 起支持
CanvasContext.prototype.__defineSetter__("shadowColor", function (color) {
  this.ctx.shadowColor = color;
});

CanvasContext.prototype.setShadow = function (offsetX, offsetY, blur, color) {
  if (this.ctx.setShadow) {
    this.ctx.setShadow(offsetX, offsetY, blur, color);
  } else {
    this.ctx.shadowOffsetX = offsetX;
    this.ctx.shadowOffsetY = offsetY;
    this.ctx.shadowBlur = blur;
    this.ctx.shadowColor = color;
  }
  return this;
}

//微信小程序 基础库 1.9.90 开始支持，低版本需做兼容处理
CanvasContext.prototype.createPattern = function () {
  var imageSrc = arguments[0];
  var repetition = arguments[1];
  var self = this;
  var img = $('<img />');
  img.get(0).onload = function () {
    //所有图片加载完成再刷新页面
    if (self.needLoadImgs.indexOf(imageSrc) >= 0) {
      self.needLoadImgs.splice(self.needLoadImgs.indexOf(imageSrc), 1);
    }
    if (self.needLoadImgs.length == 0 && self.isNeedReload) {
      window.location.reload();
    }
  }
  img.attr('src', imageSrc);
  $('body').append(img);

  //console.log(imageSrc);
  //建议带有取宽高的刷新动作。
  var width = img.get(0).width;
  var offsetWidth = img.get(0).offsetWidth;

  console.log("createPattern's img:" + width + "-" + offsetWidth);
  var pattern = this.ctx.createPattern(img.get(0), repetition);
  //console.log('pattern:' + pattern);
  if (null == pattern) {
    //如果出现图片加载失败，则在该图片加载完毕后刷新页面。
    this.isNeedReload = true;
    if (this.needLoadImgs.indexOf(imageSrc) < 0) {
      this.needLoadImgs.push(imageSrc);
    }
  }
  if (!this.isNeedReload) {
    //图片加载成功并且没有需要加载的图片，则删除图片
    console.log('tempImg remove!');
    img.remove();
  }
  return new Pattern(pattern);
}

//#endregion

//#region 渐变

Gradient.prototype.addColorStop = function (stop, color) {
  this.grd.addColorStop(stop, color);
  return this;
}

CanvasContext.prototype.createLinearGradient = function (x0, y0, x1, y1) {
  return new Gradient(this.ctx.createLinearGradient(x0, y0, x1, y1));
}

//Tip: 起点在圆心，终点在圆环。
CanvasContext.prototype.createRadialGradient = function (x, y, r) {
  ;
  return new Gradient(this.ctx.createRadialGradient(x, y, 0, x, y, r));
}

//#endregion

//#region 线条样式

//微信小程序 基础库 1.9.90 起支持
CanvasContext.prototype.__defineSetter__("lineWidth", function (lineWidth) {
  if (this.ctx.setLineWidth) {
    this.ctx.setLineWidth(lineWidth);
  }
  this.ctx.lineWidth = lineWidth;
});

CanvasContext.prototype.setLineWidth = function (lineWidth) {
  if (this.ctx.setLineWidth) {
    this.ctx.setLineWidth(lineWidth);
  } else {
    this.ctx.lineWidth = lineWidth;
  }
  return this;
}

//微信小程序 基础库 1.9.90 起支持
CanvasContext.prototype.__defineSetter__("lineCap", function (lineCap) {
  if (this.ctx.setLineCap) {
    this.ctx.setLineCap(lineCap);
  }
  this.ctx.lineCap = lineCap;
});

CanvasContext.prototype.setLineCap = function (lineCap) {
  if (this.ctx.setLineCap) {
    this.ctx.setLineCap(lineCap);
  } else {
    this.ctx.lineCap = lineCap;
  }
  return this;
}

//微信小程序 基础库 1.9.90 起支持
CanvasContext.prototype.__defineSetter__("lineJoin", function (lineJoin) {
  if (this.ctx.setLineJoin) {
    this.ctx.setLineJoin(lineJoin);
  }
  this.ctx.lineJoin = lineJoin;
});

CanvasContext.prototype.setLineJoin = function (lineJoin) {
  if (this.ctx.setLineJoin) {
    this.ctx.setLineJoin(lineJoin);
  } else {
    this.ctx.lineJoin = lineJoin;
  }
  return this;
}

//微信小程序 基础库 1.9.90 起支持
CanvasContext.prototype.__defineSetter__("lineJoin", function (miterLimit) {
  if (this.ctx.setMiterLimit) {
    this.ctx.setMiterLimit(miterLimit);
  }
  this.ctx.miterLimit = miterLimit;
});

CanvasContext.prototype.setMiterLimit = function (miterLimit) {
  if (this.ctx.setMiterLimit) {
    this.ctx.setMiterLimit(miterLimit);
  } else {
    this.ctx.miterLimit = miterLimit;
  }
  return this;
}

//微信小程序 基础库 1.9.90 起支持
CanvasContext.prototype.__defineSetter__("lineDashOffset", function (value) {
  this.ctx.lineDashOffset = value;
});

//#endregion

//#region 矩形

CanvasContext.prototype.rect = function (x, y, width, height) {
  this.ctx.rect(x, y, width, height);
  return this;
}

CanvasContext.prototype.fillRect = function (x, y, width, height) {
  this.ctx.fillRect(x, y, width, height);
  return this;
}

CanvasContext.prototype.strokeRect = function (x, y, width, height) {
  this.ctx.strokeRect(x, y, width, height);
  return this;
}

CanvasContext.prototype.clearRect = function (x, y, width, height) {
  this.ctx.clearRect(x, y, width, height);
  return this;
}

//#endregion

//#region 路径

CanvasContext.prototype.fill = function () {
  this.ctx.fill();
  return this;
}

CanvasContext.prototype.stroke = function () {
  this.ctx.stroke();
  return this;
}

CanvasContext.prototype.beginPath = function () {
  this.ctx.beginPath();
  return this;
}

CanvasContext.prototype.closePath = function () {
  this.ctx.closePath();
  return this;
}

CanvasContext.prototype.moveTo = function (x, y) {
  this.ctx.moveTo(x, y);
  return this;
}

CanvasContext.prototype.lineTo = function (x, y) {
  this.ctx.lineTo(x, y);
  return this;
}

CanvasContext.prototype.arc = function (x, y, r, sAngle, eAngle, counterclockwise) {
  this.ctx.arc(x, y, r, sAngle, eAngle, counterclockwise);
  return this;
}

//微信小程序 基础库 1.9.90 开始支持，低版本需做兼容处理
CanvasContext.prototype.arcTo = function (x1, y1, x2, y2, radius) {
  this.ctx.arcTo(x1, y1, x2, y2, radius);
  return this;
}

CanvasContext.prototype.quadraticCurveTo = function (cpx, cpy, x, y) {
  this.ctx.quadraticCurveTo(cpx, cpy, x, y);
  return this;
}

CanvasContext.prototype.bezierCurveTo = function (cp1x, cp1y, cp2x, cp2y, x, y) {
  this.ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y);
  return this;
}
//#endregion

//#region 变形

CanvasContext.prototype.scale = function (scalewidth, scaleheight) {
  this.ctx.scale(scalewidth, scaleheight);
  return this;
}

CanvasContext.prototype.rotate = function (angle) {
  this.ctx.rotate(angle);
  return this;
}

CanvasContext.prototype.translate = function (x, y) {
  this.ctx.translate(x, y);
  return this;
}

//微信小程序 基础库 1.9.90 开始支持，低版本需做兼容处理
CanvasContext.prototype.transform = function (scaleX, skewX, skewY, scaleY, translateX, translateY) {
  this.ctx.transform(scaleX, skewX, skewY, scaleY, translateX, translateY);
  return this;
}

//微信小程序 基础库 1.9.90 开始支持，低版本需做兼容处理
CanvasContext.prototype.setTransform = function (scaleX, skewX, skewY, scaleY, translateX, translateY) {
  this.ctx.setTransform(scaleX, skewX, skewY, scaleY, translateX, translateY);
  return this;
}

//#endregion

//#region 文字

CanvasContext.prototype.fillText = function (text, x, y, maxWidth) {
  this.ctx.fillText(text, x, y, maxWidth);
  return this;
}

CanvasContext.prototype.setFontSize = function (fontSize) {
  if (this.ctx.setFontSize) {
    this.ctx.setFontSize(fontSize);
  } else {
    this.ctx.font = fontSize + 'px';
  }
  return this;
}

//微信小程序 基础库 1.9.90 起支持
CanvasContext.prototype.__defineSetter__("textBaseline", function (textBaseline) {
  if (this.ctx.setTextBaseline) {
    this.ctx.setTextBaseline(textBaseline);
  }
  switch (textBaseline) {
    case 'top':
      this.ctx.textBaseline = 'top';
      break;
    case 'bottom':
      this.ctx.textBaseline = 'bottom';
      break;
    case 'middle':
      this.ctx.textBaseline = 'middle';
      break;
    case 'normal':
      this.ctx.textBaseline = 'alphabetic';
      break;
    default:
  }
});

CanvasContext.prototype.setTextBaseline = function (textBaseline) {
  if (this.ctx.setTextBaseline) {
    this.ctx.setTextBaseline(textBaseline);
  } else {
    switch (textBaseline) {
      case 'top':
        this.ctx.textBaseline = 'top';
        break;
      case 'bottom':
        this.ctx.textBaseline = 'bottom';
        break;
      case 'middle':
        this.ctx.textBaseline = 'middle';
        break;
      case 'normal':
        this.ctx.textBaseline = 'alphabetic';
        break;
      default:
    }
  }
  return this;
}

//微信小程序 基础库 1.9.90 起支持
CanvasContext.prototype.__defineSetter__("font", function (value) {
  this.ctx.font = value;
});

//微信小程序 基础库 1.9.90 起支持
CanvasContext.prototype.__defineSetter__("textAlign", function (align) {
  if (this.ctx.setTextAlign) {
    this.ctx.setTextAlign(align);
  }
  this.ctx.textAlign = align;
});

CanvasContext.prototype.setTextAlign = function (align) {
  if (this.ctx.setTextAlign) {
    this.ctx.setTextAlign(align);
  } else {
    this.ctx.textAlign = align;
  }
  return this;
}

//微信小程序 基础库 1.9.90 起支持
CanvasContext.prototype.strokeText = function (text, x, y, maxWidth) {
  this.ctx.strokeText(text, x, y, maxWidth);
  return this;
}

//#endregion

//#region 图片

CanvasContext.prototype.drawImage = function () {
  var img = new Image();
  img.src = arguments[0];
  var self = this;
  var args = arguments;
  img.onload = function () {
    if (args.length == 3) {
      //imgSrc, x, y
      self.ctx.drawImage(img, args[1], args[2]);
      return;
    }
    if (args.length == 5) {
      //imgSrc, x, y, width, height
      self.ctx.drawImage(img, args[1], args[2], args[3], args[4]);
      return;
    }
    //imgSrc, sx, sy, swidth, sheight, x, y, width, height
    self.ctx.drawImage(img, args[1], args[2], args[3], args[4], args[5], args[6], args[7], args[8]);
  }
  return this;
}

//#endregion

//#region 合成

//微信小程序 基础库 1.9.90 起支持
CanvasContext.prototype.__defineSetter__("globalAlpha", function (alpha) {
  if (this.ctx.setGlobalAlpha) {
    this.ctx.setGlobalAlpha(alpha);
  }
  this.ctx.globalAlpha = alpha;
});

CanvasContext.prototype.setGlobalAlpha = function (alpha) {
  if (this.ctx.setGlobalAlpha) {
    this.ctx.setGlobalAlpha(alpha);
  } else {
    this.ctx.globalAlpha = alpha;
  }
  return this;
}

//#endregion

//#region 其他

CanvasContext.prototype.save = function () {
  this.ctx.save();
  return this;
}

CanvasContext.prototype.restore = function () {
  this.ctx.restore();
  return this;
}

CanvasContext.prototype.draw = function (reserve, callback) {
  if (this.ctx.draw) {
    this.ctx.draw(reserve, callback);
  } else {
    if (callback) {
      callback();
    }
  }
  return this;
}

//微信小程序 基础库 1.6.0 开始支持，低版本需做兼容处理
CanvasContext.prototype.clip = function () {
  this.ctx.clip();
  return this;
}

//微信小程序 基础库 1.9.90 开始支持，低版本需做兼容处理
CanvasContext.prototype.measureText = function (text) {
  return new Metrics(this.ctx.measureText(text));
}

//微信小程序 基础库 1.9.90 起支持
CanvasContext.prototype.__defineSetter__("globalCompositeOperation", function (type) {
  this.ctx.globalCompositeOperation = type;
});

//#endregion



export { createCanvasContext, canvasToTempFilePath, canvasGetImageData, canvasPutImageData };
