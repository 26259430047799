import _ from 'lodash';
import {doThen,doCatch,isX5App,resolveLocalFile,toBoolean} from '../../utils'
import tabbar from "core/tabbar";
/**

 属性	类型	默认值	必填	说明
 index	number		是	tabBar 的哪一项，从左边算起
 text	string		是	显示的文本，超过 4 个字符则显示成 ...
 success	function		否	接口调用成功的回调函数
 fail	function		否	接口调用失败的回调函数
 complete	function		否	接口调用结束的回调函数（调用成功、失败都会执行）

 * @type {{setTabBarBadge: Tabbar.setTabBarBadge}}
 */

const Tabbar={
		setTabBarBadge:function(params){
		  	let index = params.index;
        let text = params.text;
        if(text.length > 4){
          text = "...";
        }
        tabbar.setTabBarBadge(index,text);
        doThen(params);
		}
}
export {Tabbar}
