import _ from 'lodash';
import {wx,doThen,doCatch,isX5App,resolveLocalFile} from '../../utils';
import domify from 'domify'
import './loading.css';

/***
 *OBJECT参数说明：

 属性	类型	默认值	必填	说明
 title	string		是	提示的内容
 mask	boolean	false	否	是否显示透明蒙层，防止触摸穿透
 success	function		否	接口调用成功的回调函数
 fail	function		否	接口调用失败的回调函数
 complete	function		否	接口调用结束的回调函数（调用成功、失败都会执行）
 示例代码

  wx.showLoading({
    title: '加载中',
  })

  setTimeout(function () {
    wx.hideLoading()
  }, 2000)
 *
 */

const temp=
	`<div class="wx-loader-full">
        <div class="ball-scale-ripple-multiple">
            <div class="wx-loader-view"></div>
            <div class="wx-loader-view"></div>
            <div class="wx-loader-view"></div>
            <div class="wx-loader-view"></div>
            <div class="wx-loader-view"></div>
        </div>
        <span class="wx-loader-text"><%= title%></span>
    </div>`;
let element=null;
function showLoading(params){
	let {title=""}=params;
	//将模板转换为DOM对象
	if(element && element.parentNode){
		element.parentNode.removeChild(element);
	}
	element=domify(_.template(temp)({
		title
	}));
	document.body.appendChild(element);
	let called = false;
  return doThen(params,{errMsg:"showLoading:ok"});
}

function hideLoading(params){
  let {title=""}=params;
  //将模板转换为DOM对象
  if(element && element.parentNode){
    element.parentNode.removeChild(element);
  }
  return doThen(params,{errMsg:"hideLoading:ok"});
}


export {showLoading,hideLoading}


