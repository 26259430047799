import {doThen,doCatch,isX5App,resolveLocalFile} from '../../utils';
let audio;
let audioInfo = {};

/*
 duration	选定音频的长度（单位：s），只有在当前有音乐播放时返回
 currentPosition	选定音频的播放位置（单位：s），只有在当前有音乐播放时返回
 status	播放状态（2：没有音乐在播放，1：播放中，0：暂停中）
 downloadPercent	音频的下载进度（整数，80 代表 80%），只有在当前有音乐播放时返回
 dataUrl	歌曲数据链接，只有在当前有音乐播放时返回
 */
function getBackgroundAudioPlayerState(params={}) {
  let result = {};
  result.status = 2;
  if (audio) {
    if (audio.paused) {
      if (audio.currentTime === 0) {
        result.status = 2;
      } else {
        result.status = 0;
      }
    } else if (audio.ended) {
      result.status = 2;
    } else {
      result.status = 1;
    }
    result.duration = audio.duration;
    result.currentPosition = parseInt(audio.currentTime);//moment("20000101").add(audio.currentTime, 's').format('ss'); //audio.currentTime;
    result.dataUrl = audio.src;
    let buffered = result.buffered;
    if (buffered && buffered.end) {
      let l = buffered.end.length;
      let endTime = buffered.end[l - 1];
      if (result.duration && endTime) {
        result.downloadPercent = endTime / result.duration * 100;
      }
    }
    doThen(params, result);
  } else{
	 doCatch(params, result)
  }

}
/*
 dataUrl	String	是	音乐链接
 title	String	否	音乐标题
 coverImgUrl	String	否	封面URL
 success	Function	否	接口调用成功的回调函数
 fail	Function	否	接口调用失败的回调函数
 complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
 */
function playBackgroundAudio(params ={}) {
  const {dataUrl}=params;
  if(!dataUrl) {
	  console.warn(" 播放源地址(dataUrl)  不能为空");
	  return
  }
  if (audio && audio.paused &&  audioInfo.currentFilePath === dataUrl) {
    audio.play();
    doThen(params);
  } else {
    audioInfo.currentFilePath = dataUrl;
    audio = new window.Audio();
    audio.onerror = function (error) {
      doCatch(params, error);
    };
    audio.oncanplay = function () {
      doThen(params);
    };
    audio.src = dataUrl;
    audio.play();
  }
}
function pauseBackgroundAudio(params={}) {
  audio.pause();
  doThen(params);
}
/*
 position	Number	是	音乐位置，单位：秒
 success	Function	否	接口调用成功的回调函数
 fail	Function	否	接口调用失败的回调函数
 complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
 */
function seekBackgroundAudio(params={}) {
  try {
    audio.currentTime = params.position;
    doThen(params);
  } catch (e) {
    doCatch(params, e);
  }

}
function stopBackgroundAudio(params={}) {
  audio.pause();
  audio.currentTime = 0;
  setTimeout(function(){
	  doThen(params);
  },300)

  if (audio.onstop) {
    audio.onstop.call(audio);
  }
}
function onBackgroundAudioPlay(callback) {
    if(audio){
    	audio.oncanplay = callback;
    }
}
function onBackgroundAudioPause(callback) {
	if(audio){
		audio.onpause = callback;
	}
}
function onBackgroundAudioStop(callback) {
	if(audio){
		audio.onstop = callback;
	}
}

function getBackgroundAudioManager(){
  if(!window.backgroundAudioManager){
    let backgroundAudioManager =  new Audio();
    backgroundAudioManager.preload = true;
    backgroundAudioManager.autoplay = true;
    ['Canplay','Play','Pause','Stop','Ended','TimeUpdate','Error','Waiting','Seeking','Seeked'].forEach((func)=>{
      backgroundAudioManager['on' + func] = function(callback){
        backgroundAudioManager.addEventListener(toLower(func),callback);
      };
      backgroundAudioManager['off' + func] = function(callback){
        backgroundAudioManager.removeEventListener(toLower(func),callback);
      };
    });
    window.backgroundAudioManager = backgroundAudioManager;

    return backgroundAudioManager;
  }
}

export {getBackgroundAudioPlayerState,playBackgroundAudio,pauseBackgroundAudio,seekBackgroundAudio,stopBackgroundAudio,onBackgroundAudioPlay,onBackgroundAudioPause,onBackgroundAudioStop,getBackgroundAudioManager}
