import React, {Component} from 'react';
import WxComponentBase from "components/wx/base/WxComponentBase";

import {fireWxEvent} from "../utils";
export default class WxContainer extends WxComponentBase {
  constructor(...args){
    super(...args);
  }

  render(){
    if(this.props.params){
      let wxPage = this.__root__.wxPageDeclaration.$page;
      //传递PageComponent的state 使用不同的对象 方便扩展自己上下文
      let contextVars = {...this.__root__.state.data};
      //合并wxContainer自身属性
      let {children,...wxContainerProps} = this.props;



      Object.assign(contextVars,wxContainerProps);
      //当前JsxContainer模块上下文
      Object.assign(contextVars,this.props.params);
      //这里直接渲染wxContainer的子节点 等于去掉了wxContainer
      return children(contextVars);
    }
    return <></>
  }
}
