import _ from 'lodash';
import domify from 'domify'
import {doThen,doCatch,isX5App,resolveLocalFile,toBoolean} from '../../utils'

/**
 * @showToast,
 * @hideToast
 *
 * 	参数			类型			必填			说明
	title		String		是		提示的内容
	icon		String		否		图标，只支持"success"、"loading"
	duration	Number		否		提示的延迟时间，单位毫秒，默认：1500, 最大为10000
	success		Function	否		接口调用成功的回调函数
	fail		Function	否		接口调用失败的回调函数
	complete	Function	否		接口调用结束的回调函数（调用成功、失败都会执行）
 */

const temp=`
		<div>
			<% if(toBoolean(mask)) {%>
				<div class="wx-toast-mask"></div>
			<%}%>
			<div class="wx-toast">
			<% if ( icon){%>
				<i class="wx-toast-icon <%= toggleIcon%>"  style="color:#FFFFFF;font-size:55px;display:block"></i>
			<%}%>
				<p class="wx-toast-content"><%= title%></p>
			</div>
		</div>
`

const Toast={
		showToast:function(params){
			let {title="",icon="success",duration=1500,mask=false}=params;
			duration = parseInt(params.duration) ? params.duration : 1500;
			//将模板转换为dom对象
			let element=domify(_.template(temp)({
				title,
				icon,
				"toggleIcon":(icon.trim()==="loading"?"wx-icon-loading":icon.trim()==="success"?"wx-icon-success":icon.trim()==="none"?"":("wx-icon-"+icon.trim())),
				mask,
				toBoolean
			}));
			duration=Math.min(10000,duration);
			document.body.appendChild(element);
			doThen(params,{
				errMsg:"showToast:ok"
			});
      let timer = window.setTimeout(()=>{
        if(element.parentNode){
          element.parentNode.removeChild(element);
        }
      },duration);
      this.timers = this.timers || [];
			this.timers.push({
			  timer,element
      });
		},
		hideToast:function(params){
		  let timer = this.timers && this.timers.pop();
		  if(timer){
        window.clearTimeout(timer.timer);
        if(timer.element){
        	if(timer.element.parentNode){
        		timer.element.parentNode.removeChild(timer.element);
        	}else{
        		timer.element.remove()
        	}
        }
      }
		}
}
export {Toast}
