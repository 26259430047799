import detector from 'detector';
let lastTouchY = 0 ;
let maybePreventPullToRefresh = false ;

let touchstartHandler = function(e) {
  if( e.touches.length != 1 ) {
    return ;
  }
  lastTouchY = e.touches[0].clientY ;
  maybePreventPullToRefresh = (window.pageYOffset == 0) ;
};

let touchmoveHandler = function(e) {
  let touchY = e.touches[0].clientY ;
  let touchYDelta = touchY - lastTouchY ;
  lastTouchY = touchY ;
  if (maybePreventPullToRefresh) {
    maybePreventPullToRefresh = false ;
    if (touchYDelta > 0) {
      e.preventDefault();
    }
  }
};
if(detector.os.name == "android"){
  document.addEventListener('touchstart', touchstartHandler,global.supportsPassive? { passive: true }:false);
  document.addEventListener('touchmove', touchmoveHandler,global.supportsPassive? { passive: false }:false);
}
