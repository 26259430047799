import hoistNonReactStatic from 'hoist-non-react-statics';
import PropTypes from "prop-types";

export function wrapperWithComponent(HocComponent) {
  return (OriginComponent) => {

    let CombinedComponent;
    if (OriginComponent && OriginComponent.__esModule == true) {
      OriginComponent = OriginComponent.default;
    }
    if (!OriginComponent) {
      throw new Error("wrapperWithComponent error OriginComponent不存在");
    }
    if (OriginComponent.prototype && OriginComponent.prototype.render) {
      //类声明
      CombinedComponent = class extends React.Component {
        render() {
          return <HocComponent {...this.props} innerComponent={OriginComponent}>
          </HocComponent>
        }
      }
      // Format for display in DevTools
      let name = OriginComponent.displayName || OriginComponent.name || 'Unknown';
      CombinedComponent.displayName = `wrapperedComponent(${name})`;

    } else {
      CombinedComponent = (props) => (
        <HocComponent {...props} innerComponent={OriginComponent}>
        </HocComponent>
      );
    }

    CombinedComponent.contextTypes = {
      __root__: PropTypes.object
    };

    //复制静态属性
    hoistNonReactStatic(CombinedComponent,OriginComponent);
    return CombinedComponent;
  }
}

