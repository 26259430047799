import hoistNonReactStatic from 'hoist-non-react-statics';
import React,{Component,Fragment,Profiler} from 'react';
import { isFragment } from 'react-is';
import {shallowEqual} from 'core/compShallowEqual';
import {remove,uniqueId} from 'lodash';
import PropTypes from "prop-types";
import WxComponentBase from "../../components/wx/base/WxComponentBase";
import {processBindProps,processLoadingProps} from './CommonBindPropsRender';

function withRenderWrapper(WrappedComponent){
  if(WrappedComponent == Fragment){
    return WrappedComponent;
  }


  //let ParentComponent = (WrappedComponent.prototype && WrappedComponent.prototype.isReactComponent)?WrappedComponent:Component;
  class ComponentRenderWrapper extends Component {
    constructor(props,context) {
      super(props,context);
      this.__root__ = this.__root__ || context.__root__;
      this.loadingReactionDispose = processLoadingProps(props,this.__root__,this);
      //this.compId = props.id + uniqueId();
    }

    sendRerenderCheckMessage(pid){
      this.forceUpdate();
    }

    componentWillUnmount() {
      if(this.id){
        let indexOf =this.__root__.wrapperInstanceMap.id[this.id].indexOf(this);
        if(indexOf != -1){
          this.__root__.wrapperInstanceMap.id[this.id].splice(indexOf,1);
        }
      }
      if(this.compId){
        delete this.__root__.wrapperInstanceMap.compId[this.compId]
      }

      if(this.loadingReactionDispose){
        this.loadingReactionDispose();
      }
    }

    //compId 转dataPath  @hcr  list0[0].input1  =>  list0.items[0].$children.input1
    compIdToDataPath(compId){
      return compId.replace(/\[([0-9]*)\]/g,".items[$1].$children");
    }


    render() {
      let props = this.props;
      if(this.__root__.pageType == "uix"){
        props = processBindProps(this.props,this.__root__, this);
      }
      if(React.isValidElement(props)){
        return props;
      }
      let {children,forwardRef,...otherProps} = props;
      for(let propKey in otherProps){
        let propValue = otherProps[propKey];

        if(typeof propValue == "function" &&  propValue.$$typeof == "dataPropFn"){
          propValue =  propValue();
          otherProps[propKey] = propValue;
        }


        if(propKey === "data-compid" && propValue){
          this.compId = this.compIdToDataPath(propValue);
          this.__root__.wrapperInstanceMap.compId[this.compId] = this;

        }else if(propKey === "id" && propValue){
          this.id = propValue;
          this.__root__.wrapperInstanceMap.id[propValue] = this.__root__.wrapperInstanceMap.id[propValue] || [];
          if(this.__root__.wrapperInstanceMap.id[propValue].indexOf(this) == -1){
            this.__root__.wrapperInstanceMap.id[propValue].push(this);
          }
        }else if(propKey=="directRender" && propValue == "true"){
          setTimeout(() =>{
            console.log("开启直接渲染模式");
            this.__root__.directRender = true;
          },3000);

        }
      }

      if(WrappedComponent == Fragment){
        delete otherProps.id;
      }
      let result;
      if(children === undefined || children === undefined){
        result = <WrappedComponent {...otherProps} ref={forwardRef} {...this.state}></WrappedComponent>;

      }else{
        children = processChildren(children);
        result = <WrappedComponent {...otherProps} ref={forwardRef} {...this.state}>{children}</WrappedComponent>;

      }


      if(props.profiler){
        return <Profiler id={props.id} onRender={(
          id, // 发生提交的Profiler树的“id”
          phase, // "mount"（如果树刚刚挂载）或"update"（如果树重新渲染）
          actualDuration, // 本次更新 committed 花费的渲染时间
          baseDuration, // 估计不使用memoization的情况下整个子树渲染时间
          startTime, // 本次更新中React开始渲染的时间
          commitTime, // 本次更新中React committed的时间
          interactions // 属于本次更新的interactions的集合
        ) =>{
          console.log(`${id}渲染时间: ${actualDuration}`);
        }}>
          {result}
        </Profiler>
      }else {
        return result;
      }
    }
  }

  ComponentRenderWrapper.contextTypes = {
    __root__: PropTypes.object
  };

  const ForwardedComponent = React.forwardRef((props, ref)=>{
    return <ComponentRenderWrapper {...props} forwardRef={ref} />;
  });
  ForwardedComponent.displayName = WrappedComponent.displayName || WrappedComponent.name;
  ForwardedComponent.isRenderWrapper = true
  hoistNonReactStatic(ForwardedComponent,WrappedComponent);
  return ForwardedComponent;
}

withRenderWrapper.WithRenderWrapperFragment =  withRenderWrapper(Fragment);



export function processChildren(children){
  if(children) {
    if(typeof children === "object" &&  children.length > 1){
      children = children.map((child) => {
        if(typeof child == "function" &&  child.$$typeof == "dataPropFn" && child.$$wrapperIds) {
          let WithRenderWrapperFragment =  withRenderWrapper.WithRenderWrapperFragment;
          let wrapperIds = child.$$wrapperIds;
          delete child.$$wrapperIds;
          return <WithRenderWrapperFragment id={wrapperIds}>{child}</WithRenderWrapperFragment>;
        }else if(typeof child == "function" &&  child.$$typeof == "dataPropFn"){
          return child();
        }else {
          return child;
        }
      })
    }else if(typeof children == "function" &&  children.$$typeof == "dataPropFn"){
      if(children.$$wrapperIds) {
        let WithRenderWrapperFragment =  withRenderWrapper.WithRenderWrapperFragment;
        let wrapperIds = children.$$wrapperIds;
        delete children.$$wrapperIds;
        children = <WithRenderWrapperFragment id={wrapperIds}>{children}</WithRenderWrapperFragment>;
      }else {
        children = children();
      }
    }
  }
  return children;
}



//当前方案基于hoc有嵌套问题 基于猴子补丁是方案2
export default withRenderWrapper;


