import _ from 'lodash';
import {doThen,doCatch,checkStatus,resolveLocalFile} from '../../../utils';
import moment from 'moment';
/*
wx.downloadFile({
  url: 'https://localhost:3000/beep.wav',
  type: 'audio',
  success: function(res) {
    wx.saveFile({
      tempFilePath: res.tempFilePath
    })
  }
})
*/


function _getSaveElement() {
  let a = document.querySelector('#_saveFile');
  if (!a) {
    a = document.createElement("a");
    a.id = "_saveFile";
    document.body.appendChild(a);
    a.style.display = "none";
  }
  return a;
}

/**
 tempFilePath  String  是  需要保存的文件的临时路径
 success  Function  否  返回文件的保存路径，res = {savedFilePath: '文件的保存路径'}
 fail  Function  否  接口调用失败的回调函数
 complete  Function  否  接口调用结束的回调函数（调用成功、失败都会执行）
 */
let fileList=[];
function saveFile(params) {
  let a = _getSaveElement();
  let promise = new Promise(function (resolve) {
	  resolveLocalFile(params.tempFilePath).then(file=>{
		  let url = window.URL.createObjectURL(file);
		  a.href = url;
		  a.download = params.fileName;
		  a.click();
		  //window.URL.revokeObjectURL(url);
		  fileList.push({
			  filePath: url,
			  createTime: moment().format('X'),
			  size:file['size']
		  });
		  resolve({
			  savedFilePath: url
		  });
	  })
  });
  promise.then(function (res) {
    doThen(params, res);
  }).catch(function (error) {
    doCatch(params, error);
  });
}

/**
 * 获取本地已保存的文件列表

    OBJECT参数说明：

    参数	类型	必填	说明
    success	Function	否	接口调用成功的回调函数，返回结果见success返回参数说明
    fail	Function	否	接口调用失败的回调函数
    complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
    success返回参数说明：

    参数	类型	说明
    errMsg	String	接口调用结果
    fileList	Object Array	文件列表
    fileList中的项目说明：

    键	  类型	说明
    filePath	String	文件的本地路径
    createTime	Number	文件的保存时的时间戳，从1970/01/01 08:00:00 到当前时间的秒数
    size	Number	文件大小，单位B
 *
 */

function getSavedFileList(params = {}) {
  return new Promise(resolve => {
    resolve({
      errMsg: "getSavedFileList:ok",
      fileList
    })
  }).then(res => {
    doThen(params, res)
  }).catch(error => {
    doCatch(params, error)
  })
}

/**
 * wx.getSavedFileInfo(OBJECT)
    获取本地文件的文件信息

    OBJECT参数说明：

    参数	类型	必填	说明
    filePath	String	是	文件路径
    success	Function	否	接口调用成功的回调函数，返回结果见success返回参数说明
    fail	Function	否	接口调用失败的回调函数
    complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
    success返回参数说明：

    参数	类型	说明
    errMsg	String	接口调用结果
    size	Number	文件大小，单位B
    createTime	Number	文件的保存是的时间戳，从1970/01/01 08:00:00 到当前时间的秒数
 */

function getSavedFileInfo(params = {}) {
  return new Promise((resolve,reject) => {
    const file = _.filter(fileList, file => file.filePath === params.filePath);
    if (file) {
      resolve({
        errMsg: "getSavedFileInfo:ok",
        size: file[0].size,
        createTime: file[0].createTime
      })
    }else {
    	reject('filePath is not in FileList');
    }
  }).then(res => {
    doThen(params, res)
  }).catch(error => {
    doCatch(params, error)
  })
}
  /**
 * wx.removeSavedFile(OBJECT)
    删除本地存储的文件

    OBJECT参数说明：

    参数	类型	必填	说明
    filePath	String	是	需要删除的文件路径
    success	Function	否	接口调用成功的回调函数
    fail	Function	否	接口调用失败的回调函数
    complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
 */

function removeSavedFile(params = {}) {
  return new Promise((resolve,reject) => {
    const oldFileList = fileList;
    fileList = _.filter(fileList, file => file.filePath !== params.filePath);
    if (!_.isEqual(oldFileList, fileList)) {
      resolve({
        errMsg: "removeSavedFile:ok"
      })
    }else {
    	reject('filePath is not in FileList');
    }
  }).then(res => {
    doThen(params, then)
  }).catch(error => {
    doCatch(params, error)
  })
}

/**
 *   wx.openDocument(OBJECT)
    新开页面打开文档，支持格式：doc, xls, ppt, pdf, docx, xlsx, pptx

    OBJECT参数说明：

    参数	说明	必填	说明
    filePath	String	是	文件路径，可通过 downFile 获得
    success	Function	否	接口调用成功的回调函数
    fail	Function	否	接口调用失败的回调函数
    complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
 *
 */
const supportFormats = ['.doc','.xls','.ppt','.pdf','.docx','.xlsx','.pptx'];

function openDocument(params){
	const {filePath} = params;
	return new Promise((resolve,reject) => {
	  resolveLocalFile(filePath).then(file=>{
		let url = window.URL.createObjectURL(file);
		wx.showModal({
		  title:"提示:",
		  content:"文档在线查看能力暂不支持,您可以<a target='_blank' download href='"+ url +"'>下载</a>后查看."
		});
		resolve({});
	  });
	}).then(res => {
		doThen(params, res)
    }).catch(error => {
		doCatch(params, error)
	});	
}

export {saveFile,getSavedFileList,getSavedFileInfo,removeSavedFile,openDocument };
