import {concat, merge, set, get, isArray, forEach, isObject, isString, isEmpty, keys} from "lodash";
import i18next from "i18next";
import mitt from "mitt";

import Dexie from 'dexie';

const configContextStore = new Dexie('configContext');
// Declare tables, IDs and indexes
configContextStore.version(1).stores({
  config: '&id,userId,serviceName,name,content'
});
configContextStore.open();

import localforage from "localforage";

export default class ConfigContextProcessor {
  static emitter = mitt();
  static configContextProcessors = new Set();
  static localStore = localforage.createInstance({
    name: "configContext",
    driver: localforage.LOCALSTORAGE, // Force WebSQL; same as using setDriver()
    storeName: 'configContext_store', // Should be alphanumeric, with underscores.
    description: '应用的上下文配置存储'
  });
  static configContextStore = configContextStore;

  static reset(){
    [...ConfigContextProcessor.configContextProcessors].map((processor) => {
      processor.processed = false;
      processor.inited = false;
    });
    i18next.isInitialized = false;
  }

  static registerProcess(processor) {
    ConfigContextProcessor.configContextProcessors.add(processor);

  }

  static enhancePageAdvice(page) {
    let parts = (page.pagePath + ".w").split('/');
    parts = parts.map((item) => {
      return "/" + item;
    });
    parts[0] = 'config';
    let config = get(page.configContext, parts);
    if (config) {
      Object.keys(config).forEach(key => {
        let functionConfig = config[key];
        let func = page[key]?.bind(page);
        if (typeof func == "function") {
          let beforeAdvice = functionConfig?.['@before']?.bind(page);
          let afterAdvice = functionConfig?.['@after']?.bind(page);
          let replaceAdvice = functionConfig?.['@replace']?.bind(page);
          if (beforeAdvice || afterAdvice || replaceAdvice) {

            let doFunc = (args)=>{
              replaceAdvice && (func = replaceAdvice);

              let result = func(...args);

              if (result && result instanceof Promise) {
                return afterAdvice ? result.then(async (result) => {
                  return await afterAdvice(result, ...args);
                }):result;
              }else {
                return afterAdvice? afterAdvice(result, ...args) : result;
              }
            }

            page[key] = function (...args) {

              if(beforeAdvice){
                let beforeResult = beforeAdvice(...args);
                if(beforeResult && beforeResult instanceof Promise){
                  return beforeResult.then(async (result)=>{
                    return doFunc(args);
                  });
                }
              }
              return doFunc(args);
            }
          }
        }
      })
    }
  }


  constructor(page, context) {
    this.page = page;
    this.context = context;
  }

  async processConfigContext(enableI18n) {

    this.configContext = this.context.configContext || {};
    this.page.configContext = this.configContext;
    //await this.restore();
    await this.restoreLanguageId();
    [...ConfigContextProcessor.configContextProcessors].map((processor) => {
      if (processor.processed) {
        return;
      }
      processor.processed = true;
      return processor?.processConfigContext(this.page.configContext);
    })

    this.processI18nResource();
    //开发时候判断是否启用多语言 多语言列表一定是多个 运行时可以主动开启
    this.i18nEnabled = (keys(this.configContext.i18n).length > 1) || enableI18n;


    await this.initI18n();
    try {
      await Promise.all(concat([...ConfigContextProcessor.configContextProcessors].map((processor) => {
        if (processor.inited) {
          return;
        }
        processor.inited = true;
        return processor?.onConfigContextInit(this);
      }), [this.loadI18nResource()]));
    } catch (e) {
      //多语言资源加载失败 不影响使用
      console.error(e);
    }


    let parentConfigContext = this.page.props.configContext || this.configContext;


    /*merge(parentConfigContext, this.configContext);
    merge(this.page.configContext, parentConfigContext);
    merge(this.configContext, this.page.configContext);


    this.initI18nStyle();
    this.postProcessI18nResource();
    this.initThemeStyle();
    merge(parentConfigContext, this.configContext);
    ConfigContextProcessor.emitter.emit('onPageConfigContextInited', this.configContext);*/


    //这里调整优先级 父的配置优先级比子的高
    merge(this.page.configContext, parentConfigContext);
    merge(this.configContext, this.page.configContext);


    this.initI18nStyle();
    this.postProcessI18nResource();
    this.initThemeStyle();
    merge(parentConfigContext, this.configContext);
    ConfigContextProcessor.emitter.emit('onPageConfigContextInited', this.configContext);
    //this.persistence();
  }

  persistence() {
    ConfigContextProcessor.localStore.setItem("configContext", this.stringify());
  }

  async restore() {
    let content = await ConfigContextProcessor.localStore.getItem("configContext");
    if (content) {
      let configContext = JSON.parse(content);
      merge(this.configContext, configContext);
    }
  }

  addI18nParentRef() {
    function processParentRef(data) {
      if (isArray(data)) {
        forEach(data, (item) => {
          if (isObject(item)) {
            item.$parent = data;
          }
          processParentRef(item);
        })
      } else if (isObject(data)) {
        forEach(data, (item, key) => {

          if (key != "$parent") {
            if (isObject(item)) {
              item.$parent = data;
            }
            processParentRef(item);
          }
        })
      }
    }

    processParentRef(this.page.configContext.i18n);
  }

  removeI18nParentRef() {
    function processParentRef(data) {
      if (isObject(data)) {
        delete data["$parent"];
        forEach(data, (item, key) => {
          if (isObject(item)) {
            processParentRef(item);
          }
        })
      }
    }

    processParentRef(this.page.configContext.i18n);
  }

  moveDataI18nInfoToUI() {
    let serviceInfo = get(this.page.getCurrentLanguage(), (this.page.serviceName ? "resource./" + this.page.serviceName : "resource") + "./service");
    let dataInfo = {};

    function calculateDataInfo(data) {
      forEach(data, (item, key) => {
        if (key == "$parent") {

        } else if (key.indexOf('data.m') == -1) {
          if (isObject(item)) {
            calculateDataInfo(item);
          }

        } else {
          merge(dataInfo, item);
        }
      })
    }

    calculateDataInfo(serviceInfo);
    let uiDataInfo = {resource: {}};

    if (this.page.serviceName) {
      uiDataInfo.resource["/" + this.page.serviceName] = dataInfo;
    } else {
      uiDataInfo.resource = dataInfo;
    }
    merge(this.page.getCurrentLanguage(), uiDataInfo);
  }

  fillInheritedDefaultValues() {
    this.addI18nParentRef();

    function processInheritedDefaultValue(data) {
      if (isArray(data)) {
        forEach(data, (item) => {
          processInheritedDefaultValue(item);
        })
      } else if (isObject(data)) {
        forEach(data, (item, key) => {
          if (key == "$parent" || key == "components") {
            return;
          }
          if (isString(item) && isEmpty(item)) {
            let parent = data.$parent;
            while (parent) {
              if (parent[key]) {
                data[key] = parent[key];
                break;
              }
              parent = parent.$parent;
            }
          }
          processInheritedDefaultValue(item);
        })
      }
    }

    processInheritedDefaultValue(this.page.getCurrentLanguage(), this.page.serviceName ? "resource." + this.page.serviceName : "resource");
    this.removeI18nParentRef();
  }

  postProcessI18nResource() {
    this.moveDataI18nInfoToUI();

    this.fillInheritedDefaultValues();
  }

  stringify() {
    return JSON.stringify(this.page.configContext, (key, value) => {
      return key == "$parent" ? "" : value;
    })
  }

  load(content) {
    let configContext = JSON.parse(content);
    merge(this.configContext, configContext);
    this.postProcessI18nResource();
  }

  processI18nResource(configContext = this.configContext) {
    //处理微服务隔离逻辑
    let i18n = configContext.i18n || {};
    for (let key in i18n) {
      let lang = i18n[key]?.["resource"];
      if (lang?.["$UI"]) {
        if (this.page.serviceName) {
          set(lang, "/" + this.page.serviceName + "./" + this.page.contextName, lang["$UI"]);
        } else {
          set(lang, "/" + this.page.contextName, lang["$UI"])
        }
        delete lang["$UI"];
      }
      if (lang?.["$serviceName"]) {
        if (this.page.serviceName) {
          set(lang, "/" + this.page.serviceName, lang["$serviceName"]);
        } else {
          merge(lang, lang["$serviceName"])
        }
        delete lang["$serviceName"];
      }

      if (lang?.["$service"]) {
        if (this.page.serviceName) {

          set(lang, "/" + this.page.serviceName + "./service", lang["$service"]);
        } else {
          set(lang, "/service", lang["$service"]);
        }
        delete lang["$service"];
      }
    }
  }

  async initI18next(languageId) {
    if (!i18next.isInitialized) {
      await i18next.init({
        resources: this.configContext.i18n,
        lng: languageId,
        fallbackLng: [],
        ns: 'resource',
        defaultNS: 'resource',
        load: "currentOnly",
        keySeparator: ">",
        debug: false
      });
      await i18next.changeLanguage(languageId);
    }
  }

  async initI18n() {

    if (this.configContext.i18n) {
      let languageId = this.page.getCurrentLanguage().languageId;

      await this.initI18next(languageId);

    }

  }

  async loadI18nResource() {
    if (!this.i18nEnabled) {
      return;
    }

    let currentLanguageId = this.page.getCurrentLanguage().languageId;


    let configContext = {};

    await Promise.all([(async (resolve, reject) => {
      let frontendBasePath = this.page.parentPath + this.page.pagePath.replace(/(.*)\/((.*)(pcx|pc|mobile)app\/)(.*)/, "$1/$2$3$4")

      let contextInfo = get(this.configContext, "i18n.resource." + currentLanguageId + "./" + this.page.serviceName + "./" + this.page.contextName);
      if (contextInfo) {
        return;
      }
      let {data} = await this.page.request({
        url: frontendBasePath + "/i18n/" + currentLanguageId + "/resource.json"
      })
      let _configContext = {};
      set(_configContext, "i18n." + currentLanguageId, {
        resource: data,
        language: currentLanguageId
      });
      merge(configContext, _configContext);
      return;
    })().catch(e => e), (async (resolve, reject) => {
      let frontendBasePath = this.page.parentPath + this.page.pagePath.replace(/(.*)((pcx|pc|mobile)app\/)(.*)/, "$1$2")
      let backendBasePath = this.page.parentPath + this.page.serviceName ? "/" + this.page.serviceName : "";
      let contextInfo = get(this.configContext, "i18n.resource." + currentLanguageId + "./" + this.page.serviceName + "./service");
      if (contextInfo) {
        return;
      }
      let {data} = await this.page.request({
        url: backendBasePath + "/serviceMetaInfo/i18n/" + currentLanguageId + "/resource.json"
      })

      let _configContext = {};
      set(_configContext, "i18n." + currentLanguageId, {
        resource: data,
        language: currentLanguageId
      });
      merge(configContext, _configContext);
      return;
    })().catch(e => e)]);

    this.processI18nResource(configContext);
    merge(this.configContext, configContext);
  }

  initThemeStyle() {
    let theme = this.page.getCurrentTheme();
    let id = "configContext_theme_style";
    let themeStyleNode = document.getElementById(id);
    let themeStyleText = theme?.style?.["*"];
    if (!themeStyleNode && themeStyleText) {
      const styleElement = document.createElement('style');
      styleElement.setAttribute("id", id);
      styleElement.appendChild(document.createTextNode(themeStyleText));
      document.head.appendChild(styleElement);
    }
  }

  initI18nStyle() {
    let lang = this.page.getCurrentLanguage();
    let id = "configContext_i18n_style";
    let i18nStyleNode = document.getElementById(id);
    let themeStyleText = lang?.style?.["*"];
    if (!i18nStyleNode && themeStyleText) {
      const styleElement = document.createElement('style');
      styleElement.setAttribute("id", id);
      styleElement.appendChild(document.createTextNode(themeStyleText));
      document.head.appendChild(styleElement);
    }
  }

  async restoreLanguageId() {
    //如果父页面有运行时指定的优先 否则从缓存中来
    if (this.page.props?.configContext?.currentLanguageId) {
      merge(this.configContext, {currentLanguageId: this.page.props?.configContext?.currentLanguageId});
      merge(this.page.configContext, {currentLanguageId: this.page.props?.configContext?.currentLanguageId});
    } else {
      //默认从strage中来
      let storeInfo = await ConfigContextProcessor.localStore.getItem("configContext.currentLanguageId");
      merge(this.configContext, storeInfo);
      merge(this.page.configContext, storeInfo);
    }

  }
}

