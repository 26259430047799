import {doThen, doCatch, isX5App, resolveLocalFile} from './../utils';
import {getStorageSync} from './data/storage';
import {request} from './network/request'


/*
 errMsg	String	调用结果
 code	String	用户允许登录后，回调内容会带上 code（有效期五分钟），开发者需要将 code 发送到开发者服务器后台，使用code 换取 session_key api，将 code 换成 openid 和 session_key
 */
let usercode = localStorage.getItem("uixweb_weixin_login_code");
if (!usercode) {
  usercode = "u" + new Date().getTime();
  localStorage.setItem("uixweb_weixin_login_code", usercode);
}
const mockLogin = {
  errMsg: 'login:ok',
  code: "m_" + usercode
};



let getWxInfoDfd;
function _getWxInfo(){
  if(!getWxInfoDfd){
    getWxInfoDfd = new Promise(function(resolve,reject){
      _checkLoginStatus().then(() => {
        navigator.weixin.getUserInfo(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
      }).catch(error => {
        reject(error);
      })
    });
  }
  return getWxInfoDfd;
}


function login(params) {
  if (navigator.weixin) {
    _getWxInfo().then(data => {
      let openid = data.openid;
      let unionid = data.unionid;
      let code = unionid || openid;
      doThen(params, {
        errMsg: 'ok',
        code: code
      })
    }, error => {
      doCatch(params, error)
    })
  } else if (window.isDebug) {
    doThen(params, mockLogin);
  } else {
    doCatch(params, {
      errMsg:"login:notImplemented"
    });
  }
}


// 验证登录状态
function _checkLoginStatus() {
  return new Promise((resolve, reject) => {
    navigator.weixin.ssoLogin((data) => {
      let res = JSON.stringify(data);
      resolve(res.code);
    }, (reason) => {
      reject(reason)
    })
  });
}

/*
 userInfo	OBJECT	用户信息对象，不包含 openid 等敏感信息
 rawData	String	不包括敏感信息的原始数据字符串，用于计算签名。
 signature	String	使用 sha1( rawData + sessionkey ) 得到字符串，用于校验用户信息。
 encryptData String	包括敏感数据在内的完整用户信息的加密数据，详细见加密数据解密算法
 */

function _encryptedData() {
  return '{"nickName":"' + usercode +'","avatarUrl":"/images/anoy_mockuseravatar.png","gender":2,"province":"Beijing","city":"Chaoyang"}';
}

function _signature() {
  return "0493e7b11b755a3d07b748edf23c081f0ee9b96f";
}

function _iv() {
  return "uix-web";
}


const mockUserInfo = {
  userInfo: {
    avatarUrl: "https://minio-console.newdao.net/www/anoy_mockuseravatar.png",
    city: "Chaoyang",
    gender: 2,
    nickName: usercode,
    province: "Beijing",
  },
  encryptedData: _encryptedData(),
  iv: _iv(),
  rawData: '{"nickName":"' + usercode + '","avatarUrl":"https://minio-console.newdao.net/www/anoy_mockuseravatar.png","gender":2,"province":"Beijing","city":"Chaoyang"}',
  signature: _signature(),
  errMsg: 'getUserInfo:ok'
};

function getUserInfo(params) {
  if (navigator.weixin) {
    _getWxInfo().then(data => {
      let {nickName, province, city, country, language} = data;
      let res = {};
      res.userInfo = {
        avatarUrl: data.headimgurl,
        city,
        country,
        gender: data.sex,
        language,
        nickName:data.nickname,
        province,
      };
      res.errMsg = "getUserInfo:ok";
      res.rawData = JSON.stringify(res.userInfo);
      res.signature = _signature();
      res.encryptData = _encryptedData();
      res.iv = _iv();
      doThen(params, res);
    }, error => {
      doCatch(params, error)
    })
  } else if (window.isDebug) {
    doThen(params, mockUserInfo);
  } else {
    doCatch(params, {
      errMsg:"getUserInfo:notImplemented"
    });
  }
}

function requestPayment(params) {
  if (navigator.weixin) {
    navigator.weixin.sendPayReq(params["prepayId"], function (message) {
      let responseCode = parseInt(message);
      if (responseCode === 0) {
        doThen(params, responseCode);
      } else {
        doCatch(params, responseCode);
      }
    }, function (error) {
      doCatch(params, error);
    });
  } else if (window.isDebug) {
    wx.showToast({title: "调用支付(模拟)", icon: "loading"});
    doCatch(params, {
      errMsg:"requestPayment:cancel"
    });
  } else {
    doCatch(params, {
      errMsg:"requestPayment:notImplemented"
    });
  }
}

/*
 * wx.checkSession(OBJECT)
 *
 检查登录态是否过期

 参数名	类型	必填	说明
 success	Function	否	接口调用成功的回调函数，登录态未过期
 fail	Function	否	接口调用失败的回调函数，登录态已过期
 complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
 *
 * */

function checkSession(params) {
  if(window.isDebug){
    doThen(params, {
      errMsg: "checkSession:ok"
    })
  }else{
     doCatch(params,{
       errMsg:"checkSession:notImplemented"
     })
  }
}

function chooseAddress(params) {
  if (window.isDebug) {
    doThen(params, {
      errMsg: "chooseAddress:ok",
      userName: "北京起步科技",
      postalCode: "100122",
      provinceName: "北京市",
      cityName: "北京市",
      countyName: "朝阳区",
      detailInfo: "朝阳区东三环南路98号高和蓝峰大厦18层1802室（十里河地铁站A出口往东100米）",
      nationalCode: "110115",
      telNumber: "010-59798677"
    });
  } else {
    doCatch(params,{
      errMsg:"chooseAddress:notImplemented"
    });
  }
}
function getWeRunData(params) {
  if (window.isDebug) {
    doThen(params, {
      errMsg: "getWeRunData:ok",
      encryptedData: JSON.stringify({
        "stepInfoList": [{
          "timestamp": new Date().getTime() - 24 * 60 * 60 * 1000 * 2,
          "step": 100
        }, {
          "timestamp": new Date().getTime() - 24 * 60 * 60 * 1000,
          "step": 200
        }, {
          "timestamp": new Date().getTime(),
          "step": 300
        }
        ]
      }),
      iv: _iv()
    });
  } else {
    doCatch(params, {
      errMsg:"getWeRunData:notImplemented"
    });
  }
}

function chooseInvoiceTitle(params){
  if(window.isDebug){
      doThen(params,{
        bankAccount:"1209 0928 2210 301",
        bankName:"招商银行股份有限公司广州市体育东路支行",
        companyAddress:"广州市海珠区新港中路397号自编72号(商业街F5-1)",
        errMsg:"chooseInvoiceTitle:ok",
        taxNumber:"91440101327598294H",
        telephone:"020-81167888",
        title:"广州腾讯科技有限公司",
        type:0
      })
  }else{
    doCatch(params,{
      errMsg:"chooseInvoiceTitle:notImplemented"
    })
  }

}

function getSetting(params){
  doCatch(params,{
    errMsg:"getSetting:notImplemented"
  });
}

function authorize(params){
  doCatch(params,{
    errMsg:"authorize:notImplemented"
  });
}



export {login, getUserInfo, requestPayment, checkSession, chooseAddress,getWeRunData,chooseInvoiceTitle,getSetting,authorize};
