import {isX5App,doThen,doCatch} from '../../utils';

/*
 * wx.makePhoneCall(OBJECT)
	OBJECT参数说明：

	参数	类型	必填	说明
	phoneNumber	String	是	需要拨打的电话号码
	success	Function	否	接口调用成功的回调
	fail	Function	否	接口调用失败的回调函数
	complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
 */

function makePhoneCall(params={}){
  const {phoneNumber}=params;

  if (phoneNumber){
    if(window.isDebug){
      wx.showModal({
        title:"拨打 " + phoneNumber + "?[仅为模拟]",
        success:function(res){
            if(res.confirm){
              doThen(params,{
                errMsg:"makePhoneCall:ok"
              });
            }else if(res.cancel){
              //模拟态特殊处理防止红色错误提示。
              doThen(params,{
                errMsg:"makePhoneCall:fail cancel"
              });
            }
        },
      });
    }else{
      const a=document.createElement("a");
      a.href=`tel:${phoneNumber}`;
      a.click();
      doThen(params,{
        errMsg:"makePhoneCall:ok"
      });
    }

  }else {
    doCatch(params,{
      errMsg:"phoneNumber is not exist !"
    })
  }

}

export {makePhoneCall}
