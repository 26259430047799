import URI from 'urijs';
import {omit} from 'lodash';
window.justep = window.justep || {Shell:{
    showPage:function(url){
      //兼容流程中传递json格式的方案
      if(typeof url == "object"){
        let _url =  new URI(url.url);
        _url.setQuery(omit(url, ['url']));
        url = _url.toString();
      }
      let searchInfo = new URI(url).query(true);
      let title = searchInfo.title;

      wx.navigateTo({
        url: url,
        openType:"page",
        modal: {
          width: "100%",
          style: {top: 0,
                  maxWidth: "100%",
                  height: "100%",
                  margin:0
          },
          title:title,
          bodyStyle: {minHeight: "calc(100vh - 56px)"}
        }
      })
    },
    closePage:function(){
      wx.navigateBack();
    }
}};
