import {doThen,doCatch} from '../../utils';
import URI from 'urijs';

/*
 url	String	是	开发者服务器接口地址，必须是 HTTPS 协议，且域名必须是后台配置的合法域名
 data	Object	否	请求的数据
 header	Object	否	HTTP Header , header 中不能设置 Referer
 method	String	否	默认是GET，有效值为： OPTIONS, GET, HEAD, POST, PUT, DELETE, TRACE, CONNECT
 success	Function	否	接口调用成功的回调函数
 fail	Function	否	接口调用失败的回调函数
 complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）*/
function connectSocket(params) {
  let self = this;
  let promise = new Promise(function (resolve) {
    let uri = new URI(params.url);
    if (params.data) {
      uri.search(params.data);
    }
    let currentWebSocket = new WebSocket(uri.toString());
    self.currentWebSocket = currentWebSocket;
    resolve(currentWebSocket);
  });
  promise.then(function (res) {
    doThen(params, res);
  }).catch(function (error) {
    doCatch(params, error);
  });
}


function onSocketOpen(callback) {
  let self = this;
  this.currentWebSocket.onopen = function (res) {
    callback.call(self, res);
  };
}

function onSocketError(callback) {
  let self = this;
  this.currentWebSocket.onerror = function (res) {
    callback.call(self, res);
  };
}


/*data	String	是	需要发送的内容
 success	Function	否	接口调用成功的回调函数
 fail	Function	否	接口调用失败的回调函数
 complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）*/
function sendSocketMessage(params) {
  let self = this;
  let promise = new Promise(function (resolve) {
    self.currentWebSocket.send(params.data);
    resolve();
  });
  promise.then(function (res) {
    doThen(params, res);
  }).catch(function (error) {
    doCatch(params, error);
  });
}

function onSocketMessage(callback) {
  let self = this;
  this.currentWebSocket.onmessage = function (message) {
    callback.call(self, {
      data: message.data
    });
  };
}

function closeSocket() {
  this.currentWebSocket.close();
  this.currentWebSocket = null;
}

function onSocketClose(callback) {
  let self = this;
  this.currentWebSocket.onclose = function (res) {
    callback.call(self, res);
  };
}
export {connectSocket,closeSocket,sendSocketMessage,onSocketClose,onSocketError,onSocketMessage,onSocketOpen};
