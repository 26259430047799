import _ from 'lodash';
import 'whatwg-fetch';
import {doThen,doCatch,checkStatus,resolveLocalFile} from '../../../utils';
import Compressor from 'compressorjs';
/**
 url	String	是	开发者服务器 url
 filePath	String	是	要上传文件资源的路径
 name	String	是	文件对应的 key , 开发者在服务器端通过这个 key 可以获取到文件二进制内容
 header	Object	否	HTTP 请求 Header , header 中不能设置 Referer
 formData	Object	否	HTTP 请求中其他额外的 form data
 success	Function	否	接口调用成功的回调函数
 fail	Function	否	接口调用失败的回调函数
 complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
 */

function _parseParams(params){
  return new Promise(function(resolve,reject){
    resolveLocalFile(params.filePath,params.file).then(function(file){

      new Compressor(file, {
        success(result) {
          let data = new FormData();
          data.append(params.name,result,result.name);
          _.forEach(params.formData, function(value, key) {
            data.append(key,value);
          });
          let _params = {
            method:"POST",
            body:data,
            header:params.header
          };
          resolve(_params);
        },
        error(err) {
          let data = new FormData();
          data.append(params.name,file);
          _.forEach(params.formData, function(value, key) {
            data.append(key,value);
          });
          let _params = {
            method:"POST",
            body:data,
            header:params.header
          };
          resolve(_params);
        }
      });
    }).catch(function(error){
      reject(error);
    });
  });
}

function uploadFile(params){
  _parseParams(params).then(function(_params){
    fetch(params.url,_params)
      .then(checkStatus)
      .then(function(res){
        doThen(params,res);
      }).catch(function(error){
      console.log("uploadfile error:" + error);
      doCatch(params,error);
    });
  }).catch(function(error){
    doCatch(params,error);
  });
}
export {uploadFile};
