import React, {useRef, useEffect, useImperativeHandle, forwardRef} from "react";
import PropTypes from "prop-types";
import { bus, preloadApp, startApp, destroyApp, setupApp } from "wujie";
import { Deferred } from "../../../utils";

const propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  name: PropTypes.string,
  loading: PropTypes.element,
  url: PropTypes.string,
  alive: PropTypes.bool,
  fetch: PropTypes.func,
  props: PropTypes.object,
  attrs: PropTypes.object,
  replace: PropTypes.func,
  sync: PropTypes.bool,
  prefix: PropTypes.object,
  fiber: PropTypes.bool,
  degrade: PropTypes.bool,
  plugins: PropTypes.array,
  beforeLoad: PropTypes.func,
  beforeMount: PropTypes.func,
  afterMount: PropTypes.func,
  beforeUnmount: PropTypes.func,
  afterUnmount: PropTypes.func,
  activated: PropTypes.func,
  deactivated: PropTypes.func,
  loadError: PropTypes.func,
};

let WujieReact = (props,ref) => {
  const rootElementRef = useRef(null);
  let appDeferred = null;
  const compose = async () => {
    try {
      const { current: el } = rootElementRef;
      appDeferred = new Deferred()
      await startApp({
        ...props,
        el,
      });
      appDeferred.resolve();
    } catch (error) {
      appDeferred.reject(error);
      console.log(error);
    }
  };

  useImperativeHandle(ref, () => ({
    name:props.name,
    get appPromise(){
      return appDeferred?.promise
    }
  }),[appDeferred,props.name]);


  useEffect(() => {
    compose();

    return () => {
        destroyApp(props.name);
    };
  }, [props.name, props.url]);

  return <div style={{ width:props.width, height:props.height }} ref={rootElementRef} />;
};

WujieReact = forwardRef(WujieReact);
WujieReact.propTypes = propTypes;
WujieReact.bus = bus;
WujieReact.setupApp = setupApp;
WujieReact.preloadApp = preloadApp;
WujieReact.destroyApp = destroyApp;

export default WujieReact;
