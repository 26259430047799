import {__toUrl} from '../utils'
import _ from "lodash";
import URI from "urijs";
import {parsePath} from "history";
import CryptoRequest from "../api/network/crypto-request";


class AppBase {
	constructor(options){
		if (options){
			for (let i in options){
				this[i] = options[i];
			}
		}

    this.searches = URI.parseQuery(parsePath(CryptoRequest.parse(window.location.href).toString(false)).search);
		this._sys = {};

    document.addEventListener("pause",() => {
      if (this.onHide && (typeof this.onHide == 'function')){
        this.onHide();
      }
    });
    document.addEventListener("resume",() => {
      if (this.onShow && (typeof this.onShow == 'function')){
        this.onShow();
      }
    });
    if (this.onerror && (typeof this.onerror == 'function')){
      let self = this;
      window.onerror = function(){
        self.onerror.apply(self,arguments);
      }
    }
	}

  //@deprecated
	getCurrentPage(){
		return _.last(getCurrentReactPages());
	}

  setAppConfig(config){
    let pages = config.pages;
    config.pages = _.map(pages,function(page){
      return __toUrl("/app.json",page,true);
    });
	config.tabBar = config.tabBar || {};
    let tabBar = config.tabBar;
    let list = tabBar.list || [];
    tabBar.list = _.map(list,function(item){
      _.each(item,function(value,key){
        if(key.indexOf('Path')!=-1){
          item[key] = __toUrl("/app.json",value,key==="pagePath");
        }
      });
      return item;
    });
    this._sys.appConfig = config;
  }

  getAppConfig(){
    return this._sys.appConfig;
  }

  getSearch(name){
	  if(name){
      return this.searches[name];
    }else {
	    return this.searches;
    }
  }

}


global._app = null;
global.getApp = function(){
	return global._app;
};

/**
 * 	options格式:
 * 	{
 * 		onLaunch: function(){},
 * 		onShow: function(){},
 * 		onHide: function(){},
 * 		globalData: 全局数据,
 * 		其它: 开发者可以添加任意函数或数据, 用this可以访问
 * 	}
 */
global.App = function(options){
  global._app = new AppBase(options);
  if(location){
    let user_session = getApp().getSearch("user_session");
    if(user_session){
      document.cookie = "user_session=" + user_session +  ";path=/";
    }
  }
};



