import {get,set} from 'lodash';
export function injectShell(appWindow){
  const Shell = {
    /**
     *  生命周期 接入
     *  onCreate 应该在这里 做react的 mount
     *  onDestroy 应该在这里 做react的 unmount
     *  onShow 门户中页面激活时候
     *  onHide 门户中页面隐藏时候
     */
    onShow: function (callback) {
      this.onShowCallback = callback;
    },
    onHide: function (callback) {
      this.onHideCallback = callback;
    },
    onCreate: function (callback) {
      this.onCreateCallback = callback;
      appWindow.__WUJIE_MOUNT = callback;
    },
    onDestroy: function (callback) {
      this.onDestoryCallback = callback;
      appWindow.__WUJIE_UNMOUNT = callback;
    },

    /**
     * 路由行为 api
     *  一般用来关闭自己
     *  如果需要打开一个其他页面关闭自己
     *
     *  navigateBack, navigateTo, preload, refresh, close, closeOther, closeAll
     *
     * if (window && window.parent && window.parent.postMessage){
     * 		  let msg = {type: "shell", name: "navigateBack", args: {}};
     * 		  if (currentPage){
     * 			  msg.args.pageUrl = currentPage.pageUrl;
     * 		  }
     * 		  window.parent.postMessage(JSON.stringify(msg), '*');
     * 	  }
     *
     */
    navigateTo(params) {
      let msg = {type: "shell", name: "navigateTo", args: params};
      appWindow.parent.postMessage(JSON.stringify(msg), '*');
    },

    navigateBack() {
      let {pageUrl} = appWindow.$wujie?.props;
      let msg = {
        type: "shell", name: "navigateBack", args: {
          pageUrl
        }
      };
      appWindow.parent.postMessage(JSON.stringify(msg), '*');
    },

    refresh() {
      let {pageUrl} = appWindow.$wujie?.props;
      let msg = {
        type: "shell", name: "refresh", args: {
          pageUrl
        }
      };
      appWindow.parent.postMessage(JSON.stringify(msg), '*');
    },

    /**
     *  共享变量  主要用来存储跨页面的共享变量
     *  主要存储 共享的用户信息  共享的基础js库比如 window.lodash 等
     */
    async getShardState(path,loader) {
      let shardObject = appWindow.parent.microService;
      let result = get(shardObject,path);
      if(!!result){
        return result;
      }else {
        result = await loader?.();
        set(shardObject,path,result);
        return result;
      }
    }
  }
  appWindow.Shell = Shell;
}


