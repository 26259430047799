import _ from 'lodash';
import {doThen,doCatch,isX5App,isWeixinBrowser,resolveLocalFile,resolveFileUrl} from '../../utils';
import URI from 'urijs';
import React from 'react';

/*
 sourceType	StringArray	否	album 从相册选视频，camera 使用相机拍摄，默认为：['album', 'camera']
 maxDuration	Number	否	拍摄视频最长拍摄时间，单位秒。最长支持60秒
 camera	StringArray	否	前置或者后置摄像头，默认为前后都有，即：['front', 'back']
 success	Function	否	接口调用成功，返回视频文件的临时文件路径，详见返回参数说明
 fail	Function	否	接口调用失败的回调函数
 complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
 返回参数说明
 tempFilePath	选定视频的临时文件路径
 duration	选定视频的时间长度
 size	选定视频的数据量大小
 height	返回选定视频的长
 width	返回选定视频的宽
 */
function chooseVideo(params) {
  params.maxDuration = params.maxDuration || 60;
/*  if (isX5App) {
    let result = {};
    navigator.device.capture.captureVideo(function (mediaFiles) {
      let mediaFile;
      for (let file of mediaFiles) {
        mediaFile = file;
      }

      if(mediaFile){
    	let getFormatDataPromise = new Promise((resolve,reject) => {
    		mediaFile.getFormatData(function(mediaFileData){
	          result.duration = mediaFileData.duration;
	          result.height = mediaFileData.height;
	          result.width = mediaFileData.width;
	          resolve(result);
	        },function(error){
	          reject(error);
	        });
    	});

    	let resolveFileUrlPromise = new Promise((resolve,reject) => {
    		resolveFileUrl(mediaFile.fullPath).then(internalURL =>{
            	result.tempFilePath = internalURL;
            	resolve(result);
            });
    	});

    	Promise.all([resolveFileUrlPromise,getFormatDataPromise]).then(() =>{
    		doThen(params,result);
    	}).catch(error=>{
    		doCatch(params,error);
    	});

        result.size =  mediaFile.size;
      }else{
        doCatch(params);
      }
    }, function (error) {
      doCatch(params, error);
    }, {
      duration:params.maxDuration
    });
  }else{*/
    let imageChoose = document.createElement('input');
    imageChoose.type = "file";
    if(isX5App || isWeixinBrowser){
    	imageChoose.accept = "video/*";
    }else{
    	imageChoose.accept = "video/mp4,video/x-m4v,video/*";
    }
    imageChoose.style = "position: absolute;left:-8888px;";
    imageChoose.addEventListener('change', function() {
      if(imageChoose.files.length == 1){
        const id = _.uniqueId('inputFile://') + "/" + imageChoose.files[0].name;
        window.inputFiles[id] = imageChoose.files[0];
        doThen(params,{
          tempFilePath:id,
          duration:60,
          height:180,
          width:240
        });
      }else{
        doCatch(params,{
           "errMsg":"invalid file number"
        });
      }
    });
    document.body.appendChild(imageChoose);
    imageChoose.click();
 // }
}
export {chooseVideo};
