'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _unitlessCssProperty = require('loader/unitless-css-property/index');

var _unitlessCssProperty2 = _interopRequireDefault(_unitlessCssProperty);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (property, value) {
  return !(0, _unitlessCssProperty2.default)(property) && !isNaN(parseFloat(value)) && isFinite(value) && value !== 0;
};

module.exports = exports['default'];
