import $ from 'jquery';
import './transition';
import {isX5App} from '../../core/utils';

var iScroll = function (domNode, page,enablePullDownRefresh,onReachBottomDistance) {
  this.page = page;
  this.enablePullDownRefresh = enablePullDownRefresh;
  this.onReachBottomDistance = onReachBottomDistance;
  this.$scrollEle = $(domNode);
  this.$scrollCEle = this.$scrollEle.find('>.x-scroll-content');
  this.$pdEle = this.$scrollEle.find('>.x-pull-down');
  this.$pdiEle = this.$scrollEle.find('.x-pull-down>.x-pull-down-img');

  this.$puEle = this.$scrollEle.find('>.x-pull-up');

  this.eventNamespace = new Date().getTime() + "";
  var self = this;
  this.options = {
    topOffset: 42,
    onRefresh: function(){
      if (self.$pdEle.hasClass('x-loading')) {
        self.$pdEle.removeClass('x-loading');
        self.$pdEle.addClass('x-pull-down');
      }
      self.$pdiEle.css({"transform":"rotate(0deg) translate3d(0,0,0)"});
    },
    onScrollMove: function(){
      if(self.y > -15 && self.y < 5){
        var deg = ((self.y - 5)/20 + 1) * -180;
        self.$pdiEle.css({"transform":"rotate("+ deg + "deg) translate3d(0,0,0)"});
      }else if(self.y > 5 ){
        self.$pdiEle.css({"transform":"rotate(-180deg) translate3d(0,0,0)"});
      }

      if (self.y > 5) {
        //超过阀值
        self.$pdEle.addClass('x-flip');
        self.$pdEle.removeClass('x-restore');
        self.minScrollY = 0;
      }  else if (self.y < 5) {
        //不到阀值
        self.$pdEle.removeClass('x-flip');
        self.$pdEle.addClass('x-restore');
        self.minScrollY = -self.options.topOffset;
      }
    },
    onScrollEnd: function(type){
      if(type == "scroll"){
        if(self.maxScrollY -self.$scrollEle.get(0).scrollTop < 3){
          if (self.options.onScrollToBottom) {
            self.options.onScrollToBottom.call(self);
          }
        }
      }else if(type == "pullDown"){
        if (self.$pdEle.hasClass('x-restore')) {
          self.refresh();
        }else{
          self.$scrollEle.transform('translate3d(0,42px,0)');
          self.$pdEle.removeClass('x-flip');
          self.$pdEle.addClass('x-loading');
          if(page && page.onPullDownRefresh){
            page.onPullDownRefresh.call(page);
          }
          setTimeout(function(){
        	  self.refresh();
          },500)
        }
      }else if(type == "pullUp"){
        if(self.maxScrollY -self.$scrollEle.get(0).scrollTop < self.onReachBottomDistance){
          if (self.options.onScrollToBottom) {
            self.options.onScrollToBottom.call(self);
          }
        }
        self.refresh();
      }
    },
    onScrollToBottom:function(){
      if(page && page.onReachBottom){
        page.onReachBottom.call(page);
      }
    }
  };
  this.init();
  this.optimizePerformanceForKeyboard();

};

iScroll.prototype.init = function () {
  this.pullDown();
  this.refresh();
};

iScroll.prototype.handleScroll = function (e) {
  if(e.target == e.currentTarget){
    if (this.options.onScrollMove) {
      this.options.onScrollMove.call(this, e);
    }
    debounce(this.onScrollEnd, 200, this)("scroll");
  }
};

iScroll.prototype.onScrollEnd = function (type) {
  var self = this;
  if (this.options.recycleSelector !== "") {
    this.calculateUnit();
  }
  if (self.options.onScrollEnd) {
    self.options.onScrollEnd.call(self,type);
  }else{
    this.refresh();
  }
};


iScroll.prototype.optimizePerformanceForKeyboard = function () {
  var self = this;
  $(window).on('keyboardshow.' + this.eventNamespace, function () {
    $(self.unInViewEles).hide();
  }).on('keyboardhide.' + this.eventNamespace, function () {
    $(self.unInViewEles).show();
  });
};
iScroll.prototype.calculateUnit = function () {
  var self = this;
  this.inViewEles = [];
  this.unInViewEles = [];
  debounce(function () {
    self.$scrollCEle.find(self.options.recycleSelector).each(function (index, ele) {
      if (isInViewport(ele)) {
        self.inViewEles.push($(ele).children());
        //$(ele).children().show();
      } else {
        self.unInViewEles.push($(ele).children());
        //$(ele).children().hide();
      }
    });
  }, 1000, self)();
};


function debounce(fn, wait, scope) {
  return function () {
    var args = arguments,
      later = function () {
        fn.__timeout = undefined;
        fn.apply(scope, args);
      };
    if (fn.__timeout) {
      clearTimeout(fn.__timeout);
    }
    fn.__timeout = setTimeout(later, wait);
  };
};

function isInViewport(el) {
  var rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= $(window).height() &&
    rect.right <= $(window).width()
  );
};

iScroll.prototype.refresh = function () {
  return new Promise((resolve,reject) => {
    var self = this;
    if (this.options.onRefresh) {
      this.options.onRefresh.call(self);
    }
    var self = this;
    this.$scrollEle.transition(300);
    this.$scrollEle.transitionEnd(function () {
      self.$scrollEle.transition(0);
    });
    this.$scrollEle.transform('none');
    this.$pdEle.removeClass('x-flip');
    this.$pdEle.removeClass('x-restore');
    this.$pdEle.removeClass('x-loading');
    setTimeout(function () {
      self.maxScrollY = self.$scrollEle.get(0).scrollHeight - self.$scrollEle.height();
      self.y = self.$scrollEle.scrollTop() * -1;
      self.$pdEle.css('opacity','0.99');
      resolve();
    }, 300);
  });
};

iScroll.prototype.startPullDownRefresh = function(){
  return new Promise((resolve,reject) => {
    var self = this;
    if (this.options.onRefresh) {
      this.options.onRefresh.call(self);
    }
    var self = this;
    this.$scrollEle.transition(300);
    this.$scrollEle.transitionEnd(function () {
      self.$scrollEle.transition(0);
      resolve();
    });
    self.$scrollEle.transform('translate3d(0,42px,0)');
    self.$pdEle.removeClass('x-flip');
    self.$pdEle.addClass('x-loading');
    if(this.page && this.page.onPullDownRefresh){
      this.page.onPullDownRefresh.call(this.page);
    }
  });
};

iScroll.prototype.scrollTo = function (y) {
  if (isX5App && detector.os.ios) {
    this._scrollTo(y);
  } else {
    this.$scrollEle.scrollTop(y);
  }
};

/**
 * slm
 * xxx的UIWebView 使用的内核版本过于老旧 造成滚动如果超过一屏 会造成白屏，这个问题只发生在webview中
 * safari中无此问题。 如果以后UIWebview内嵌WkWebView 或版本内核升级可以去掉这个兼容函数
 */
iScroll.prototype._scrollTo = function (y) {
  var scrollEle = this.$scrollEle.get(0);
  animateScroll(y);
  function animateScroll(y) {
    // scroll animation loop w/ easing
    // credit https://gist.github.com/dezinezync/5487119
    var start = Date.now(), duration = 550, fromY = scrollEle.scrollTop;

    if (fromY === y) {
      return;
    }

    // decelerating to zero velocity
    function easeOutCubic(t) {
      return (--t) * t * t + 1;
    }

    // scroll loop
    function animateScrollStep() {
      var currentTime = Date.now(), time = Math.min(1,
          ((currentTime - start) / duration)),
      // where .5 would be 50% of time on a linear scale easedT gives
      // a
      // fraction based on the easing method
        easedT = easeOutCubic(time);

      if (fromY != y) {
        scrollEle.scrollTop = parseInt((easedT * (y - fromY))
          + fromY, 10);
      }

      if (time < 1) {
        window.requestAnimationFrame(animateScrollStep);
      }
    }
    // start scroll loop
    window.requestAnimationFrame(animateScrollStep);
  }
};


iScroll.prototype.scrollToElement = function (el) {
  if (el.scrollIntoView) {
    el.scrollIntoView();
  }
};


iScroll.prototype.supportContentTouchMove = function () {
  let isMoved = false, pullStarted, touchesStart = {}, isScrolling, deltaX, deltaY, touchesDiff, touchStartTime,
    self = this, isFirstMove = true,isPullDown;
  this.handleStart = function (e) {
    if (e instanceof $.Event) {
      e = e.originalEvent;
    }
    isMoved = false;
    isScrolling = true;
    isFirstMove = true;
    touchesDiff = 0;
    touchesStart.x = e.type === 'touchstart' ? e.targetTouches[0].pageX : e.pageX;
    touchesStart.y = e.type === 'touchstart' ? e.targetTouches[0].pageY : e.pageY;
    touchStartTime = (new Date()).getTime();
    self.$scrollEle.transition(0);
    //防止ios橡皮筋抖动
    let startTopScroll = self.$scrollEle.scrollTop();
    let scrollEle = self.$scrollEle.get(0);
    /*if(startTopScroll <= 0)
      scrollEle.scrollTop = 1;*/

    if(startTopScroll + scrollEle.offsetHeight >= scrollEle.scrollHeight)
      scrollEle.scrollTop = scrollEle.scrollHeight - scrollEle.offsetHeight - 1;
  };

  this.handleMove = function (e) {
    if (e instanceof $.Event) {
      e = e.originalEvent;
    }
    if (e.type === 'touchmove' && e.targetTouches.length > 1) {
      return;
    }
    var pageX = e.type === 'touchmove' ? e.targetTouches[0].pageX : e.pageX;
    var pageY = e.type === 'touchmove' ? e.targetTouches[0].pageY : e.pageY;
    deltaX = Math.abs(pageX - touchesStart.x);
    deltaY = Math.abs(pageY - touchesStart.y);
    touchesDiff = pageY - touchesStart.y;
    if(isFirstMove){
      isPullDown = deltaY > deltaX && touchesDiff > 0;
    }
    //下拉情况
    //兼容滚动条处在父级的情况
    if (isFirstMove
      && (deltaY > deltaX)
      && self.$scrollEle.scrollTop() <= 1
      && touchesDiff > 0
      && self.enablePullDownRefresh
      && self.$scrollCEle.scrollTop() === 0
      && self.$scrollEle.parent().scrollTop() === 0
    ) {
      isScrolling = false;
      isMoved = true;
    }

    if (isScrolling === false
      && isMoved === true
      && touchesDiff > 0
      && self.enablePullDownRefresh
      && isPullDown) {
      e.preventDefault();
      //摩擦系数为0.85感觉手感不错
      self.$scrollEle.transform('translate3d(0,' + Math.pow(touchesDiff, 0.85) + 'px,0)');
      self.y = Math.pow(touchesDiff, 0.85) - self.options.topOffset;
      if (self.options.onScrollMove) {
        self.options.onScrollMove.call(self, e);
      }
    }
    //上滑情况
    if (isFirstMove && (deltaY > deltaX) &&
      //不满或者刚刚满一屏
      (self.$scrollEle.get(0).scrollHeight - self.$scrollEle.height() - self.$scrollEle.scrollTop()) === 0
      && touchesDiff < 0) {
      //符合上滑环境
      isFirstMove = false;
      isScrolling = false;
      isMoved = true;
    }
    if (isScrolling === false && isMoved === true && touchesDiff < 0) {
        //e.preventDefault();
      //上滑无效果
      //self.$scrollEle.transform('translate3d(0,-' + Math.pow(touchesDiff * -1, 0.85) + 'px,0)');
      //self.y = -Math.pow(touchesDiff * -1, 0.85) - (self.$scrollEle.get(0).scrollHeight - self.$scrollEle.height());
      if (self.options.onScrollMove) {
        self.options.onScrollMove.call(self, e);
      }

    }
    isFirstMove = false;
  };
  this.handleCancel = this.handleEnd = function (e) {
    if (e instanceof $.Event) {
      e = e.originalEvent;
    }

    if (isScrolling === false && isMoved === true) {
      let direction = "pullDown";
      if (touchesDiff < 0) {
        direction = "pullUp";
      }
      self.onScrollEnd(direction);
    }else if(isScrolling === true && touchesDiff < 0){
      self.onScrollEnd("scroll");
    }
    isScrolling = true;
    isMoved = false;
    isFirstMove = false;
  };
};

iScroll.prototype.pullDown = function () {
  if (this.$pdEle.length === 0 && this.$puEle.length === 0) {
    return;
  }
  this.supportContentTouchMove();
};


iScroll.prototype.destroy = function () {
  $(window).off("." + this.eventNamespace);
};
export default iScroll;
