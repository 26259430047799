
import cx from 'classnames'

//实现组件的hover状态
/*
	属性名	类型	默认值	说明
	hover	Boolean	false	是否启用点击态
	hover-class	String	none	指定按下去的样式类。当 hover-class="none" 时，没有点击态效果
	hover-start-time	Number	50	按住后多久出现点击态，单位毫秒
	hover-stay-time	Number	400	手指松开后点击态保留时间，单位毫秒
 * */

let hoverClassTimer=null;

function hoverTouchStart(hoverClz,defaultClz,hoverStartTime=0){
	return new Promise(resolve=>{
		if(hoverClz == "none" || hoverClassTimer){
			return resolve (null);
		}
		let newClz;
		hoverClassTimer = setTimeout(()=>{
			newClz = cx({[hoverClz]:hoverClz},{[defaultClz]:!hoverClz});
			return resolve (newClz);
		},hoverStartTime)
	})
}


function _clearTimer(){
	return new Promise (resolve=>{
		if(hoverClassTimer){
			clearTimeout(hoverClassTimer);
			hoverClassTimer = null;
			resolve (true)
		}else {
			resolve (false)
		}
	})
}

function hoverTouchEnd (hoverStayTime,fn){
  setTimeout(function(){
    _clearTimer().then(status=>{
      if(status){
        setTimeout(()=>{
          fn();
        },hoverStayTime)
      }
    })
  });

}
function hoverTouchCancel (fn){
  setTimeout(function(){
    _clearTimer().then(status=>{
      status && fn();
    })
  });
}

export {hoverTouchStart,hoverTouchEnd,hoverTouchCancel}
