'use strict';

var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toCSS = toCSS;
exports.toObject = toObject;
exports.nestPseudoClasses = nestPseudoClasses;
exports.importantify = importantify;

var _camelToDashCase = require('./utils/camelToDashCase');

var _camelToDashCase2 = _interopRequireDefault(_camelToDashCase);

var _dashToCamelCase = require('./utils/dashToCamelCase');

var _dashToCamelCase2 = _interopRequireDefault(_dashToCamelCase);

var _isNumber = require('./utils/isNumber');

var _isNumber2 = _interopRequireDefault(_isNumber);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

/**
* Creates a valid CSS string out of an object of styles
* @param {Object} styles - an object with CSS styles
* @param {string} unit - unit that gets applied to number values
*/
function toCSS(styles) {
  var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

  var unit = options.unit || 'px';
  var ruleSeparator = options.ruleSeparator || '';
  var selectorSeparator = options.selectorSeparator || '';
  var indent = options.indent || '';

  return Object.keys(styles).reduce(function (rules, property) {
    var value = styles[property];
    // resolve multi values passed as an array
    if (value instanceof Array) {
      value = value.join(';' + property + ':' + ruleSeparator + indent);
    }
    if (value instanceof Object) {
      // prerender nested style objects
      rules += (0, _camelToDashCase2.default)(property) + '{' + selectorSeparator + toCSS(value, options) + selectorSeparator + '}' + selectorSeparator; // eslint-disable-line
    } else {
        // add an semicolon at the end of each rule (if not the last one)
        if (rules !== '') {
          rules += ';' + ruleSeparator;
        }
        // automatically adds units to CSS properties that are not unitless
        // but are provided as a plain number
        if ((0, _isNumber2.default)(property, value)) {
          value = value + unit;
        }

        rules += indent + (0, _camelToDashCase2.default)(property) + ':' + value;
      }
    return rules;
  }, '');
}

/**
 * Generates a object with CSS key-value pairs out of a CSS string
 * @param {string} CSS - CSS string that gets objectified
 */
function toObject(CSS) {
  var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

  var replacer = options.replacer || { '.': '' };

  // this checks if the string is made of selectors
  var replacePrefixes = Object.keys(replacer);
  var replacerRegExp = replacePrefixes.map(function (prefix) {
    return '[' + prefix + ']';
  }).join('|');
  var selectors = CSS.match(new RegExp('(' + replacerRegExp + ')?[a-z0-9-_:\(\) ]*{[^}]*}', 'g'));

  // Resolve nested CSS selector strings
  if (selectors && selectors.length > 0) {
    return selectors.reduce(function (rules, rule) {
      // seperate selector (className) and its styles
      // replace selector prefixes according the replacer settings
      var selector = rule.match(/[^{]*/)[0];
      var styles = rule.replace(selector, '');

      var className = replacePrefixes.reduce(function (transformedClassName, prefix) {
        if (transformedClassName.indexOf(prefix) === 0) {
          transformedClassName = transformedClassName.replace(prefix, replacer[prefix]);
        }
        return transformedClassName;
      }, selector.trim());

      // recursive objectify on pure styles string (without wrapping brackets)
      rules[className] = toObject(styles.replace(new RegExp('{|}', 'g'), ''));
      return rules;
    }, {});
  }

  // splitting the rules to single statements
  return CSS.split(';').reduce(function (rules, rule) {
    var _rule$split = rule.split(':');

    var _rule$split2 = _slicedToArray(_rule$split, 2);

    var property = _rule$split2[0];
    var value = _rule$split2[1];

    // trimming both to remove padding whitespace

    value = value.trim();

    if (value) {
      // convert number strings to real numbers if possible
      // Improves usability and developer experience
      // line-height can not convert to real number
      /*var numberValue = parseFloat(value);
      if (numberValue == value || numberValue == value.replace('px', '')) {
        // eslint-disable-line
        value = numberValue;
      }*/

      // dash-casing the property
      property = (0, _dashToCamelCase2.default)(property.trim());

      // mutiple values / fallback values get added to an array
      // while the order stays the exact same order
      if (rules.hasOwnProperty(property)) {
        value = [rules[property]].concat(value);
      }
      rules[property] = value;
    }
    return rules;
  }, {});
}

var setDotProp = function setDotProp(obj, path, value) {
  var props = path.split('.');
  var majorPseudo = props.pop();

  var newObj = props.reduce(function (output, property) {
    // add selector if not already existing
    if (!output[property]) {
      output[property] = {};
    }

    return output[property];
  }, obj);

  newObj[majorPseudo] = value;
};

/**
 * Nests pseudo selectors into their reference selector
 * @param {Object} styles - an object with styles
 */
function nestPseudoClasses(styles) {
  Object.keys(styles).forEach(function (selector) {
    if (selector.indexOf(':') > -1) {
      var _selector$split = selector.split(':');

      var _selector$split2 = _toArray(_selector$split);

      var sel = _selector$split2[0];

      var pseudo = _selector$split2.slice(1);
      // add selector if not already existing

      if (!styles[sel]) {
        styles[sel] = {};
      }

      setDotProp(styles[sel], ':' + pseudo.join('.:'), styles[selector]);

      // add pseudo to selector object
      delete styles[selector];
    }
  });

  return styles;
}

/**
 * Adds an !important flag to every value
 * @param {Object} styles - an object with styles
 */
function importantify(styles) {
  Object.keys(styles).forEach(function (property) {
    var value = styles[property];
    // add !important flag to achieve higher priority than inline styles
    if (value.toString().indexOf('!important') === -1) {
      styles[property] = value + '!important';
    }
  });

  return styles;
}
