import moment from "moment";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat)
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

dayjs.extend(quarterOfYear)
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.extend(weekOfYear)

const types = ["year", "month", "day", "hour", "minute", "second"];
const formatters = ["YYYY", "MM", "DD", "HH", "mm", "ss"];
/**
 * moment
 * 1. 容错 2020- -08 00:00:00
 */

export default class DateUtils {
	static getEnums() {
		let enumMap = new Map();
		for (let i = 63; i > 0; i--) {
			let binaryString = i.toString(2);
			if (binaryString.length < 2) {
				binaryString = "00000" + binaryString;
			} else if (binaryString.length < 3) {
				binaryString = "0000" + binaryString;
			} else if (binaryString.length < 4) {
				binaryString = "000" + binaryString;
			} else if (binaryString.length < 5) {
				binaryString = "00" + binaryString;
			} else if (binaryString.length < 6) {
				binaryString = "0" + binaryString;
			}
			let binaryStringArr = binaryString.split("");
			let typeStr = "", formatStr = "";
			let count = 0;
			for (let j = 0; j < binaryStringArr.length; j++) {
				if (!(binaryStringArr[j] + "" == "0")) {
					count++;
					typeStr += "," + types[j];
					let sperator = "-";
					if (j == 3) {
						sperator = " "
					} else if (j == 4 || j == 5) {
						if (binaryStringArr[3] == '0' && j == 4) sperator = ' '
						else if (binaryStringArr[3] == '0' && binaryStringArr[4] == '0' && j == 5) sperator = ' '
						else
							sperator = ":"
					}
					formatStr += sperator + formatters[j];
				} else {
					typeStr += "";
					formatStr += "";
				}
			}
			let key = typeStr.substring(1);
			let format = formatStr.substring(1);
			if (key.indexOf("hour") <= -1 && key.indexOf("minute") <= -1 && key.indexOf("second") <= -1) {
				// "00:00:00"
			} else if (key.indexOf("hour") <= -1 && key.indexOf("minute") > -1 && key.indexOf("second") > -1) {
				// 00:mm:SS
				format = format.replace("mm:SS", "00:mm:SS");
			} else if (key.indexOf("hour") <= -1 && key.indexOf("minute") <= -1 && key.indexOf("second") > -1) {
				// 00:00:SS
				format = format.replace("SS", "00:00:SS");
			} else if (key.indexOf("hour") > -1 && key.indexOf("minute") > -1 && key.indexOf("second") <= -1) {
				// HH:mm:00
			} else if (key.indexOf("hour") > -1 && key.indexOf("minute") > -1 && key.indexOf("second") > -1) {
				// HH:mm:SS
			} else if (key.indexOf("hour") > -1 && key.indexOf("minute") <= -1 && key.indexOf("second") <= -1) {
				// HH:00:00
			} else if (key.indexOf("hour") > -1 && key.indexOf("minute") <= -1 && key.indexOf("second") > -1) {
				// HH:00:SS
				format = format.replace("HH:SS", "HH:00:SS");
			} else if (key.indexOf("hour") <= -1 && key.indexOf("minute") >= -1 && key.indexOf("second") <= -1) {
				// 00:mm:00
				format = format.replace("mm", "00:mm");
			}
			if (key.indexOf("month") > -1 && key.indexOf("year") <= -1 && key.indexOf("day") <= -1) {
				// day补0
				format = format.replace("MM", "MM-00");
			}
			if (key.indexOf("year") > -1 && key.indexOf("day") > -1 && key.indexOf("month") <= -1) {
				// month补0
				format = format.replace("YYYY", "YYYY-00");
			}
			let type = "";
			if (i >= 62) {
				type = "datetime";
			} else if (i == 56) {
				type = "date";
			} else {
				type = "string";
			}
			enumMap.set(key,
				{
					dataType: type,
					format
				}
			);

		}
		return enumMap;
	}

	static getAntdEnums() {
		let enumArr = [];
		enumArr.push({key: {picker: "week"}, value: {dataType: "string", format: "YYYY-ww周"}});
		enumArr.push({key: {picker: "quarter"}, value: {dataType: "string", format: "YYYY-Q季度"}});
		enumArr.push({key: {picker: "date"}, value: {dataType: "date", format: "yyyy-MM-DD"}});
		enumArr.push({key: {picker: "time"}, value: {dataType: "string", format: "HH:mm:ss"}});
		enumArr.push({key: {picker: "month"}, value: {dataType: "string", format: "YYYY-MM"}});
		enumArr.push({key: {picker: "year"}, value: {dataType: "string", format: "YYYY"}});
		enumArr.push({
			key: {picker: "date", "showTime": true},
			value: {dataType: "datetime", format: "YYYY-MM-DD HH:mm:ss"}
		});
		// enumArr.push({ key: { picker: "date", "showTime": true, format: "yyyy-mm-dd HH:mm" }, value: { dataType: "datetime", format: "yyyy-mm-dd HH:mm" } });
		// enumArr.push({ key: { picker: "date", "showTime": true, format: "yyyy-mm-dd HH" }, value: { dataType: "string", format: "yyyy-mm-dd HH" } });
		// enumArr.push({ key: { picker: "date", "showTime": true, format: "yyyy-mm-dd mm" }, value: { dataType: "string", format: "yyyy-mm-dd mm" } });
		// enumArr.push({ key: { picker: "time", format: "HH:mm" }, value: { dataType: "string", format: "HH:mm" } });
		// enumArr.push({ key: { picker: "time", format: "HH" }, value: { dataType: "string", format: "HH" } });
		// enumArr.push({ key: { picker: "time", format: "mm" }, value: { dataType: "string", format: "mm" } });
		return enumArr;
	}

	static getEnum(type, isPcx = false) {
		if (isPcx) {
			let enumArr = DateUtils.getAntdEnums();
			for (let i = 0; i < enumArr.length; i++) {
				if (DateUtils.isObjectValueEqual(type, enumArr[i].key)) {
					return enumArr[i];
				}
			}
		} else {
			let enumMap = DateUtils.getEnums();
			return enumMap.get(type);
		}
	}

	/**
	 * 特殊需求
	 *   ref 类型支持date datatime string
	 *   date new Date()
	 *   datetime new Date()
	 *   string ISO8601  "2019-01-01T00:00:00.000Z"
	 *   localestring "2019-01-01 00:00:00"    //默认东八区
	 *
	 *   moment(time).formate()  //moment 格式化
	 *
	 *    要支持选择通配时间能力
	 *    0000 年
	 *    00 月
	 *    00  日
	 *    00 时
	 *    00 分
	 *    00 秒
	 *
	 *    0000-00-00 00:00:00
	 *
	 *    2100-00-00 00:00:00
	 *
	 * { picker: "week" },  { dataType: "string", format: "YYYY-ww周" } });
	 *          { picker: "quarter" }  { dataType: "string", format: "YYYY-Q季度" } });
	 *         { picker: "date" }, value: { dataType: "date", format: "yyyy-MM-DD" } });
	 *          { picker: "time" }, value: { dataType: "string", format: "HH:mm:ss" } });
	 *          { picker: "month" }, value: { dataType: "string", format: "YYYY-MM" } });
	 *          { picker: "year" }, value: { dataType: "string", format: "YYYY" } });
	 *          { picker: "date", "showTime": true }, { dataType: "datetime", format: "YYYY-MM-DD HH:mm:ss" }
	 *
	 *
	 *    pc端  底层采用moment format format 格式枚举  如上
	 * type = { picker: "date", "showTime": true }
	 *    return "datetime","date","string"
	 *
	 *  移动端 底层采用moment format format 格式格局type(columns) 拼接出
	 * type: year,month, day, hour, minute, second
	 *
	 * return "datetime","date","string"
	 *
	 *
	 */
	static getDataType(type, isPcx = false) {
		let enumObj = DateUtils.getEnum(type, isPcx);
		if (isPcx) {
			return enumObj?.value?.dataType;
		}
		return enumObj.dataType;
	}

	static getFormat(type, isPcx = false) {
		let enumObj = DateUtils.getEnum(type, isPcx);
		if (isPcx) {
			return enumObj?.value?.format;
		}
		return enumObj.format;
	}

	static removeNullUndefined = (obj) =>
		Object.entries(obj).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {});

	static isObjectValueEqual(a, b) {
		// 判断两个对象是否指向同一内存，指向同一内存返回 true
		if (a === b) return true // 获取两个对象键值数组
		a = DateUtils.removeNullUndefined(a);
		b = DateUtils.removeNullUndefined(b);
		let aProps = Object.getOwnPropertyNames(a);
		let bProps = Object.getOwnPropertyNames(b);
		// 判断两个对象键值数组长度是否一致，不一致返回 false
		if (aProps.length !== bProps.length) return false // 遍历对象的键值
		for (let prop in a) {
			// 判断 a 的键值，在 b 中是否存在，不存在，返回 false
			if (b.hasOwnProperty(prop)) {
				// 判断 a 的键值是否为对象，是则递归，不是对象直接判断键值是否相等，不相等返回 false
				if (typeof a[prop] === 'object') {
					if (!DateUtils.isObjectValueEqual(a[prop], b[prop])) return false
				} else if (a[prop] !== b[prop]) {
					return false
				}
			} else {
				return false
			}
		}
		return true
	}

	static isValid(value, format, tools = "moment") {
		if (tools == "moment")
			return moment(value, format, true).isValid();
		if (format == "YYYY-ww周") {
			let valArr = value && value.split("-");
			if (valArr.length > 1) {
				return dayjs(valArr[0]).week(valArr[1].substring(0, valArr[1].length - 1)).isValid();
			}
		} else if (format == 'YYYY-Q季度') {
			let valArr = value && value.split("-");
			if (valArr.length > 1) {
				return dayjs(valArr[0]).quarter(valArr[1].substring(0, valArr[1].length - 2)).isValid();
			}
		}
		return dayjs(value, format, true).isValid();
	}

	static parse(value, type, dataType, isPcx = false, tools = "moment") {
		let formatter = DateUtils.getFormat(type, isPcx);
		if (dataType == "string") {
			if (tools == "moment")
				value = moment(value, formatter, true).valueOf();
			else {
				if (formatter == "YYYY-ww周") {
					let valArr = value && value.split("-");
					if (valArr.length > 1) {
						value = dayjs(valArr[0]).week(valArr[1].substring(0, valArr[1].length - 1)).valueOf();
					}
				} else if (formatter == 'YYYY-Q季度') {
					let valArr = value && value.split("-");
					if (valArr.length > 1) {
						value = dayjs(valArr[0]).quarter(valArr[1].substring(0, valArr[1].length - 2)).valueOf();
					}
				} else {
					value = dayjs(value, formatter, "zh-cn", true).valueOf();
				}
			}
		}
		return value;
	}

	static format(date, type, dataType, isPcx = false, tools = "moment") {
		let formatter = DateUtils.getFormat(type, isPcx);
		let value;
		if (dataType == "string") {
			if (tools == "moment") {
				value = moment(date).format(formatter);
			} else {
				if (formatter == "YYYY-ww周") {
					let week = dayjs(date).week();
					value = dayjs(date).format("YYYY") + "-" + week + "周";
				} else if (formatter == 'YYYY-Q季度') {
					let quarter = dayjs(date).quarter();
					value = dayjs(date).format("YYYY") + "-" + quarter + "季度";
				} else {
					value = dayjs(date).format(formatter);
				}
			}
		}
		return value;
	}
}
