import {isX5App,doThen,doCatch} from '../../utils';

/*
	 *	wx.scanCode(OBJECT)
		调起客户端扫码界面，扫码成功后返回对应的结果

		Object 参数说明：

		参数	类型	必填	说明
		success	Function	否	接口调用成功的回调函数，返回内容详见返回参数说明。
		fail	Function	否	接口调用失败的回调函数
		complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
		success返回参数说明：

		参数	说明
		result	所扫码的内容
		scanType	所扫码的类型
		charSet	所扫码的字符集
		path	当所扫的码为当前小程序的合法二维码时，会返回此字段，内容为二维码携带的 path
 *
 */
const mockReslut = {
	errMsg:"mock Msg is OK !",
	charSet : "UTF-8",
	result : "扫描结果[模拟]"
}
const justep = window.justep;
function scanCode ( params ) {
	let res={};
	if (isX5App) {
	window.justep && window.justep.barcodeScanner.scan(
			function (result) {
				res.errMsg='scanCode:ok';
				res.scanType=_.uniqueId('CODE_');
				res.result=result.text;
				res.charSet='UTF-8';
				doThen(params,res)
			},
			function (error) {
				doCatch(params,(`Scanning failed:  ${error}`));
			}
		);
	}else if (window.isDebug) {
		console.warn('当前环境 启用模拟数据..');
		setTimeout(function(){
      doThen(params,mockReslut);
    },1000);
	} else {
		console.warn('当前环境不支持调用 wx.scanCode ');
	}
}

export {scanCode}
