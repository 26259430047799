
import PageComponent from "core/framework/PageComponent";
import React,{Fragment} from 'react';
import {createPath} from "history";
import URI from "urijs";
import Md5 from "crypto-js/md5";
import WujieReact from 'wujie-react';
/**
 *
 * 扩展对iframe页面或者微前端的支持
 * 微前端 $openType=app
 * iframe http开头
 *
 */
function resolveIframe(location){

  let pageUrl =createPath(location);
  if(pageUrl.indexOf("http") == 0){
    return <iframe style={{height:"100%",width:"100%",border:0}} key={pageUrl} className={'page-container'} src={pageUrl}></iframe>
  }
  return false;
}

function resolveAppPageFrame(location){

  let pageUrl = createPath(location);
  let uri = new URI(pageUrl);
  if(uri.search(true)?.["$openType"] == 'app'){
    let pageId = Md5(uri.toString()).toString();
    return <WujieReact
      width="100%"
      height="100%"
      name={pageId}
      url={uri.toString()}
      alive={false}
      sync={false}
      props={{
        pageUrl,
        pageId
      }}
    ></WujieReact>
  }
}
export default class PageContainer extends PageComponent {

  constructor(props,context){
    let wxPageStyle = {
    };
    let wxPageConfig ={};
    let wxPagePathResolver = function (path){
      return path;
    }
    super(props,context);
    this.wxPagePathResolve = wxPagePathResolver;
    this.wxPageStyle = wxPageStyle;
    this.wxPageConfig = wxPageConfig;
    this.wxPageDeclaration = {
      onShow:()=>{},
      onHide:()=>{},
      onReady:()=>{},
      onPullDownRefresh: ()=>{},
      onReachBottom:()=>{},
      onUnload: ()=>{},
      onShareAppMessageHandle: ()=>{},
      onPageScroll: ()=>{},
      $page : {}
    };
    this.constructed();
  }

  get title(){
    let pageUrl = createPath(this.props.location);
    let uri = new URI(pageUrl);
    return uri.search(true)?.["title"];
  }

  render(){
    return  resolveAppPageFrame(this.props.location) || resolveIframe(this.props.location) || <Fragment></Fragment>
  }
}
