'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Converts a dash-case string to a camel-case string
 * @param {string} str - str that gets converted to camel-case
 */

exports.default = function (str) {
  return str.replace(/-([a-z])/g, function (match) {
    return match[1].toUpperCase();
  }).replace(/^Ms/g, 'ms');
};

module.exports = exports['default'];