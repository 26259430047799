let hasOwnProperty = Object.prototype.hasOwnProperty;

function is(x, y) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    return x !== x && y !== y;
  }
}

//只能走浅比较,因为我们的js对象是有循环引用的.
//同时因为没有走props严格传递,同时也没有走state全局挂载的方案.只有叶子节点可以做浅比较
export function leafShallowEqual(objA,objB){
  if((objA && typeof objA.children == "object") || (objB && typeof objB.children == "object")){
    return false;
  }
  return shallowEqual(objA,objB);
}


export function shallowEqual(objA, objB,ignoreChildren) {


  //对象相等
  if (is(objA, objB)) {
    return true;
  }
  //类型不等
  if (typeof objA !== 'object' || objA === null ||
      typeof objB !== 'object' || objB === null) {
    return false;
  }

  let keysA = Object.keys(objA);
  let keysB = Object.keys(objB);
  //对象属性个数不等
  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  for (let i = 0; i < keysA.length; i++) {
    //对象属性不存在
    if (!hasOwnProperty.call(objB, keysA[i])) {
      return false;
    }
    if(keysA[i]== "children" && ignoreChildren){
      //忽略子 个数必须相同
      if(!objA['children'].length == objB['children'].length){
        return false;
      }
      continue;
    }
    //style可以值相等
    if(keysA[i] === "style"){
      if(JSON.stringify(objA['style']) != JSON.stringify(objB['style'])){
        return false;
      }

    //其他属性必须对象相等
    }else if(!is(objA[keysA[i]], objB[keysA[i]])){
      return false;
    }
  }
  return true;
}
