import React, {Fragment} from "react";
import {getRefRow,getRefData, getRefUserData} from 'core/utils';
import {forEach, get, isArray, isObject, isString} from "lodash";

function isEmpty(value) {
	return value===undefined || value===null || value==="";
}

function processCellStateI18n(page,cellState ){
	if(cellState?.constraint?.msg){
		cellState.constraint.msg = page.i18n(cellState.constraint.msg);
	}
	if(cellState?.required?.msg){
		cellState.required.msg = page.i18n(cellState.required.msg);
	}
}

export function processBindProps(props, page) {
	let refData = getRefData(props, page);
	let refRow = getRefRow(props, page);
	let cellState = refRow?.getCellState?.(props.refColumnName);
	processCellStateI18n(page,cellState);
	props = processUse(props);
	if(page.loadDeferred.state == "pending" && page.state.loading){
		return props;
	}
	if(refData?.loading.get() && !page.state.loading){
		props = {...props};
		props.loading = refData?.loading.get() || props.loading;
	}
	return processCommonProps(props, cellState);
}


function processCommonProps(props, cellState) {
	if (!cellState && props['refColumnName'] && props["refDataId"]) {
		//有绑定列 但是无记录
		props = {...props};
		props.disabled = true;
		props.className = props.className + " x-disabled";
	}
	return processHidden(props, cellState);
}

function processHidden(props, cellState ) {
	cellState = cellState || {};
	let {hidden} = props;

	if (hidden || cellState.hidden) {
		return <Fragment></Fragment>;
	}
	props = processDisabled(props, cellState);
	props = processReadOnly(props, cellState);
	props = processRequired(props, cellState);
	props = processConstraint(props, cellState);


	return props;
}

/**
 * column 上userdata
 * constraint:
 *    val: true/false false:不满足约束
 *    msg: 不满足约束的提示消息
 *
 * required:
 *    val: true/false false:不必填  true:必填
 *    msg: 的提示消息
 *
 */

function processConstraint(props, cellState) {

	let { constraint } = props;

	if (constraint || cellState?.constraint?.val == true) {
		props = {...props};
		props.constraint = true;
		props.errorMessage = props.constraintMessage || cellState?.constraint?.msg;
		props.className = props.className + " x-constraint";
	}
	return props;
}

function processDisabled(props, cellState = {}) {
	let { disabled } = props;

	if (disabled || cellState?.disabled?.val == true) {
		props = { ...props };
		props.disabled = true;
		props.className = props.className + " x-disabled";
	}
	return props;

}


function processReadOnly(props, cellState = {}) {

	//只有readonly格式是个数的  {readonly:true} 没有val 因为没有msg


	let {readonly} = props;

	if (readonly || cellState.readonly) {
		props = { ...props };
		props.readonly = true;
		//data只有只读 没有禁用 所以 对接 组件添加了disabled属性
		props.disabled = true;
		props.className = props.className + " x-readonly";
	}
	return props;
}

function processRequired(props, cellState = {}) {
	let { required } = props;

	if (cellState?.required?.defVal == true) {
		props = { ...props };
		props.required = true;
	}

	if (cellState?.required?.val == true) {
		props = { ...props };
		props.requiredMessage = props.requiredMessage || cellState?.required?.msg;
		props.className = props.className + " x-required";
	}
	return props;
}

/**
 *

 ext:use 处理逻辑

 use:{
    Button:{
      "default":{
          name:"aaa",
          text:"bbb"
      },
      "key1":{

      }
    }
 }
 */

function processUse(props, useInfo = {}) {
	let {use} = props;
	if (use && React.isValidElement(use)) {
		React.Children.forEach(use.props.children, (child) => {
			let childProps = child.props;
			childProps = processUse(childProps, useInfo);

			let {use_name, id,use_key = "default", ...other} = childProps;
			let childInfo = useInfo[use_name];
			useInfo[use_name] = useInfo[use_name] || {};
			useInfo[use_name][use_key] = other;
		});
		let {...result} = props;
		result.use = useInfo;
		return result;
	}
	return props;
}

/*
单前process和其他process处理逻辑 不同 其他process在render中处理
当前为  挂到data上通过监听data上的loading状态 来处理自身的setState
主要应用于
  bind:ref   -> input类的
  bind:items -> 循环对象
  bind:options -> 备选对象 无循环概念
  bind:data   -> search 之类组件

  同时考虑到 button之类的逻辑  操作编译时候 也会生成props  refDataId
  page上提供2个属性
  1. 禁用 页面加载动画  自定义走common.js
  2. 禁用 局部加载动画  自动走标准 组件上的 loading属性 如果可以自定义的话

  mobx 是wxsys包的东西
  core是 common包的东西 理论上 common不应该依赖wxsys内的东西
 */
export function processLoadingProps(props,page,comp){
	if(page.props.disableCompLoading){
		return;
	}
	let data = getRefData(props, page);
	if(data && !props.hasOwnProperty("refRow") && !props.hasOwnProperty("refColumnName")){
		let disposer = data.reaction(
			()=>{return data.loading.get()},
			loading => {

				if(page.state.loading == true){
					//页面加载中  组件不感知loading效果
				}else{
					//页面正在等待 重绘中 组件的loading效果应该走页面渲染逻辑
					if (loading) {
						comp.setState({
							loading: true
						})
					} else {
						comp.setState({
							loading: props.loading
						})
					}
				}
			}
		);
		return disposer;
	}

}



