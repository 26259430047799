import _ from 'lodash';
import {wx,doThen,doCatch,isX5App,resolveLocalFile} from '../../utils';
import Velocity from 'velocity-animate';
/**
demo
Velocity(document.body,{width:400},{
	duration:4000,
	begin:function(){
		Velocity.CSS.setPropertyValue(document.body,'transformOrigin',"50% 40% 0");
	}
});

 Velocity(document.body,{width:200},{
	duration:4000,
	begin:function(){
		Velocity.CSS.setPropertyValue(document.body,'transformOrigin',"40% 40% 0");
	}
})
*/



function stopAnimation(target,actions){
  Velocity(target, 'stop', true);
}

function applyAnimation(target,actions){
  for(let action of actions){
    action.options.transition.begin = function(){
      Velocity.CSS.setPropertyValue(target,'transformOrigin',action.options.transformOrigin);
    };
    Velocity(target, action.animations, action.options.transition);
  }
}

window.applyAnimation = applyAnimation;
/*
 duration	Integer	否	动画持续时间，单位ms，默认值 400
 timingFunction	String	否	定义动画的效果，默认值"linear"，有效值："linear","ease","ease-in","ease-in-out","ease-out","step-start","step-end"
 delay	Integer	否	动画持续时间，单位 ms，默认值 0
 transformOrigin	String	否	设置transform-origin，默认为"50% 50% 0"

 actions 对step
 animations 对要做动画的属性
 option 对动画属性
 {actions:[{animations:[],option:{}}]}
 */
function createAnimation(params) {
   return new Animation(params);
}

/*有效值："linear","ease","ease-in","ease-in-out","ease-out","step-start","step-end"*/
//todo 暂时没有实现 step-start 和 step-end
let timingFunctionMapping = {};
timingFunctionMapping['linear'] = "linear";
timingFunctionMapping['ease'] = "linear";
timingFunctionMapping['ease-in'] = "easeInSine";
timingFunctionMapping['ease-in-out'] = "easeInOutSine";
timingFunctionMapping['ease-out'] = "easeOutSine";
timingFunctionMapping['step-start'] = "linear";
timingFunctionMapping['step-end'] = "linear";

class Animation {
  constructor(duration = 400, timingFunction = "linear", delay = 0, transformOrigin = "50% 50% 0") {
    this.options = {
      transformOrigin: transformOrigin,
      transition: {
        duration: duration,
        easing: timingFunctionMapping[timingFunction],
        delay: delay
      }
    };
    this.actions = [];
    this.step();
  }

  step(options) {
    this.currentAction = {animations: {}};
    this.currentAction.options = options || this.options;
    this.actions.push(this.currentAction);
    return this;
  }

  opacity(value) {
    this.currentAction.animations.opacity = value;
    return this;
  }

  backgroundColor(color){
    this.currentAction.animations.backgroundColor = color;
    return this;
  }
  width(lenght){
    this.currentAction.animations.width = length;
    return this;
  }

  height(lenght){
    this.currentAction.animations.height = length;
    return this;
  }
  top(length){
    this.currentAction.animations.top = length;
    return this;
  }
  left(length){
    this.currentAction.animations.left = length;
    return this;
  }
  bottom(length){
    this.currentAction.animations.bottom = length;
    return this;
  }
  right(length){
    this.currentAction.animations.right = length;
    return this;
  }
  rotate(deg){
    this.currentAction.animations.rotateZ = deg + "deg";
    return this;
  }
  rotateX(deg){
    this.currentAction.animations.rotateX = deg + "deg";
    return this;
  }
  rotateY(deg){
    this.currentAction.animations.rotateY = deg+"deg";
    return this;
  }
  rotateZ(deg){
    this.currentAction.animations.rotateZ = deg + "deg";
    return this;
  }
  rotate3d(x,y,z,deg){
    this.currentAction.animations.rotate3d = [x,y,z,deg].join(",") + "deg";
    return this;
  }
  scale(sx,sy){
    if(!_.isNil(sx)){
      this.currentAction.animations.scaleX = sx;
    }

    if(!_.isNil(sy)){
      this.currentAction.animations.scaleY = sy;
    }
    return this;
  }
  scaleX(sx){
    this.currentAction.animations.scaleX = sx;
    return this;
  }
  scaleY(sy){
    this.currentAction.animations.scaleY = sy;
    return this;
  }
  scaleZ(sz){
    this.currentAction.animations.scaleZ = sz;
    return this;
  }
  scale3d(sx,sy,sz){
    if(!_.isNil(sx)){
      this.currentAction.animations.scaleX = sx;
    }

    if(!_.isNil(sy)){
      this.currentAction.animations.scaleY = sy;
    }
    if(!_.isNil(sz)){
      this.currentAction.animations.scaleZ = sz;
    }
    return this;
  }
  translate(tx,ty){
    if(!_.isNil(tx)){
      this.currentAction.animations.translateX = tx;
    }
    if(!_.isNil(ty)){
      this.currentAction.animations.translateY = ty;
    }
    return this;
  }
  translateX(tx){
    this.currentAction.animations.translateX = tx;
    return this;
  }
  translateY(ty){
    this.currentAction.animations.translateY = ty;
    return this;
  }
  translateZ(tz){
    this.currentAction.animations.translateZ = tz;
    return this;
  }
  translate3d(tx,ty,tz){
    if(!_.isNil(tx)){
      this.currentAction.animations.translateX = tx;
    }
    if(!_.isNil(ty)){
      this.currentAction.animations.translateY = ty;
    }
    if(!_.isNil(tz)){
      this.currentAction.animations.translateZ = tz;
    }
    return this;
  }
  skew(ax,ay){
    if(!_.isNil(ax)){
      this.currentAction.animations.skewX = ax;
    }
    if(!_.isNil(ay)){
      this.currentAction.animations.skewY = ay;
    }
    return this;
  }
  skewX(ax){
    this.currentAction.animations.skewX = ax;
    return this;
  }
  skewY(ay){
    this.currentAction.animations.skewY = ay;
    return this;
  }
  matrix(...args){
    this.currentAction.animations.matrix = args;
    return this;
  }
  matrix3d(...args){
    this.currentAction.animations.matrix3d = args;
    return this;
  }
  export(){
    if(this.actions.length > 0){
      let lastAction = this.actions[this.actions.length-1];
      if(_.isEmpty(lastAction.animations)){
          this.actions.pop();
      }
    }
    let actions = this.actions;
    this.actions = [];
    this.step();
    return actions;
  }
}
export {createAnimation,applyAnimation,stopAnimation};
