import URI from 'urijs';
import wx from 'core/api/wxApi';
import {last,endsWith} from 'lodash';
import tabbar from 'core/tabbar';
import 'core/framework/app';
import HotRenderClient from  "core/render/hot-replace-render/client";
import {parseMicroServiceSrc} from "../utils";
import CryptoRequest from "../api/network/crypto-request";
import {isInServiceMetaInfo} from "../api/ui/navigate";

/**
 菜单信息的记录：
    pc端   记录在 jupste.microService.menuMap中  key 为 originUrl  value为菜单项目
    pcx端（uix）  记录在 wx.microService.menuMap中
        key不为 originUrl  存储的为 url
          主要原因有 ：
            originUrl中带.w 这逻辑不平 在wx.navigator的api重写中被上层覆盖了 传递下来的url不带.w 已经和originUrl对不齐了
          所以存储为真实的url

    那么url的处理逻辑为
      pc中调用showPage或者navigatTo等api是传递originUrl
        首先上层处理后去掉.w 符合微信规范（这是历史遗留问题）
        底层处理过程中formatUrl为符合对应端的url传递给loader

      loader只负责加载页面
          从window.routes中找
          从远端pages-manifest中找  ，所以pages-manifest中的key也是url不是originUrl


 这里特殊逻辑在于 页面带不同参数打开算几个页面 （特殊逻辑permissionparams的逻辑算到页面url中）
        pc中不同参数算一个页面 走参数变化通知事件，permissionparams不同算不同页面。
        pcx（uix）中按照小程序规范 参数不同是不同页面，参数变化走消息交互。
 *
 */
class MicroServiceModelLoader {
  constructor(...props) {
    window.microService = {
      pages: {},
      modules: {},
      manifest:{},
      compatibilityModeLoadMenu:false,
      isMicroService: undefined,
      tabBars:[],
      dlls:[],
      menus:[],
      hotRenders:[],
      menuMap:{},
      spaMenuItems:[],
      spaApplications:[]
    };
    this.checkMicroServiceEnv();
  }

  get menuResolved(){
    this._menuResolvedPromise = this._menuResolvedPromise || new Promise((resolve,reject) =>{
      if(window.microService.isMicroService && window.microService.compatibilityModeLoadMenu){
        let self = this;
        let context = "mobile";
        if(window.isPcx) {
          context = "pcx";
        }
        let pathName = window.location.pathname;
        //兼容entry老门户 开发entry时候能加载uix页面
        if(pathName == "/entry/x5/UI2/v_/pc/index.w"){
          pathName = "/entry/pcapp/v_/pc/index.w";
        }
        let pathInfo = parseMicroServiceSrc(pathName);
        let menuRequest = new Request(CryptoRequest.parse(pathInfo.parentPath + "/entry/manager/menus?types=" + context + "&context=" + context).toString());
        fetch(menuRequest)
          .then(function (response) {
            if (response.status >= 400) {
              reject(`获取菜单清单失败！`);
            } else {
              return response.json();
            }
          })
          .then(function (data) {
            window.microService.menus = data;
            self.toMenuMap(data);
            tabbar.reset();
            resolve();
          }).catch(function(err){
          reject(`获取菜单清单失败！:${err}`);
        });
      }else{
        resolve();
      }
    })
    return this._menuResolvedPromise;
  }

  toMenuMap(menus){
    if(window.microService.isMicroService){
      for(let index=0;index < menus.length;index ++ ){
        let item = menus[index];


        // 需要走iframe或者openPage的页面 进行url补全 这样pageContainer中才能因为http开头按照iframe方式渲染
        if(item.url && item.types
          && (item.types.indexOf("iframe") != -1 || item.types.indexOf("openPage") != -1)
          &&  item.url.indexOf('http') != 0
        ){
          let parentPathAndServiceName = new URI(window.location.href).path().split(/([^\/]*)(pcx|pc|mobile)app\//g)[0];
          let parts = parentPathAndServiceName.split("/");
          parts.splice(-3);
          let uri = new URI(parts.join("/") + item.url);
          uri.origin(window.location.origin);
          item.url = uri.toString();
        }

        //微前端集成方案
        if(item.url && item.types && item.types.indexOf("app") != -1){
          let uri = new URI(item.url);
          uri.search({"$openType":"app"});
          item.url = uri.toString();
        }


        if(item.children && item.children.length > 0){
          if(['window','module'].indexOf(item.loadMode) != -1){
            window.microService.spaMenuItems.push(item);
          }
          this.toMenuMap(item.children);
        }else{
          if(item.types.indexOf("tab") != -1){

            let ext = item.ext && JSON.parse(item.ext).tabBar || {};
            let tabItem = {
              "pagePath":item.url.replace("/entry/mobileapp/","/"),
              "text":ext.text || item.title,
              "iconPath":ext.iconPath ? ext.iconPath.replace("/entry/mobileapp/","./"): ext.iconPath,
              "selectedIconPath":ext.selectedIconPath ?ext.selectedIconPath.replace("/entry/mobileapp/","./") :ext.selectedIconPath,
              "order":item.order
            }
            window.microService.tabBars.push(tabItem);
          }

          if(item.types.indexOf("entry") != -1){
              last(window.microService.spaMenuItems).entry = item.url;
          }
          //originUrl 兼容性保留
          window.microService.menuMap[item.originUrl] = item;

          window.microService.menuMap[item.url] = item;
        }
      }
      window.microService.tabBars.sort((a,b)=> a.order - b.order);
    }
  }

  registerSpaApplication(){
    return new Promise((resolve,reject)=>{
      //可以自动决定SystemJs加载器的引入
      if(window.microService.spaMenuItems.length > 0){
        require.ensure(['./single-spa/spa'],(require)=>{
          require('./single-spa/spa');
          resolve();
        });
      }else{
        resolve();
      }
    });

  }

  /**
   * 和ui2逻辑不同 不能通过加载门户后执行js设定是否为微服务模式
   *
   * 约定逻辑
   *
   *      开发时基础路径 为 http://aaa-ide.xcaas.net/uixweb/main/#main/index
   *      单体小程序发布后路径 http://aaa-ide.xcaas.net/aaa/#/main/index
   *      微服务开发时路径为 http://aaa-ide.xcaas.net/uixweb/main/#/eportal/uixweb/main/main/index
   *
   *      微服务开发后路径为  http://servicemanager-ide.xcaas.net/servicemanager/#/eportal/portal001/main/index
   */
  checkMicroServiceEnv(){
    let searchInfo = new URI(location.href).query(true);


    let isMicroService = searchInfo.isMicroService;

    if (isMicroService === "false") {
      window.isMicroService = false;
    } else if (isMicroService === "true") {
      window.isMicroService = true;
    }


    if (window.microService.isMicroService == undefined) {
      window.microService.isMicroService = window.isMicroService;
    }
  }


  preloadPage(params){
    if(params.url){
      params.currentPage._formatPageUrl(params);
      return this.loadPage(params.url,{},params.currentPage,"prefetch");
    }
  }
  /**
   *
   * @param moduleName  模块导出的变量名字 [name]_[hash]  加载模块的url可以通过modelName按照规则推出来
   * @param moduleId 主入口js在模块闭包中的modelId 比如webpack 中常用的数字1
   * @returns {Promise<any>}
   * @private
   *
   *   开发时不合成pages-manifest.json
   *   直接请求页面对应的模块文件
   *      通过nodejs server返回fullhash  X-Page-Hash  方便修改调试
   *
   *   发布时合成pages-manifest做到最少改动。
   *
   *   loadType: "prefetch"
   */
  loadPage(url,params,currentPage,loadType) {

    //优先加载本地页面否则   slm 2022-2-9 后续统一走页面编译加载方案 不加载本地页面

    /**
     * wx 分支保留短泾的写法 /mobile/work
     */
    let path = new URI(location.href).path();
    if(window.parentPath){
      path = path.replace(window.parentPath,"");
    }
    for (const route of window.routes) {
      const params = this.matchURI(route, url);
      if (!params) {
        continue;
      }
      if (window.microService.isMicroService) {
        let parts = path.split("/");
        let serviceName = parts[1];
        let contextName = parts[2];
        url = `/${serviceName}/${contextName}${url}`;
      }
    }

    //后续开发 运行都走 bundler模式 所以 开发时候直接请求页面地址
    if (window.microService.isMicroService) {
      return this.menuResolved.then(()=>{
        let parts = url.split("/");
        let serviceName = parts[1];
        /*if(window.microService.spaApplications.indexOf(serviceName) == -1){

        }*/
        return this.loadRemotePage({url,params,currentPage,loadType});
      });
    }else{
      //非微服务模式运行  比如调试环境的预览运行  或者普通应用的发布
      return this.loadRemotePage({url,params,currentPage,loadType});
    }
  }

  loadRemoteApplication(url){
    // /planets/planets/
    let parts = url.split("/");
    let serviceName = parts[1];
    let contextName = parts[2];
    return this._loadApplicationModel(serviceName, contextName).then(function (pageModel) {
      pageModel.serviceName = serviceName;
      pageModel.contextName = contextName;
      pageModel.loadMode = "remote";
      return pageModel;
    });
  }
  loadRemotePage({url,params,currentPage,retry = true,loadType}){

    /**
     *    url为 如下   微服务模式
     *   /eportal/pcxapp/pcx/index
     *
     *   预览运行的url如下 非微服务模式
     *   /pcxapp/pcx/index
     *   兼容url
     *   /pcx/index
     *
     *   兼容src最后是/:pagetime的逻辑
     */
      let parts = url.split("/");

      //兼容src最后是/:pagetime的逻辑
      if (!isNaN(parts[parts.length-1])){
        parts.pop();
      }

      //

      let serviceName = parts[1];
      let contextName = parts[2];
      let appName = contextName.replace(/app$/,"");
      let pagePath =  "/" + parts.slice(3).join("/");



      //规范化url
      if(endsWith(contextName,"app") && !endsWith(serviceName,"app")){
        //标准微服务

      }else if(endsWith(serviceName,"app") && !endsWith(contextName,"app")){
        //应用预览标准url  /pcxapp/pcx/a.w
        serviceName = "";
        contextName = parts[1];
        appName = contextName.replace(/app$/,"");
        pagePath =  "/" + parts.slice(2).join("/");

      }else if(!endsWith(serviceName,"app") && !endsWith(contextName,"app")){
        //应用预览兼容url  /pcx/a.w  这个有坑值兼容 比如 /comp/a.w 就推不出来  保留型存在
        serviceName = "";
        contextName = parts[1] + "app";
        let pathParts = location.pathname.split("/");
        if(pathParts[2] == contextName){
          //预览内的 调试运行
          serviceName  = pathParts[1];
        }
        appName = parts[1];
        pagePath = "/" + parts.slice(1).join("/");
      }

      window.microService[serviceName] = window.microService[serviceName] || {};


    /**
     * 兜底支持pcx路径
     * 因为存在pcx中加载mx制作的页面的场景 所有不能直接替换
     * 流程组件 之类的对话框没有写serviceMetaInfo所以需要替换为pcx
     *   改为先通过serviceMetaInfo判断 如果serviceMetaInfo中存在描述 按照已经计算好的处理
     *   如果serviceMetaInfo中没有符合条件的 按照兜底逻辑处理
     *
     *
     */

    if(window.isPcx){
      if(contextName.indexOf("pcx")==-1){
        if(!isInServiceMetaInfo(url)){
          contextName = contextName.replace("mobile",'pcx');
        }
      }
    }



      //通用目录下 路径处理 不能按照所在页面端类行支持 必须按照当前运行环境类型支持
     //比如 pc门户打开移动页面 移动页面的弹框 最好能谈pc的对话框比较好
      if(contextName == "compapp" || contextName == "wxsysapp"){
        contextName = window.isPcx?"pcxapp":"mobileapp";
      }

      let pageModuleId;

      //特殊支持发布环境中嵌入开发ide应用。
      let isUixWebMode = (url.indexOf("uixweb") != -1);
      if (isUixWebMode) {
         // 这一只 后续废弃
        contextName = parts[2] + "/" + parts[3];
        appName = parts[4];
        pageModuleId = "./pages/" + parts.slice(4).join("/") + ".component.js";
      } else {
        pageModuleId = "./pages" + pagePath + ".component.js";
      }
      /**
       * 发布模式为了提供性能 先拉去了服务下的页面列表  后续请求 根据内存即可获取最新的hash值来加载 相同hash值可以命中浏览器缓存
       */
      return this._loadServiceManifest(serviceName, contextName,currentPage).then((serviceManifest) => {

        let pageInfo = null;
        if(serviceManifest.serverType == "devServer"){
          pageInfo = {
            id: pageModuleId,
            name: pageModuleId.replace(/[\.|\/]/g, "_"),
            serverType:"devServer"
          }
        }else{
          pageInfo = serviceManifest.pages[pageModuleId];

          if(!pageInfo){
            /*if(!window.isPcx){
              wx.showModal({
                "title":`在服务[${serviceName}]中未找到合适的页面打开，请稍后重试。`,
                "showCancel":false,
                success () {
                  if(location.hash.indexOf(src) != -1){
                    history.back();
                  }
                }
              });
            }*/
        	console.error(`服务[${serviceName}]中未找到适合的页面:[${pageModuleId}]的信息，请稍后重试。`);
            throw {
              error:`此功能[${pageModuleId}]不支持当前设备！`,
              serviceName: serviceName,
              contextName:contextName,
              loadMode:'remote'
            };
          }
        }

        return this._loadPageModel({serviceName, contextName, pageInfo,currentPage,url,params,retry,loadType}).then(function (pageModel) {
            pageModel.parentPath = currentPage.parentPath;
            pageModel.serviceName = serviceName;
            pageModel.contextName = contextName;
            pageModel.loadMode = "remote";
          return pageModel;
        }).catch((e)=>{
          if(typeof e == "string"){
            throw {
              error:`页面打开失败:${url}`,
              serviceName: serviceName,
              contextName:contextName,
              loadMode:'remote',
              stack:e
            }
          }else{
            throw {
              error:`页面打开失败:${url}`,
              serviceName: serviceName,
              contextName:contextName,
              loadMode:'remote',
              stack:e?.stack || e
            }
          }
        });
      });
  }

  // Match the provided URL path pattern to an actual URI string. For example:
  //   matchURI({ path: '/posts/:id' }, '/dummy') => null
  //   matchURI({ path: '/posts/:id' }, '/posts/123') => { id: 123 }
  matchURI(route, path) {
    const match = route.pattern.exec(path);
    if (!match) {
      return null;
    }
    const params = Object.create(null);
    for (let i = 1; i < match.length; i++) {
      params[route.keys[i - 1].name] = match[i] !== undefined ? this.decodeParam(match[i]) : undefined;
    }
    return params;
  }

  decodeParam(val) {
    if (!(typeof val === 'string' || val.length === 0)) {
      return val;
    }

    try {
      return decodeURIComponent(val);
    } catch (err) {
      if (err instanceof URIError) {
        err.message = `Failed to decode param '${val}'`;
        err.status = 400;
      }

      throw err;
    }
  }

  _loadPageModel({serviceName, contextName, pageInfo,currentPage,url,params,retry,loadType}) {
      let modelId = pageInfo['id'];
      let modelName = pageInfo['name'];

      if("devServer" == pageInfo.serverType){
        /**
         * 开发模式
         * locaiton.pathname
         *  1. 预览运行  第一级为contextName  不启用微服务模式 所以不需要serviceName
         *  2. 开发模式的调试运行  第一级为 baseName  第二级为 contextName  这种场景 外面传递的时候 就是正确的
         */
        let src = "/" + serviceName + "/" + contextName + "/pages/" + modelName + ".js?modelId=" + encodeURIComponent(modelId) + "&modelName=" + encodeURIComponent(modelName);
        if(!serviceName){
          src = "/" + contextName + "/pages/" + modelName + ".js?modelId=" + encodeURIComponent(modelId) + "&modelName=" + encodeURIComponent(modelName);
        }
        return this._load({src,currentPage,url,params,"type":"page",serviceName,contextName,serverType:pageInfo.serverType,retry,loadType}).then(function () {
          window['microService.modules.' + modelName].c = currentPage.parentPath + (serviceName?"/" + serviceName + "/" + contextName + "/pages/":"/" + contextName + "/pages/");
          window['microService.modules.' + modelName].p = currentPage.parentPath + (serviceName?"/" + serviceName + "/" + contextName + "/pages/":"/" + contextName + "/pages/");
          let result = null;
          try{
            result = window['microService.modules.' + modelName](modelId).default;
          }catch(e){
            console.error(e);
            result = window['microService.modules.' + modelName](modelId.replace('.component.js','.error.component.js')).default;
          }
          return result;
        });
      }else{
        //生产模式
        let src = (serviceName?"/" + serviceName:"") + "/" + contextName + "/pages/" + modelName + ".js?modelId=" + encodeURIComponent(modelId) + "&modelName=" + encodeURIComponent(modelName);
        if (window.microService.modules[modelName]) {
          //模块文件是否已经装载过
          return Promise.resolve(window.microService.modules[modelName]);
        } else if (window['microService.modules.' + modelName]) {
          //模块文件是否已经加载过
          window.microService.modules[modelName] = window['microService.modules.' + modelName](modelId).default;
          return Promise.resolve(window.microService.modules[modelName]);
        } else {
          return this._load({src,currentPage,url,params,type:"page",serviceName,contextName,serverType:pageInfo.serverType,retry,loadType}).then(function (pageModel) {
            if(pageModel){
              return pageModel;
            }
            window['microService.modules.' + modelName].c = currentPage.parentPath + (serviceName?"/" + serviceName + "/" + contextName + "/pages/":"/" + contextName + "/pages/");
            window['microService.modules.' + modelName].p = currentPage.parentPath + (serviceName?"/" + serviceName + "/" + contextName + "/pages/":"/" + contextName + "/pages/");
            if(!window.microService.modules[modelName]){
              window.microService.modules[modelName] = window['microService.modules.' + modelName](modelId).default
            }
            return window.microService.modules[modelName];
          });
        }
      }
  }

  _loadApplicationModel(serviceName, contextName) {

      let modelName = serviceName;
      //根据
      let path = "/" + serviceName + "/" + contextName + "/spa.js";
      //模块是否已经加载过
      if (window.microService.modules[modelName]) {
        return Promise.resolve(window.microService.modules[modelName]);
        //模块文件是否已经加载过
      } else if (window['microService.modules.' + modelName]) {
        window.microService.modules[modelName] = window['microService.modules.' + modelName];
        return Promise.resolve(window.microService.modules[modelName]);
        //需要先拉取模块文件再做模块获取
      } else {
        return this._load({src:path}).then(function () {
          //window['microService.modules.' + modelName].p = "/" + basePath + "/" + contextPath + "/";
          window.microService.modules[modelName] = window['microService.modules.' + modelName];
          return window.microService.modules[modelName];
        });
      }
  }


  //获取服务的资源信息 并处理
  _loadServiceManifest(serviceName, contextName,currentPage) {
      let key = serviceName + contextName;
      if (window.microService.manifest[key] && window.microService.manifest[key + "_promise"] && window.microService.manifest[key].serverType != "devServer") {
        return window.microService.manifest[key + "_promise"];
      } else {
        let requestUrl = CryptoRequest.parse(currentPage.parentPath + (serviceName?"/" + serviceName : "") + (contextName?"/" + contextName : "") + "/service-manifest.json?t=" + new Date().getTime()).toString();
        let serviceManifestRequest = new Request(requestUrl);
        let loadServiceManifestPromise = fetch(serviceManifestRequest)
          .then(function (response) {
            if (response.status >= 400) {
              throw `获取服务[${serviceName}]页面列表信息失败，请稍后重试...`;
            } else {
              return response.json();
            }
          })
          .then(function (data) {
            window.microService.manifest[key] = data;
            return data;
          }).then((data)=>{
            if(data.hotRender){
              if(!window.microService.hotRenders[key]){
                let hotRender = new HotRenderClient(serviceName,contextName);
                window.microService.hotRenders[key] = hotRender;
              }
            }
            return data;
        }).then((data)=>{
          let dlls = data.dlls || [];
          let dllLoadPromises = [];
          for(let dllInfo of dlls){
            dllLoadPromises.push(this._loadDllModule(serviceName,contextName,dllInfo,currentPage));
          }
          return new Promise((resolve, reject)=>{
            Promise.all(dllLoadPromises).then(()=>{
              resolve(data);
            }).catch((err)=>{
              reject(err);
            })
          });
        }).catch(function(){
          throw `服务[${serviceName}]应用信息加载失败！`;
        });
        window.microService.manifest[key + "_promise"] = loadServiceManifestPromise;
        return loadServiceManifestPromise;
      }
  }

  _loadDllModule(serviceName, contextName, dllInfo,currentPage) {
    let name = dllInfo.name;
    let path = dllInfo.path;
    let libraryName = dllInfo.libraryName;
    let src = currentPage.parentPath + (serviceName ? "/" + serviceName: "") + "/" + contextName + path;
    let dllLoadInfo = window.microService.dlls[libraryName];
    if(!dllLoadInfo){
      //通过script加载的
      if (window[libraryName]){
        dllLoadInfo = {
          loadPromise:Promise.resolve(),
          name
        }
      }else{
        dllLoadInfo = {
          loadPromise:this._load({src,currentPage}),
          name
        }
      }
      window.microService.dlls[libraryName] = dllLoadInfo;
    }else{
      /**
       * 已经加载过的dll 需要检查dll版本号
       */
      if(dllLoadInfo.name != name){
        console.log(
          `%c警告:库%c${libraryName} %c加载版本: %c${dllLoadInfo.name} %c和期望加载的版本 %c${name} %c不一致\n%c重新发布门户后使用最新版本可能修复此问题`,
          'font-size:20px','font-size:20px;color: blue','font-size:20px;','font-size:20px;color: green','font-size:20px;', 'font-size:20px;color: red','font-size:20px;',''
        );
      }
    }
    dllLoadInfo.loadPromise.then(function(){
      let oldBasePath = window[libraryName].p;
      if(oldBasePath && oldBasePath.charAt(0) == "."){
        let basePath = oldBasePath.replace(".",currentPage.parentPath + (serviceName?"/" + serviceName + "/" + contextName :"/" + contextName));
        window[libraryName].c = basePath;
        window[libraryName].p = basePath;
      }
    });

    return dllLoadInfo.loadPromise;
  }

  _loadErrorHandler({src,currentPage,type,url,params,serviceName,contextName}){
    /**
     *  这里只处理生产环境加载失败
     *   加载失败有2中情况
     *   1. 权限不足
     *   2. 页面404 404在微前端场景下  如果一个应用的serviceManifest已经被内存缓存 这个过程中 应用重新发布，
     *        那么过期的serviceManifest中的hash值为旧值 会走到404
     *        这个时候 需要清除这个应用的serviceManifest缓存 根据新hash重新请求
     */
    return new Promise((resolve,reject)=>{
      if(type != "page"){
        reject();
        return;
      }
      let retryRequest = new Request(currentPage.parentPath + src);
      fetch(retryRequest)
        .then( (response) =>{
          if (response.status == 404) {
            let key = serviceName + contextName;
            if (window.microService.manifest[key]) {
              //生产模式 需要清空缓存重试
              window.microService.manifest[key] = undefined;
              this.loadRemotePage({url,params,currentPage,retry:false}).then((pageModel)=>{
                 resolve(pageModel);
              }).catch((e)=>{
                 reject(e);
              });
            }
          } else if (response.status == 401 ){
            let reason = response.text();
            reject(reason);
          } else {
            reject("网络不稳定,请稍后重试");
          }
        })
        .catch(function(err){
          reject("网络异常,请稍后重试");
      });
    });
  }


  async _preLoad({src,currentPage,url,params,type,serviceName,contextName,serverType,retry,loadType}) {


    return new Promise( (resolve, reject) =>{

      if(currentPage){
        let subjectInfo;
        if(currentPage.getRequestInfo){
          subjectInfo = currentPage.getRequestInfo("X-Credential-Subject-Info");
        }else if(currentPage.wxPageDeclaration){
          subjectInfo = currentPage.wxPageDeclaration.$page.getRequestInfo("X-Credential-Subject-Info");
        }

        if(subjectInfo){
          let uri = new URI(src);
          uri.addSearch("X-Credential-Subject-Info", subjectInfo);
          src = uri.toString();
        }
        if(params && params["$credentialAuthorizeInfo"]){
          let uri = new URI(src);
          uri.addSearch("$credentialAuthorizeInfo", params["$credentialAuthorizeInfo"]);
          src = uri.toString();
        }

      }
      let href = CryptoRequest.parse(currentPage.parentPath + src).toString();

      const selector = `link[rel="prefetch"][href^="${href}"],
                      link[rel="preload"][href^="${href}"],
                      script[src^="${href}"]`;
      if (document.querySelector(selector)) {
        resolve();
        return;
      }

      let head = document.getElementsByTagName('head')[0];
      let link = document.createElement('link');
      link.rel = `prefetch`;
      link.charset = 'utf-8';
      link.href = CryptoRequest.parse(currentPage.parentPath + src).toString();
      head.appendChild(link);

      let loadSuccess =  () =>{
        link.removeEventListener('load', loadSuccess, false);
        link.removeEventListener('error', loadError, false);
        resolve();
      };

      let loadError = ()=> {
        link.removeEventListener('load', loadSuccess, false);
        link.removeEventListener('error', loadError, false);
        reject();
      };
      link.addEventListener('load', loadSuccess, false);
      link.addEventListener('error', loadError, false);
    });
  }

  async _load({src,currentPage,url,params,type,serviceName,contextName,serverType,retry,loadType}) {

    if(loadType == "prefetch"){
        await this._preLoad({src,currentPage,url,params,type,serviceName,contextName,serverType,retry,loadType});
    }

    return new Promise( (resolve, reject) =>{
      let head = document.getElementsByTagName('head')[0];
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.charset = 'utf-8';
      script.async = true;
      if(currentPage){
        let subjectInfo;
        if(currentPage.getRequestInfo){
          subjectInfo = currentPage.getRequestInfo("X-Credential-Subject-Info");
        }else if(currentPage.wxPageDeclaration){
          subjectInfo = currentPage.wxPageDeclaration.$page.getRequestInfo("X-Credential-Subject-Info");
        }

        if(subjectInfo){
          let uri = new URI(src);
          uri.addSearch("X-Credential-Subject-Info", subjectInfo);
          src = uri.toString();
        }
        if(params && params["$credentialAuthorizeInfo"]){
          let uri = new URI(src);
          uri.addSearch("$credentialAuthorizeInfo", params["$credentialAuthorizeInfo"]);
          src = uri.toString();
        }

      }
      script.src = CryptoRequest.parse(currentPage.parentPath + src).toString();
      head.appendChild(script);
      let loadSuccess =  () =>{
        script.removeEventListener(name, loadSuccess, false);
        script.removeEventListener(name, loadError, false);
        resolve();
      };

      let loadError = ()=> {
        script.removeEventListener(name, loadSuccess, false);
        script.removeEventListener(name, loadError, false);
        if(retry){
          this._loadErrorHandler({src,url,params,currentPage,serviceName,contextName,type}).then((pageModel)=>{
            resolve(pageModel);
          }).catch((e)=>{
            reject(e);
          })
        }else{
          reject();
        }
      };
      script.addEventListener('load', loadSuccess, false);
      script.addEventListener('error', loadError, false);
    });
  }
}

export default new MicroServiceModelLoader();
