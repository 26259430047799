import * as singleSpa from 'single-spa';
let spaMenuItems = window.microService.spaMenuItems;
let spaApplications = window.microService.spaApplications = [];
import header from '../../header';
import tabbar from '../../tabbar';
spaMenuItems.forEach(function(item){
    let url = item.entry || item.children[0].url || item.children[0].children[0].url;
    let parts = url.split("/");
    let baseName = parts[1];
    let contextName = parts[2];
    let parentNode = document.querySelector('#container');
    spaApplications.push(baseName);
    console.log('registerApplication:' + baseName);
    singleSpa.registerApplication(baseName, () => {
      console.log('registerApplication end:' + baseName);
      let entryUrl = item.entry || "/" + baseName + "/entry.js";
      //集成方案为window平台提供默认iframe的脚手架方案
      if(item.loadMode == "window"){
        entryUrl = item.entry || "./iframe-entry.js";
      }
      let loadingFunction = System.import(entryUrl);
      return loadingFunction.then(function(data){
        let Config  = data.default.default;
        if(typeof Config == "function"){
          let config = new Config();
          config.bootstrap = config.bootstrap.bind(config);
          config.mount = config.mount.bind(config);
          config.unmount = config.unmount.bind(config);
          return config;
        }else if(Config){
          return Config;
        }else{
          return window["microService.modules." + baseName];
        }
      });
    }, (location)=>{
      return location.hash.indexOf(`#/${baseName}`) === 0
    },{
      header,tabbar,baseName,contextName,item,parentNode
    });
});

!function(){
  let userinfoResolve;
  let userinfoReject;
  window.microService.userinfoResolver = new Promise(function(resolve,reject){
    userinfoResolve = resolve;
    userinfoReject = reject;
  });
  window.microService.userinfoResolver.resolve = userinfoResolve;
  window.microService.userinfoResolver.reject = userinfoReject;
}();
//特殊逻辑，企业应用要求先登录
window.microService.userinfoResolver.then(function(userinfo){
  singleSpa.userinfo = userinfo;
  singleSpa.start();
});


