import {doThen} from '../../utils';

/*
 success	Function	否	接口调用成功的回调函数
 fail	Function	否	接口调用失败的回调函数
 complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
 */
function stopPullDownRefresh(params){
  let page = _.last(getCurrentReactPages());
  let stopPromise = page.stopPullDownRefresh();
  stopPromise.then(() => {
    doThen(params);
  });
}

function startPullDownRefresh(params){
  let page = _.last(getCurrentReactPages());
  let startPromise = page.startPullDownRefresh();
  startPromise.then(() => {
    doThen(params);
  });
}
export {startPullDownRefresh,stopPullDownRefresh};
