import DataStateLinker from './dataStateLinker';
import PropTypes from "prop-types";
import {_exRun} from "../utils";
import React from "react";
import wx from "../api/wxApi";
import WxPageComponent from "./WxPageComponent";
import PageContext from "./PageContext";
import i18next from "i18next";
import Md5 from "crypto-js/md5";
import {merge, get, pick, set, isArray, forEach, isObject, isString} from "lodash";
import ConfigContextProcessor from './ConfigContextProcessor';
import URI from "urijs";

/**
 PageView.class(.w)  -> PageViewModel.class                     -> 用户代码(.js) ->      Page.js            ->              PageComponent  ->   WxPageComponent
                                                                                                                                              (pcx mx wx各有不同后缀 .mobile.js .pc.js .wx.js)
 render 			       ->   通用逻辑logic                           -> 业务逻辑       ->     提供了通用api                         react集成   ->       完成wx(多端支持)相关逻辑

                         1. model上派发生命周期事件的能力                                  1. 比如this.comp this.setData       1. 提供react相关api  		1. 部分方法是通过instance复制扩展的方法(满足hcr上层event this.disDispatchEvent的能力)
                         2. before after逻辑																				                                                              2. 比如taro集成逻辑在WxPageComponent.mobile.js 中实现。
                         3.在super.onLoad()之前初始化状态树                                                                                         3. 小程序的状态树定义和编译生成方法 在构造时候存贮
                         4。保证用户onload状态树初始化正常
                         5. super.onLoad 之后
 */

function _requireViewTransition(action){
  let current;
  _requireViewTransition = (action)=>{
    if(!current){
      current = "ViewTransition@" + new Date().getTime();
      document._currentViewTransition = document.startViewTransition(()=>{
        action();
      });
      (window.requestIdleCallback || window.setTimeout)(()=>{
        current = null;
      })
    }else{
      action();
    }
  }
  _requireViewTransition(action);
}

async function initAppLifeCycle({lifeCycle,page}){
  let app = getApp();
  if (app && app[lifeCycle] && (typeof app[lifeCycle] == 'function') && (!app[lifeCycle]._inited)){
    app[lifeCycle]._inited = true;
    await app[lifeCycle]({page});
  }

  if(lifeCycle == "onLaunch"){
    let header = await import('core/header');
    header.default.setState({
      launched:true
    });
    let tabbar = await import('core/tabbar');
    tabbar.default.setState({
      launched:true
    });
  }
}


export default class PageComponent extends WxPageComponent{
  constructor(props,context) {
    super(props,context);
    this.$requestInfo = {};
    this.configContextProcess = new ConfigContextProcessor(this,context);

    Promise.all([
      this.configContextProcess.processConfigContext(),
      initAppLifeCycle({
        lifeCycle:"onLaunch",
        page:this
      })
    ]).then(() => {
      this.constructDeferred.resolve();
    }).catch((err) => {
      this.constructDeferred.reject(err);
    });
  }



  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextProps.loading == true && this.props.loading == true) {
      return false;
    }
    return true;
  }




  i18n(key,callerPath){
    /**
     * 适配平台编译后路径
     * xxxx.component.js -> xxxx.w
     *
     * xxxx.user.js - > xxxx.js
     *
     */
    //相对场景 需要传callerPath
    // 兼容key为空场景
    if(!key){
      return key;
    }
    let defaultValue = key;
    //兼容key带特殊字 比如 : 或者同时有  < > 场景 进行md5做key处理
    if(key.indexOf(":") != -1 || (key.indexOf("<") !=-1 && key.indexOf(">") !=-1)){
      key = Md5(key).toString();
    }
    //兼容key带> <  比如  订单金额 > 5
    if((key.indexOf(">") != -1 || key.indexOf("<") !=-1) && key.charAt(0) != "/" && key.indexOf("components") != 0){
      key = Md5(key).toString();
    }



    if(key.indexOf(">") == -1){
      let path;
      if(!callerPath){
        callerPath = this.pagePath + ".w";
        path = callerPath.replaceAll("/",">/").replace(">/","/") + ">" + key;
      }else {
        //从js文件中调用的 需要传递import.meta.url
        callerPath = callerPath.split("/pages")[1];
        callerPath = callerPath.replace(/\.component\.js$/,".w").replace(/\.user\.js$/,".js");
        callerPath = callerPath.replace(".vue%3Fvue&type=script&setup=true&lang=jsx",".js");
        let bashPath = this.serviceName? "/" + this.serviceName + "/" +  this.contextName : "/" +  this.contextName;
        callerPath = (bashPath + callerPath);
        path = callerPath.replaceAll("/",">/").replace(">/","/") + ">" + key;
      }
      let contextResourcePath = (this.serviceName? ("/" + this.serviceName + ">")  : "") + key;
      return i18next.t(path,{defaultValue:""}) || i18next.t(contextResourcePath,{defaultValue:""}) || i18next.t(key,{defaultValue:""}) || defaultValue;
    }else{
      //全路径的场景
      let parts = key.split('>');
      let defaultValue = parts[parts.length -1];
      return i18next.t(key,{defaultValue:defaultValue}) || defaultValue;
    }
  }

  constructed() {
    super.constructed();
    this.initCompProxy();
    this.reactRefs = {};
    this.__idRefMapping = {};
    //reactRefs 挂载到标准小程序定义json中的reactRefs域中
    this.wxPageDeclaration.reactRefs = this.reactRefs;
    this.wxPageDeclaration.__idRefMapping = this.__idRefMapping;
  }

  async startViewTransition(action){
    try{
      this._viewTransitionRunning = true;
      await action();
      this._viewTransitionRunning = false;
    }finally{
      this._viewTransitionRunning = false;
    }
  }

  getCurrentTheme(){
    return this.configContext[this.configContext.currentTheme || "theme"];
  }

  getCurrentLanguage(){
    let currentLanguageId = this.configContext.currentLanguageId || "zh-CN";
    let result = pick(this.configContext.i18n,currentLanguageId)?.[currentLanguageId] || {};
    result.languageId = currentLanguageId;
    return result;
  }

  getConfig(key){
    if(isString(key)){
        let callerPath = this.pagePath + ".w";
        let parts = (this.pagePath + ".w").split('/');
        parts = parts.map((item) => {
          return "/" + item;
        });
        parts[0] = 'config';
        parts.push(key);
        return get(this.configContext, parts);
    }else if(isArray(key)){
      return get(this.configContext, ["config",...key]);
    }
    //兼容小程序json文件的配置兜底
    return this.owner && this.owner.getConfig();
  }

  initViewTransition(){
    if(document.startViewTransition && !this.reactSetState) {
      let reactSetState =  this.setState;
      this.reactSetState = reactSetState;
      this.setState = (partialState, callback)=>{
        if(this.state.loading == false && this._viewTransitionRunning){
          _requireViewTransition(()=>{
            reactSetState.apply(this,[partialState, callback]);
          });
        }else {
          reactSetState.apply(this,[partialState, callback]);
        }
      }
    }
  }


  initCompProxy(){
    if(this.comp){
      this.comp = new Proxy(this.comp,{
        get: (comp, prop,receiver)=> {
          return comp.bind(this)(prop);
        }
      })
    }
  }


  get componentType() {
    if(this.vue?.setup){
      return "vue";
    }else {
      return "react";
    }
  }

  /*supportReactState(){
    //打通setState逻辑 支持用户js 定义state 并且 setState
    let $page = this.wxPageDeclaration.$page;
    if(this.wxPageDeclaration.$page.state){
      this.state = {
        ...this.state,
        ...this.wxPageDeclaration.$page.state
      };
    }
    Object.defineProperty($page, 'state', {
      get: ()=>{ return this.state }
    });
    $page.setState = this.setState.bind(this);
    $page.reactPageInstance = this;
  }*/

  mergeWxPageDeclarationFunction() {
    //需要合并 小程序定义页的函数 到 react的page对象上 hcr的事件查找逻辑需要
    let self = this;
    for (let name in this.wxPageDeclaration) {
      if (this.wxPageDeclaration.hasOwnProperty(name)) {
        if (typeof this.wxPageDeclaration[name] === "function") {
          if(this[name] && name != "getPage"){
            console.warn(`冲突的方法${name}`)
          }else{
            this[name] =  this.wxPageDeclaration[name].bind(this.wxPageDeclaration)
          }
        }
      }
    }
  }

  // 标准逻辑中需要这个api  但是createPageConfig中已经占用了这个名字 而且需要复制过来 所以这里写个空的api
  // 方便javascript编辑器代码提示和检查 运行时getPage被覆盖后重写
  getPage(){
  }

  getChildContext() {
    return {__root__: this};
  }

  async onLoad(query) {


    this.mergeWxPageDeclarationFunction();
    await super.onLoad(query);
    let userPage = this.wxPageDeclaration["$page"];
    console.log("页面onload之后初始化h5环境");
    if(wx.initenv){
      wx.initenv(userPage);
    }
    console.log(userPage);
    //reactRefs 挂载到标准小程序定义json中的reactRefs域中
    /*this.wxPageDeclaration.$page.reactRefs = this.reactRefs;
    this.wxPageDeclaration.$page.__idRefMapping = this.__idRefMapping;
    this.supportReactState();*/
  }
  // onReady
  onReady () {
    super.onReady();
    this.initViewTransition();
  }


  // 对应 onShow
  async onShow () {
    await initAppLifeCycle({
      lifeCycle:"onShow",
      page:this
    })
    super.onShow();
  }



  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
    if (this.onUnload && (typeof this.onUnload == "function")){
      this.onUnload();
    }
    this._ismounted = false;
  }

  //历史问题保留 后续应该组件内统一调用fireEvent 这个方法可以去掉
  __$$callFn(name, args){
    if (!this[name] || (typeof this[name] !== "function")){
      throw new Error("页面必须定义'" + name + "'方法");
    }
    return this[name].apply(this, args);
  }

  //扩展上层使用react对接函数 deprecated  uix体系 用下面
  addReactRef(compid,name,id){
    if(compid){
      let refId = name ? (compid + "-" +  name) :compid;
      this.reactRefs[refId] = this.reactRefs[refId] || React.createRef();
      if(id){
        this.__idRefMapping[id] = this.reactRefs[refId];
      }
      return this.reactRefs[refId];
    }
  }

  onReactRefChanged(refId,id,ref){
    if(refId){
      this.reactRefs[refId] = this.reactRefs[refId] || React.createRef();
      if(ref){
        this.__idRefMapping[id] = this.reactRefs[refId];
      }
      this.reactRefs[refId].current = ref;

      let event = new CustomEvent("onReactRefChanged",{detail:{
        refId,id,ref
      }});
      this.emitter.emit('onReactRefChanged',event);
    }
  }

  getModuledCss(classNames){
    if(!classNames){
      return "";
    }
    let s = this.wxPageStyle || {};

    //cssModule
    let cssModuleClassName = classNames.split(' ').map(function (item) {
      return s[item] || "";
    });
    //className
    classNames = classNames + " " + cssModuleClassName.join(' ');
    //console.log("模块化css:" + classNames);
    return classNames;
  }


  getData(dataId){
    return this.callPageModelMethod(dataId);
  }
  /*
  会合并所以这里不定义getPage

  getPage(){
    return this.callPageModelMethod();
  }
  */


  callPageModelMethod(compid,method,...params){
    let page = this.wxPageDeclaration && this.wxPageDeclaration.$page;
    if(!compid){
      return page;
    }
    if(compid && page) {
      let wxComp = this.wxPageDeclaration.$page.comp(compid);

      if(!method){
        return wxComp;
      }
      return wxComp[method].call(wxComp, params, this);
    }
  }


  setRequestInfo(key,value){
    this.$requestInfo[key] = value;
  }


  removeRequestInfo(key){
    delete this.$requestInfo[key];
  }

  getRequestInfo(key){
    return key?this.$requestInfo[key]:this.$requestInfo;
  }

  getPageTitle(){
    return this.i18n(this.params?.title || this.title || this.config?.navigationBarTitleText);
  }

  async request(params){
    //支持$service的写法
    if(params.url.indexOf("$serviceName") == 0){
      params.url = params.url.replace("$serviceName",this.serviceName? ("/" + this.serviceName) :"");
    }

    //兼容写 this.request({url:"/entry/authorize/currentUserRoles"}) 这种写法 url不是通过api得到的
    if(this.parentPath && params.url && params.url.indexOf("/") == 0 && params.url.indexOf(this.parentPath) != 0){
      params.url = this.parentPath +  params.url;
    }

    let requestHeader = this.getRequestInfo();
    params.header = {...requestHeader,...(params.header || {})};

    let languageId = this.getCurrentLanguage().languageId;
    if(!params.header["Accept-Language"] && !params.header["accept-language"] && languageId){
      params.header["Accept-Language"] = languageId;
    }
    try{
      let result = await wx.request(params);
      processI18nResult({params,result,page:this});
      return result;
    }catch(result){
      processI18nErrorResult({result,page:this});
      throw result;
    }
  }
}

function processI18nErrorResult({result,page}){
  let key = (page.serviceName ?"/"+ page.serviceName  + "/service": "/service") + ".@error";
  key = key.replaceAll("/","./");

  let languageInfo = page.getCurrentLanguage();
  let currentRequestI18nInfo = get(languageInfo,`resource${key}`);
  if(currentRequestI18nInfo){
    let {data} = result;
    function processData(item){
      if(isArray(item)){
        forEach(item,  (_item)  =>  {
          processData(_item);
        });
      }else if(isObject(item)){
        forEach(item,  (_item,key)  =>  {
          if(isString(_item) && currentRequestI18nInfo[key]){
            item[key] = currentRequestI18nInfo[key][_item] || _item;
          }
          if(isArray(_item) || isObject(_item)){
            processData(_item);
          }
        });
      }
    }
    processData(data);
  }
}

function processI18nResult({params,result,page}){
  let path = new URI(params.url).path();
  path = path.replace(new RegExp("^/" + page.serviceName + "/"),"/"+ page.serviceName  + "/service/");
  path = path.replaceAll("/","./");
  let key = path + ":" + params.method?.toUpperCase() || "GET"

  let languageInfo = page.getCurrentLanguage();
  let currentRequestI18nInfo = get(languageInfo,`resource${key}`);
  if(currentRequestI18nInfo){
    let {data} = result;
    function processData(item){
      if(isArray(item)){
        forEach(item,  (_item)  =>  {
          processData(_item);
        });
      }else if(isObject(item)){
        forEach(item,  (_item,key)  =>  {
          if(isString(_item) && currentRequestI18nInfo[key]){
            item[key] = currentRequestI18nInfo[key][_item] || _item;
          }
          if(isArray(_item) || isObject(_item)){
            processData(_item);
          }
        });
      }
    }
    processData(data);
  }
}


PageComponent.childContextTypes = {
  __root__: PropTypes.object
};

