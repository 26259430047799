import PageContext from "core/framework/PageContext";
import {useContext,useRef} from 'react';

const usePage = (props) => {
  const pageContext = useContext(PageContext);

  let isUpdate = useRef()
  const getPage = () => {
    return pageContext;
  }
  const fireEvent = function(eventName,event,defaultAction,targetProps = props){
    targetProps[eventName] && targetProps[eventName](event);
    if((!event) || (!event.cancel)){
      defaultAction && defaultAction(event);
    }
  }

  return {
    getPage,
    fireEvent
  }
}

export default usePage;
