import {doThen,doCatch,isX5App,resolveLocalFile} from '../../utils';

let voiceInfo = {};
let audio;
/*
wx.downloadFile({
  url: 'http://localhost:3000/beep.wav',
  type: 'audio',
  success: function (res) {
    wx.playVoice({
      filePath: res.tempFilePath
    })
  }
})
*/
/*


 filePath	String	是	需要播放的语音文件的文件路径
 success	Function	否	接口调用成功的回调函数
 fail	Function	否	接口调用失败的回调函数
 complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
 */
let promiseParams={};
let voiceTimer =null;
function playVoice(params) {
  promiseParams = params;
  const recordingTime = window.sessionStorage.getItem('recordingTime');
  voiceTimer = setTimeout(()=>{
      pauseVoice();
  },recordingTime)
  if (audio && audio.paused && voiceInfo.currentFilePath === params.filePath) {
    audio.play();
  } else {
    voiceInfo.currentFilePath = params.filePath;
    resolveLocalFile(params.filePath).then(function (blob) {
      audio = new window.Audio();
      audio.src = window.URL.createObjectURL(blob);
      audio.play();
    }).catch(function (error) {
      doCatch(params, error);
    });
  }
}

function _doThenVoice(params){
   voiceTimer && clearTimeout(voiceTimer);
   audio.onerror = function (error) {
          doCatch(params, error);
        };
    doThen(params);
    audio.oncanplay = function () {
  };
}

function pauseVoice() {
  audio.pause();
  _doThenVoice(promiseParams);
}

function stopVoice() {
  if(audio){
    audio.pause();
    _doThenVoice(promiseParams)
    audio = undefined;
    voiceInfo = {};
  }
}

export {playVoice,pauseVoice,stopVoice};
