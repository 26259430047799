export default class Report {
	static error(options){
		console.log(options);
		throw new Error(options);
	}
	
	static warn(options){
		console.warn(options);
	}
	
}