let _isOBJByType = function (o, type) {
  return Object.prototype.toString.call(o) === "[object " + (type || "Object") + "]";
};

let _isOBJ = function (obj) {
  let type = typeof obj;
  return type === "object" && !!obj;
};

let _isEmpty = function (obj) {
  if (obj === null) return true;
  if (_isOBJByType(obj, "Number")) {
    return false;
  }
  return !obj;
};

window.addEventListener("unhandledrejection", function(err, promise) {
  global.onerror.call(global,(err.reason && err.reason.message) || err.reason,null,null,null,err.reason || "");
});

let orgError = global.onerror;
// rewrite window.oerror
global.onerror = function (msg, url, line, col, error) {
  let newMsg = msg;

  if (error && error.stack) {
    newMsg = _processStackMsg(error);
  }

  if (_isOBJByType(newMsg, "Event")) {
    newMsg += newMsg.type ?
      ("--" + newMsg.type + "--" + (newMsg.target ?
        (newMsg.target.tagName + "::" + newMsg.target.src) : "")) : "";
  }
  orgError && orgError.call(global, newMsg,url,line,col,error);
};
let _processError = function (errObj) {
  try {
    if (errObj.stack) {
      let url = errObj.stack.match("https?://[^\n]+");
      url = url ? url[0] : "";
      let rowCols = url.match(":(\\d+):(\\d+)");
      if (!rowCols) {
        rowCols = [0, 0, 0];
      }

      let stack = _processStackMsg(errObj);
      return {
        msg: stack,
        rowNum: rowCols[1],
        colNum: rowCols[2],
        target: url.replace(rowCols[0], "")
      };
    } else {
      //ie 独有 error 对象信息，try-catch 捕获到错误信息传过来，造成没有msg
      if (errObj.name && errObj.message && errObj.description) {
        return {
          msg: JSON.stringify(errObj)
        };
      }
      return errObj;
    }
  } catch (err) {
    return errObj;
  }
};

let _processStackMsg = function (error) {
  let stack = error.stack
    .replace(/\n/gi, "")
    .split(/\bat\b/)
    .slice(0, 9)
    .join("@")
    .replace(/\?[^:]+/gi, "");
  let msg = error.toString();
  if (stack.indexOf(msg) < 0) {
    stack = msg + "@" + stack;
  }
  return stack;
};
