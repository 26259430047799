import AES from "crypto-js/aes";
import ECB from "crypto-js/mode-ecb";
import Pkcs7 from "crypto-js/pad-pkcs7";
import CryptoJS from 'crypto-js/core';
import Utf8 from "crypto-js/enc-utf8";
import Hex from "crypto-js/enc-hex";
import HmacSHA1 from "crypto-js/hmac-sha1";
import Md5 from "crypto-js/md5";
import {request} from 'core/api/network/request';

function fillKey(key) {
  key = key.substring(0, 16);
  if (key.length < 16) {
    for (var i = key.length; i < 16; i++) {
      key += "0";
    }
  }
  return key;
}


function aesEncrypt(value, key) {
  key = Utf8.parse(fillKey(key))


  value = Utf8.parse(value);
  //CipherOption,加密的一些选项：
  //mode:加密模式，可取值（CBC,CFB,CTR,CTRGladman,OFB,ECB),都在CryptoJS.mode对象下
  //padding:填充方式，可取值（Pkcs7,Ansix923,Iso10126,ZeroPadding,NoPadding),都在CryptoJS.pad对象下
  //iv:偏移量，mode===ECB时，不需要iv
  //返回的是一个加密对象
  var encrypted = AES.encrypt(value, key, {
    mode: ECB,
    padding: Pkcs7,
    iv: '',
    keySize: 128,
  });
  return encrypted.ciphertext.toString(Hex);

}

function aesDecrypt(value, key) {
  key = Utf8.parse(fillKey(key))

  value = Hex.parse(value);
  //返回的是解密后的对象
  var decrypt = AES.decrypt(CryptoJS.lib.CipherParams.create(
    {
      ciphertext: value
    }), key, {
    mode: ECB,
    padding: Pkcs7,
    iv: '',
    keySize: 128,
  });

  var decryptedStr = decrypt.toString(Utf8);
  return decryptedStr.toString();
}

function hmacEncode(value, key) {
  key = Utf8.parse(key)
  value = HmacSHA1(value, key);
  return value.toString();
}

/**
 基于url做端到端安全
 1. 防止框架注入 页面注入等问题
 2. 防止参数明文传输带来的安全问题

 hmac 签名算法

 所有url参数 编码为
 ?crypto_params=aes({
    query:query,
    $cryptoSignature:hmac(query,crypto_secret)
  });
 body
 application/x-form-urlencoded 为
 crypto_params=aes({
      ~query:query,
      $cryptoSignature:hmac(query,crypto_secret)
    })
 application/json 必须为json结果 所以编码为
 {
      crypto_body:aes({
              query:query,
              $cryptoSignature:hmac(query,crypto_secret)
            })
    }
 暂不支持  application/multipart-from 以及其他文本流 比如xml



 需要提供api
 url
 addParam
 getParam

 拦截
 ajax               主动进行替换
 window.open     主动进行替换
 iframe src       需要主动调用api进行处理


 后端检查
 1. 发现url参数中有特殊字符 进行异常拦截
 2. 网关上解密后 进行参数还原 对后端是透明加解密
 3. 302 场景进行网关拦截转换 对后端透明
 4. 后端代码中形成url带到 短信之类的地方的 提供api可以转换成加密url ，如果不加密 可以支持明文传输 但是必须保证 参数是不敏感的 同时不能有特殊字符
 框架注入漏洞
 特殊字符表
 [1] |（竖线符号）
 [2] & （& 符号）
 [3];（分号）
 [4] $（美元符号）
 [5] %（百分比符号）
 [6] @（at 符号）
 [7] '（单引号）
 [8] "（引号）
 [9] \'（反斜杠转义单引号）
 [10] \"（反斜杠转义引号）
 [11] <>（尖括号）
 [12] ()（括号）
 [13] +（加号）
 [14] CR（回车符，ASCII 0x0d）
 [15] LF（换行，ASCII 0x0a）
 [16] ,（逗号）
 [17] \（反斜杠）

 秘钥交换 逻辑 这块本质是签名算法和加密算法合并

 签名算法 后端在解开后保证没有被篡改
 加密算法 目的防止请求的明文传输, 正常加解密字符串 对称加密 前端也可以解开 拿到内部信息
 只是前端不进行篡改检查 （前端本身没有检查的需要 ）

 参数签名需要的秘钥交换: 拦截html内容插入script下放 crypto_secret 即是签名秘钥

 后端验证签名解密后 还原为标准url参数传递到后端
 --AES解密
 function unaes(key,data)
 local aes = require "resty.aes"
 local str = require "resty.string"
 local hash = {
         iv = "fedcba9876543210",
         method = nil
      }
 local salt = "0123456789abcdef"
 local aes_128_cbc, err = aes:new(key, salt, aes.cipher(128,"cbc"), hash)
 return aes_128_cbc:decrypt(data)
 end

 */

//window.crypto_secret = "my_secret0000000";
function CryptoRequest(url, crypto_secret) {

  this.base = "http://mockserver";
  this.url = new URL(url, this.base);
  this.cryptoEncoded = this.url.searchParams.has("crypto_query");

  this.urlType = "";
  if ((url.charAt(0) === '/') || (url.charAt(0) === '$')) {
    this.urlType = "absolute";
  } else if (url.substring(0, 2) === '//' || url.substring(0, 4) === 'http') {
    if (location.origin == this.url.origin) {
      this.urlType = "full";
    } else {
      this.urlType = "crossDomain";
    }
  } else if (url.charAt(0) === '.') {
    this.urlType = "relative";
  } else {
    this.urlType = "auto";
  }


  if (this.urlType == "crossDomain") {
    //this.crypto_secret = crypto_secret;
  }

  if (!this.getCryptoSecret()) {
    this.url = url;
  }
}

CryptoRequest.parse = function (url) {
  return new CryptoRequest(url || "");
}

CryptoRequest.encode = function (url) {
  if (!url) return url;
  return new CryptoRequest(url).toString();
}

CryptoRequest.decode = function (url) {
  if (!url) return url;
  return new CryptoRequest(url).toString(false);
}

//后台处理
CryptoRequest.cryptoCheckParam = CryptoRequest.prototype.cryptoCheckParam = function (paramValue) {
  if (!window.crypto_request_ui_check_param) {
    return;
  }

  ["|", "&", ";", "$", "%", "@", "'", "\\''", "\"", "<", ">", "(", ")", "+", /\n|\r/g, ",", "\\"].forEach(function (item) {
    if (item.test) {
      if (item.test(paramValue)) {
        throw new Error("url 参数不合法");
      } else {
        if (paramValue.indexOf(item) != -1) {
          throw new Error("url 参数不合法");
        }
      }
    }
  });
};


CryptoRequest.prototype.getCryptoSecret = function () {
  return window.crypto_secret;
}


/**
 @params value 参数要求decodeURIComponent后传递   value可以是hash部分 也可以是crypto_query的value
 @returns 为json结构 没有真实params为 {}
 返回值的json value 为已经url解码后的内容 不需要decodeURIComponent
 */
CryptoRequest.prototype.cryptoDecodeQuery = function () {
  if (!this.url.search) {
    return;
  }

  if (this.url.searchParams.has("crypto_query")) {
    var cryptoedQuery = this.url.searchParams.get("crypto_query");
    var crypto_DHId = this.url.searchParams.get("crypto_DHId");
    if (crypto_DHId) {
      cryptoedQuery = aesDecrypt(cryptoedQuery, this.getCryptoSecret());
    } else {
      cryptoedQuery = aesDecrypt(cryptoedQuery, window.crypto_secret || window._crypto_secret);
    }


    cryptoedQuery = JSON.parse(cryptoedQuery);
    this.url.searchParams.delete("crypto_query");
    this.url.searchParams.delete("crypto_DHId");

    var searchParams = new URLSearchParams(cryptoedQuery.query);
    var self = this;
    searchParams.forEach(function (_value, _name) {
      CryptoRequest.cryptoCheckParam(_value);
      self.url.searchParams.append(_name, _value);
    });
    self.url.search = "?" + cryptoedQuery.query;
  }
};


CryptoRequest.cryptoEncodeParam = function (value, type, crypto_secret) {
  crypto_secret = crypto_secret || window.crypto_secret;
  if (crypto_secret) {
    if (!value) return value;


    var cryptoBody = {
      query: value
    };
    cryptoBody = JSON.stringify(cryptoBody);
    if (cryptoBody.length < 14) {
      return value;
    }
    cryptoBody = aesEncrypt(cryptoBody, crypto_secret).toString(Utf8);

    if (type == "application/json") {
      if (cryptoBody.length < 250000) {
        if(window.crypto_request_config.DHId){
          return JSON.stringify({
            crypto_body: cryptoBody,
            crypto_DHId: window.crypto_request_config.DHId
          });
        }else {
          return JSON.stringify({
            crypto_body: cryptoBody
          });
        }


      } else {
        return value;
      }

    } else {
      cryptoBody = encodeURIComponent(cryptoBody);
      if (cryptoBody.length < 7500) {
        if(window.crypto_request_config.DHId){
          return "crypto_query=" + cryptoBody + "&crypto_DHId=" + window.crypto_request_config.DHId;
        }else {
          return "crypto_query=" + cryptoBody ;
        }

      } else {
        return value;
      }
    }
  }
  return value;
};

CryptoRequest.prototype.cryptoEncodeParam = function () {
  if (!this.url.search) {
    return;
  }


  var cryptoBody = {
    query: this.url.search.substring(1)
  };

  var query = {};
  this.url.searchParams.forEach(function (value, name) {
    query[name] = value;
  });

  for (var key in query) {
    if (query.hasOwnProperty(key)) {
      this.url.searchParams.delete(key);
    }
  }

  cryptoBody = JSON.stringify(cryptoBody);
  cryptoBody = aesEncrypt(cryptoBody, this.getCryptoSecret());
  this.url.searchParams.append("crypto_query", cryptoBody);
  if(window.crypto_request_config.DHId){
    this.url.searchParams.append("crypto_DHId", window.crypto_request_config.DHId);
  }

};

/*CryptoRequest.prototype.cryptoDecodeHash = function(){
  if(!this.url.hash){
    return;
  }
  var value = window.at(this.url.hash.substring(1));

  try{
    value = base64Decode(value);
  }catch(e){
    console.warn(e);

  }
  this.url.has = "#" + value;
};

CryptoRequest.prototype.cryptoEncodeHash = function(){
  if(!this.url.hash){
    return;
  }

  var value = base64Encode(this.url.hash.substring(1));
  this.url.hash = "#" + value;
	};

CryptoRequest.cryptoSignature = function(value, crypto_secret) {
  crypto_secret = crypto_secret || window.crypto_secret;
  if (crypto_secret) {
    value = hmacEncode(value, crypto_secret);
  }
  return value;
};

CryptoRequest.prototype.cryptoSignature = function(value) {

  value = hmacEncode(value, this.crypto_secret);
  return value;
};
*/
/**
 直接基于url编码 返回编码后的url
 */

CryptoRequest.prototype.toString = function (encode) {
  var result = this.url;
  if (this.getCryptoSecret() && this.urlType != "crossDomain") {
    if (encode === undefined) {
      encode = true;
    }
    if (encode) {
      //先解开
      this.cryptoDecodeQuery();
      //再合并 防止有新加的参数 通过标准api添加过
      this.cryptoEncodeParam();

      //防止二次编码
      /*this.cryptoDecodeHash();
      this.cryptoEncodeHash();*/
    } else {
      this.cryptoDecodeQuery();
      //this.cryptoDecodeHash();
    }

    result = this.url.toString();
    if (this.urlType == "full" || this.urlType == "crossDomain") {

    } else if (this.urlType == "relative") {
      result = result.replace(this.base, ".");
    } else if (this.urlType == "absolute") {
      result = result.replace(this.base, "");
    } else if (this.urlType == "auto") {
      result = result.replace(this.base + "/", "");
    }
  }else if(result){
    result = result.toString();
  }
  return result;
};

CryptoRequest.interceptRequest = function (params, init) {
  let originParams = {...params};
  if (params.cryptoRequest == false) {
    return;
  }

  let url = params.url;
  //端到端加密 拦截请求加密
  if (new CryptoRequest(url).urlType == "crossDomain") {
    return;
  }

  var method = init.method?.toUpperCase() || "GET";
  var contentType = init.headers && init.headers["content-type"];
  var body = init.body;

  //form 提交
  if (method == "POST" && contentType.indexOf("application/x-www-form-urlencoded") != -1) {
    params.url = CryptoRequest.parse(url).toString();
    init.body = CryptoRequest.cryptoEncodeParam(body);
  } else if (method == "POST" && contentType.indexOf("application/json") != -1) {
    params.url = CryptoRequest.parse(url).toString();
    init.body = CryptoRequest.cryptoEncodeParam(body, "application/json");
  } else if (method == "POST" && contentType.indexOf("multipart/form-data") != -1) {
    // 附件上传不处理
  } else if (method == "GET" || method == "HEAD") {
    if (body) {
      if (url.indexOf("?") == -1) {
        url += "?";
      } else {
        url += "&";
      }
      url += body;
    }
    params.url = CryptoRequest.parse(url).toString();
    init.body = undefined;
  } else if (method == "PUT" || method == "DELETE") {
    params.url = CryptoRequest.parse(url).toString();
    if (contentType.indexOf("application/json") != -1) {
      init.body = CryptoRequest.cryptoEncodeParam(body, "application/json");
    }
  }

  CryptoRequest.enableForwardMethod(init, method);
  CryptoRequest.enableSharedSecretReExchange(originParams,params);
  CryptoRequest.enableNonceCheck(init);
};


//支持特殊方法的转发
CryptoRequest.enableForwardMethod = function (init, method) {
  //代理 需要转发的method
  if (window.crypto_request_config && window.crypto_request_config.forwardMethod) {
    let forwardMethod = window.crypto_request_config.forwardMethod;
    if (forwardMethod.indexOf(method) != -1) {
      init.method = "POST";
      init.headers = init.headers || {};
      init.headers['X-Crypto-Request-FORWARDED-METHOD'] = method;
    }
  }
};


//支持nonce
CryptoRequest.enableNonceCheck = function(init) {
  //代理 需要转发的method
  if(window.crypto_request_config && window.crypto_request_config.nonceCheck){
    let nonce =  new Date().getTime()/1000;
    nonce = aesEncrypt(nonce, window.crypto_secret).toString(Utf8);
    //encode nonce for header value
    nonce = encodeURIComponent(nonce);
    init.headers = init.headers || {};
    init.headers['X-Crypto-Request-Nonce'] = nonce;
  }
};

//开启共享密钥重交换
CryptoRequest.enableSharedSecretReExchange = function (originParams, params) {

  params._crypto_request_success = params.success;
  params._crypto_request_complete = params.complete;


  params.success = (res) => {
    if (res.statusCode == 440) {
      let cryptoRequestConfigPublicKey = res.header["x-crypto-request-server-publickey"];
      let cryptoRequestConfigDHId = res.header["x-crypto-request-server-dhid"];
      if (cryptoRequestConfigPublicKey && cryptoRequestConfigDHId) {
        window.crypto_request_config = window.crypto_request_config || {};
        window.crypto_request_config.publicKey = cryptoRequestConfigPublicKey;
        window.crypto_request_config.DHId = cryptoRequestConfigDHId;
        CryptoRequest.diffieHellman(true);
        res._crypto_request_complete_ignore = true;
        request(originParams);
        return;
      }
    }
    params._crypto_request_success?.(res);

  }

  params.complete = (res) => {
    if(res._crypto_request_complete_ignore != true){
      params._crypto_request_complete?.(res);
    }
  }
};




CryptoRequest.interceptWindowOpen = function () {
  //端到端加密 拦截window.open
  var originOpen = window.open;
  if (!originOpen.cryptoIntercepted) {
    window.open = function (url, windowName, ...features) {
      url = CryptoRequest.parse(url).toString();
      return originOpen.call(window, url, windowName, features);
    }
    window.open.cryptoIntercepted = originOpen
  }
};


CryptoRequest.diffieHellman = function (force) {
  // 服务端公钥
  const serverPublicKey = window.crypto_request_config.publicKey;
  const DHId = window.crypto_request_config.DHId;


  if (!(serverPublicKey && DHId)) {
    return;
  }
  //兼容uix启动的时候 ui2密钥已经交换过了的场景
  if (window._crypto_secret && force != true) {
    return;
  }

  // 安全质数p和生成元g
  const p = 997;
  const g = 2;

  // 生成私钥和公钥
  function generateKey() {
    const privateKey = Math.floor(Math.random() * 997 / 100);
    const publicKey = BigInt(g) ** BigInt(privateKey) % BigInt(p); // 计算公钥
    return {privateKey, publicKey};
  }

  // 协商共享密钥
  function exchangeKey(myPrivateKey, partnerPublicKey) {
    const sharedSecret = BigInt(partnerPublicKey) ** BigInt(myPrivateKey) % BigInt(p);
    return sharedSecret;
  }

  function exchangeKeyToServer(DHId, clientPublicKey) {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', '/entry/crypto-request/admin/dhExchangeKey', false); // 设置为同步请求
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          console.log('密钥交换成功',xhr.responseText);
        }
      };
      xhr.onerror = function (error) {
        console.log('密钥交换失败',error);
      };
      const data = {
        DHId: DHId,
        clientPublicKey: clientPublicKey+""
      };
      xhr.send(JSON.stringify(data));
  }

  // 客户端生成私钥和公钥
  const clientKey = generateKey();
  console.log(clientKey);
  console.log(serverPublicKey);
  //计算共享密钥
  const sharedSecret = exchangeKey(clientKey.privateKey, serverPublicKey);
  //发送客户端公钥 服务端计算共享密钥
  exchangeKeyToServer(DHId, clientKey.publicKey);

  CryptoRequest.updateCryptoSecret(sharedSecret);
};

CryptoRequest.updateCryptoSecret = function (sharedSecret) {
  if (window.crypto_secret) {
    window._crypto_secret = window._crypto_secret || window.crypto_secret;
    window.crypto_secret = Md5(window._crypto_secret + sharedSecret).toString().substring(0, 16);
  }
}


CryptoRequest.resetCryptoSecret = function(){
  var text = window.crypto_secret;
  if(text && text.indexOf(":dfh:") !=-1){
    text = text.replace(/:dfh:/g,"");
    var shift = -3
    var result = "";

    for (var i = 0; i < text.length; i++) {
      var char = text[i];
      // Check if the character is an uppercase or lowercase letter
      var isUpperCase = /[A-Z]/.test(char);
      var isLowerCase = /[a-z]/.test(char);

      if (isUpperCase || isLowerCase) {
        var alphabetStart = isUpperCase ? 65 : 97;
        var alphabetEnd = isUpperCase ? 90 : 122;
        var shiftedCharCode = ((char.charCodeAt(0) - alphabetStart + shift) % 26 + 26) % 26 + alphabetStart;
        result += String.fromCharCode(shiftedCharCode);
      } else {
        // If the character is not a letter, keep it unchanged
        result += char;
      }
    }
    window.crypto_secret = result;
  }

}


if (window.crypto_secret) {
  CryptoRequest.resetCryptoSecret();
  CryptoRequest.diffieHellman();
  //uix reqeust 没有拦截点所有在request的实现中拦截
  //CryptoRequest.interceptRequest();
  CryptoRequest.interceptWindowOpen();
}

export default CryptoRequest;
//iframe 设置url 需要调用 cryptoEncodeUrl api  没有拦截点


