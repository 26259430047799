export default class IframeEntry {
  constructor() {
  }
  async bootstrap(props) {
    this.parentNode = props.parentNode;
    let baseName = props.baseName;
    let app = document.createElement('iframe');
    this.rootElement = app;
    app.setAttribute('id', baseName);
    app.classList.add("single-spa", "page_container");
    //外部hash变化传到进iframe
    window.addEventListener("hashchange", (event)=> {
      let newURL = new URL(event.newURL).hash.substring(1);
      this.setIFrameSrc(newURL, baseName);
    });
    this.rootElement.addEventListener("load", function (event) {
      this.rootElement.contentWindow.addEventListener('hashchange', (event)=> {
        let location = this.rootElement.contentWindow.location;
        let newHash = "#" + location.pathname + location.hash;
        if (newHash != window.location.hash) {
          window.history.replaceState(null, null, newHash);
        }
      });

      let originPushState = this.rootElement.contentWindow.history.pushState;
      this.rootElement.contentWindow.history.pushState = (data, title, url) =>{
        let result = originPushState.call(this.rootElement.contentWindow.history, data, title, url);
        let location = this.rootElement.contentWindow.location;
        let newHash = "#" + location.pathname + location.hash;
        if (newHash != window.location.hash) {
          window.history.replaceState(data, title, newHash);
        }
        return result;
      };

      let originReplaceState = this.rootElement.contentWindow.history.replaceState;
      this.rootElement.contentWindow.history.replaceState = (data, title, url) =>{
        let result = originReplaceState.call(this.rootElement.contentWindow.history, data, title, url);
        let location = this.rootElement.contentWindow.location;
        let newHash = "#" + location.pathname + location.hash;
        if (newHash != window.location.hash) {
          window.history.replaceState(data, title, newHash);
        }
        return result;
      };
    })
  }

  setIFrameSrc(newURL, baseName) {
    if (newURL.indexOf("/" + baseName) == 0) {
      let urlParts = newURL.split("#");
      let pathname = urlParts[0];
      let hash = urlParts[1];
      let contentWindow = this.rootElement.contentWindow;
      if (contentWindow) {
        let iFrameLocation = contentWindow.location;
        let iFrameHash = iFrameLocation.hash;
        if (iFrameHash != hash) {
          iFrameLocation.hash = hash;
        }
      } else {
        this.rootElement.src = newURL;
        if (!this.rootElement.parentElement) {
          this.parentNode.appendChild(this.rootElement);
        }
      }
    }
  }

  async mount(props) {
    this.lastPageElement = document.querySelector(".current_page");
    this.rootElement.classList.add('page-from-right-to-center');
    this.rootElement.addEventListener('animationend', () => {
      this.rootElement.classList.add('current_page');
      this.rootElement.classList.remove('page-from-right-to-center');
      this.lastPageElement && this.lastPageElement.classList.remove('current_page');
    }, {once: true});
    let header = props.header;
    let title = props.item.title;
    header.setState({
      title: title,
      back: true
    });
    this.setIFrameSrc(location.hash.substring(1), props.baseName);
  }

  async unmount(props) {
    this.lastPageElement && this.lastPageElement.classList.add('current_page');
    this.rootElement.classList.add('page-from-center-to-right');
    this.rootElement.addEventListener('animationend', () => {
      this.rootElement.classList.remove('page-from-center-to-right');
      this.rootElement.classList.remove('current_page');
    }, {once: true});
  }
}

