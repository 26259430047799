var DOM_ATTR = ["accept", "acceptCharset", "accessKey", "action", "allowFullScreen", "allowTransparency", 
                 "alt", "async", "autoComplete", "autoFocus", "autoPlay", "capture", "cellPadding", "cellSpacing", 
                 "challenge", "charSet", "checked", "cite", "classID", "className", "colSpan", "cols", "content", 
                 "contentEditable", "contextMenu", "controls", "coords", "crossOrigin", "data", "dateTime", "default", 
                 "defer", "dir", "disabled", "download", "draggable", "encType", "form", "formAction", "formEncType", 
                 "formMethod", "formNoValidate", "formTarget", "frameBorder", "headers", "height", "hidden", "high", 
                 "href", "hrefLang", "htmlFor", "httpEquiv", "icon", "id", "inputMode", "integrity", "is", "keyParams", 
                 "keyType", "kind", "label", "lang", "list", "loop", "low", "manifest", "marginHeight", "marginWidth", 
                 "max", "maxLength", "media", "mediaGroup", "method", "min", "minLength", "multiple", "muted", "name", 
                 "noValidate", "nonce", "open", "optimum", "pattern", "placeholder", "poster", "preload", "profile", 
                 "radioGroup", "readOnly", "rel", "required", "reversed", "role", "rowSpan", "rows", "sandbox", "scope", 
                 "scoped", "scrolling", "seamless", "selected", "shape", "size", "sizes", "span", "spellCheck", "src", 
                 "srcDoc", "srcLang", "srcSet", "start", "step", "style", "summary", "tabIndex", "target", "title", 
                 "type", "useMap", "value", "width", "wmode", "wrap"];  
var OTHER_ATTR = ["autoCapitalize", "autoCorrect", "color", "itemProp", "itemScope", "itemType", "itemRef", "itemID", "security",
                   "unselectable", "results", "autoSave"];
var ATTR_PREFIX = ["data-", "aria-"]; 

function isValidAttr(name){
	if (DOM_ATTR.indexOf(name)!=-1 || OTHER_ATTR.indexOf(name) != -1){
		return true;
	}
	for (let i=0; i<ATTR_PREFIX.length; i++){
		if (name.indexOf(ATTR_PREFIX[i]) == 0){
			return true;
		}
	}
	return false;
}


var WxUtils = {
	toDOMAttrs: function(props){
		if (!props) return props;
		var ret = {};
		for (let name in props){
			if (props.hasOwnProperty(name) && isValidAttr(name)){
				ret[name] = props[name];
			}
		}
		return ret;
	}
}

export default WxUtils;