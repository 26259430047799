import { doThen, doCatch, isX5App, resolveLocalFile } from './../utils';
import detector from 'detector';
function getNetworkType(params) {
  if (navigator.connection) {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    var networkType = connection.type;
    doThen(params, {
      networkType: networkType
    });
  } else {
    doThen(params, {
      networkType: "wifi"
    });
  }
}

/*
 * model 手机型号 pixelRatio 设备像素比 windowWidth 窗口宽度 windowHeight 窗口高度 language
 * 微信设置的语言 version 微信版本号
 */

function _getSystemInformation(){
	const result = {
		pixelRatio: window.devicePixelRatio,
		windowWidth: window.innerWidth,
		windowHeight: window.innerHeight,
		language: navigator.language
	};
	if (isX5App && window.device) {
	    result.model = device.model;
	    result.version = device.version;
	  } else {
	    result.model = detector.device.name;
	    result.version = detector.device.version;
	 }
	return result;
}


function getSystemInfo(params) {
  doThen(params, _getSystemInformation());
}

/*
 * wx.getSystemInfoSync()
	获取系统信息同步接口
 */

function getSystemInfoSync(){
	return _getSystemInformation();
} 

/**
 * 重力感应API:
 * CALLBACK返回参数：
        参数	        类型	       说明
        x   	      Number	      X 轴
        y	          Number	      Y 轴
        z	          Number	      Z 轴
 */
let watchID = "";
function stopAccelerometer(callback){
	if(navigator.accelerometer){
		if(watchID)
		navigator.accelerometer.clearWatch(watchID);
	}else if(window.isDebug){
		console.warn("PC端浏览器不支持硬件设备功能");
	    doThen(callback,{});
	}else{
		 console.warn("不支持硬件设备功能");
	}
}
function onAccelerometerChange(callback) {
  if (navigator.accelerometer) {
    const options = { frequency: 200 };
    watchID = navigator.accelerometer.watchAcceleration(acceleration => {
      let {x, y, z} = {
        x: acceleration.x,
        y: acceleration.y,
        z: acceleration.z
      }
      callback.call(this, { x, y, z });
    }, function (error) {
      doCatch(callback, error);
    }, options);
  } else if (window.DeviceMotionEvent) {
    const speed = 0.2;
    var x = 0, y = 0, z = 0, lastX = 0, lastY = 0, lastZ = 0;
    const deviceMotionHandler = eventData => {
      var acceleration = eventData.accelerationIncludingGravity;
      let {x, y, z } = {
        x: acceleration.x,
        y: acceleration.y,
        z: acceleration.z
      }
      if (Math.abs(x - lastX) > speed || Math.abs(y - lastY) > speed || Math.abs(z - lastZ) > speed) {
        callback.call(this, { x, y, z });
      }
      lastX = x;
      lastY = y;
      lastZ = z;
    }
    window.addEventListener('devicemotion', deviceMotionHandler, false);
    if (x == 0 && y == 0 && z == 0) {
      console.warn("PC端浏览器不支持硬件设备功能");
      window.removeEventListener('devicemotion', deviceMotionHandler, false);
    }
  } else if (window.isDebug) {
    console.warn("PC端浏览器不支持硬件设备功能");
    doThen(callback,{
    	x:0,
    	y:0,
    	z:0,
    	errMsg:"ok"
    })
  } else {
    console.warn("不支持硬件设备功能");
  }
}

/**
 * 罗盘API：
 * 
 * CALLBACK返回参数：

    参数	      类型        说明
  direction   	Number	面对的方向度数

 */

function onCompassChange(callback) {
  if (navigator.compass) {
    const watchID = navigator.compass.watchHeading(function (compassHeading) {
      const direction = compassHeading.magneticHeading;
      callback.call(self, {
        direction
      });
    }, function (error) {
      doCatch(callback, error);
    });
  } else if (window.DeviceMotionEvent) {
    window.addEventListener('deviceorientation', deviceOrientationHandler, false);
    function deviceOrientationHandler(eventData) {
      var {direction} = { direction: eventData.alpha }
      if (direction == null) {
        console.warn("PC端浏览器不支持硬件设备功能");
        window.removeEventListener('deviceorientation', deviceOrientationHandler, false);
        return
      }
      callback.call(self, { direction })
    }
  } else if (window.isDebug) {
    console.warn("PC端浏览器不支持硬件设备功能");
    doThen({
      direction: 0,
      errMsg: "ok"
    })
  } else {
    console.warn("不支持硬件设备功能");
  }
}
export { getNetworkType, getSystemInfo,getSystemInfoSync, onAccelerometerChange, onCompassChange, stopAccelerometer};
