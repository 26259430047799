import {findReact} from '../../utils';


/*
 * play    无          播放
 * pause   无          暂停
 * seek    Position  跳转到指定位置，单位 s
 * sendDanmu    danmu      发送弹幕，danmu 包含两个属性 text, color。
 */
function createVideoContext(params){
	let videoId = document.getElementById(params);
	return findReact(videoId);
}

export {createVideoContext};